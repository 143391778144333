import React, {useCallback, useEffect, useRef, useState} from "react";


interface ImageRendererProps {
    images: string[];
}

export function Carousel({ images, ...props }: ImageRendererProps) {
    const [imgs, setImgs] = useState(images);
    const [idx, setIdx] = useState(0);

    return (
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                {
                    imgs.map((image: string, index: number) => {
                        return (
                            <div key={"nuvala-carousel-" + index} className={"carousel-item" + ((idx % imgs.length) === index ? " active" : "")}>
                                <img className="d-block w-100" src={image} alt={image} />
                            </div>
                        )
                    })
                }
            </div>
            <button className="carousel-control-prev" type="button" onClick={() => setIdx(idx-1)}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" onClick={() => setIdx(idx+1)}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}
