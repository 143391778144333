import React, {useEffect, useState} from "react";
import {AccommodationPublicModel, MediaModel} from "../../models/Accommodation";
import {dateToString, PricesExtract} from "../../models/Planner";
import DatePicker, {registerLocale} from "react-datepicker";
import it from "date-fns/locale/it";
import {getAccAvailDates, getAccPrices} from "../../services/Planner.service";
import {NuvalaMapPopupPrice} from "./NuvalaMapPopupPrice.component";
import {SharedAccoProps} from "./NuvolaMapPopup.component";
import {Carousel} from "../shared/Carousel.component";
import {createReservation} from "../../services/Reservation.service";
import {useNavigate} from "react-router-dom";

export function NuvalaMapPopupDate(props: SharedAccoProps) {
    const [recap, setRecap] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPrice, setLoadingPrice] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [lowerLimitDate, setLowerLimitDate] = useState<Date | null>(null);
    const [upperLimitDate, setUpperLimitDate] = useState<Date | null>(null);
    const [accommodation, setAccommodation] = useState<AccommodationPublicModel>(props.accommodation);
    const [availDates, setAvailDates] = useState<Date[]>([]);
    const [bkAvailDates, setBkAvailDates] = useState<Date[]>([]);
    const [selectedPax, setSelectedPax] = useState<number>(props.accommodation.min_pax);
    const [priceExtract, setPriceExtract] = useState<PricesExtract | null>(null);

    let navigate = useNavigate();

    registerLocale('it', it);

    useEffect(() => {
        setLoading(true);
        getAccAvailDates(props.accommodation.slug).then(res => {
            const d = res.data.availabilities.map((date: string) => {
                const temp_dat = new Date(date);
                temp_dat.setHours(0, 0, 0, 0);
                return temp_dat;
            });
            d.sort((a: Date, b: Date) => a.getTime() - b.getTime());
            setAvailDates(d);
            setBkAvailDates(d);
            if (props.startDateIn !== undefined) setStartDate(props.startDateIn);
            if (props.endDateIn !== undefined) setEndDate(props.endDateIn);
            if (props.guestsIn !== undefined && props.guestsIn !== null) setSelectedPax(props.guestsIn);
            if(props.startDateIn && props.endDateIn) {
                console.log(props.startDateIn, props.endDateIn)
                console.log(props.accommodation.min_pax, props.guestsIn)
                fetchPrice(props.startDateIn, props.endDateIn, props.guestsIn || props.accommodation.min_pax)
            }
        }).catch(err => console.log(err)).finally(() => {
            setLoading(false);
        });
    }, [props])

    const onChange = (dates: [Date | null, Date | null]) => {
        setPriceExtract(null);
        const [start, end] = dates;
        if (start !== null && end === null) {
            const startIdx = availDates.findIndex((d: Date) => d.getTime() === start.getTime());
            let endIdx = startIdx;
            availDates.forEach((selected, idx, array) => {
                if (idx > startIdx) {
                    if (Math.abs(selected.getTime() - array[endIdx].getTime()) === 86400000) endIdx = idx;
                }
            });
            let upperPlusOne = new Date(availDates[endIdx].getTime() + 86400000)

            setAvailDates([...bkAvailDates, upperPlusOne]);
            setUpperLimitDate(upperPlusOne);
            setLowerLimitDate(availDates[startIdx]);

        } else {
            setUpperLimitDate(null);
            setLowerLimitDate(new Date());
            setAvailDates(bkAvailDates);
        }
        setStartDate(start);
        setEndDate(end);
        if (start && end) fetchPrice(start, end, selectedPax);
    };

    function fetchPrice(start?: Date, end?: Date, pax?: number) {
        if (start && end && pax) {
            setLoadingPrice(true);
            getAccPrices(props.accommodation.slug, start, end, pax).then(res => {
                setPriceExtract(res.data);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setLoadingPrice(false);
            });
        }
    }

    function registerReservation() {
        setLoading(true);
        if (startDate && endDate) {
            createReservation(startDate, endDate, selectedPax, accommodation.slug).then(res => {
                navigate('/guest/reservation/' + res.data.slug)
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    if (recap) return (
        <div className="row mx-0">
            <div className="col-md-12 col-12 p-4">
                <div className="row">
                    <div className="col-12">
                        <h5 className="text-nuvala-blue">{accommodation.name}</h5>
                    </div>
                    <div className="col-12 col-md-7">
                        <Carousel images={accommodation.media.map((i: MediaModel) => i.url)} />
                        <p className="text-nuvala-blue mt-3">
                            {
                                (accommodation?.description.length > 100) ?
                                    accommodation?.description.substring(0, 300) + '...' :
                                    accommodation?.description
                            }
                        </p>
                    </div>
                    <div className="col-12 col-md-5 text-nuvala-blue">
                        <h3 className="text-nuvala-blue mb-4">Riepilogo prenotazione</h3>
                        <h5>Soggiorno</h5>
                        <div className="d-flex justify-content-between mb-1">
                            Data arrivo
                            <span><b>{startDate ? dateToString(startDate) : "-"}</b></span>
                        </div>
                        <div className="d-flex justify-content-between mb-1">
                            Data partenza
                            <span><b>{startDate ? dateToString(endDate) : "-"}</b></span>
                        </div>
                        <div className="d-flex justify-content-between mb-1">
                            Numero ospiti
                            <span><b>{selectedPax}</b></span>
                        </div>
                        <h5 className="mt-4">Costo</h5>
                        <div className="d-flex justify-content-between mb-1">
                            Prezzo x notte
                            <span><b>{priceExtract?.avg_ppd}€</b></span>
                        </div>
                        <div className="d-flex justify-content-between">
                            {priceExtract?.avg_ppd}€ x {priceExtract?.no_nights} {(priceExtract?.no_nights === 1) ? "notte" : "notti"}
                            <span><b>{priceExtract?.total}€</b></span>
                        </div>
                        <hr/>
                        <div className="d-flex justify-content-between">
                            <span><b>Totale</b></span>
                            <span><b>{priceExtract?.total}€</b></span>
                        </div>
                        <div className="col-12 col-md-5 mt-2">
                            <p><small><b>*</b> Una volta inviata la prenotazione, il gestore della struttura confermera la prenotazione entro 24 ore. Successivamente ti verra inviata una email con il modulo di pagamento.</small></p>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <button className="btn btn-outline-nuvala mt-3" onClick={(e) => setRecap(false)}>Torna alla selezione date</button>
                            <button className="btn btn-nuvala mt-3" onClick={(e) => registerReservation()}>Conferma e prenota</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="row mx-0">
            <div className="col-md-8 col-12 b-nuvala-bottom p-4">
                <div className="calendar-wrapper text-center h-100">
                    {
                        loading ? (
                            <div className="d-flex justify-content-center align-items-center"
                                 style={{height: "100%"}}>
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            (availDates.length > 0) ?
                                (
                                    <DatePicker
                                    locale="it"
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={lowerLimitDate}
                                    maxDate={upperLimitDate}
                                    includeDates={availDates}
                                    onChange={onChange}
                                    monthsShown={2}
                                    inline
                                    focusSelectedMonth={true}
                                    selectsRange
                                />
                                ) : (<h3 className="text-nuvala-blue mt-5">Al momento non ci sono date disponibili</h3>)
                        )
                    }
                </div>
            </div>
            <div className="col-md-4 col-12 b-nuvala-bottom b-nuvala-left p-4">
                <>
                    <div className="d-flex justify-content-between text-nuvala-blue">
                        <small><b>check in</b><br/> {startDate ? dateToString(startDate) : "-"}</small>
                        <small><b>check out</b><br/> {endDate ? dateToString(endDate) : "-"}</small>
                    </div>
                    <div className="text-nuvala-blue mt-3">
                        {(accommodation.min_stay) && <p className="mb-1"><small>soggiorno minimo: {accommodation.min_stay} notti</small></p>}
                        {(accommodation.max_stay) && <p className="mb-1"><small>soggiorno massimo: {accommodation.max_stay} notti</small></p>}
                    </div>
                    <div className="mt-3 text-nuvala-blue">
                        <small><b>numero ospiti</b></small>
                        <select className="form-select form-select-sm mt-2"
                                value={selectedPax}
                                onChange={(e) => {
                                    const new_pax = parseInt(e.target.value);
                                    setSelectedPax(new_pax);
                                    if (startDate && endDate) {
                                        fetchPrice(startDate, endDate, new_pax);
                                    }
                                }}>
                            {
                                Array(20).fill(0).map((curr, idx, arr) => {
                                    const value = idx + 1;
                                    if (value >= accommodation.min_pax && value <= accommodation.max_pax) {
                                        return <option key={"pax-no" + value} value={value}>{value}</option>
                                    }
                                })
                            }
                        </select>
                    </div>
                    {
                        loadingPrice ? (
                            <div className="d-flex justify-content-center mt-5">
                                <div className="spinner-border text-warning" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <NuvalaMapPopupPrice p={priceExtract} setRecap={setRecap} />
                        )
                    }
                </>
            </div>
            <div className="col-md-8 col-12 p-4">
                <p className="text-nuvala-blue">
                    <b>il tuo host:</b> <br />
                    {accommodation.host.name} <br />
                    {accommodation.host.address}
                </p>
            </div>
            <div className="col-md-4 col-12 b-nuvala-left p-4">
                <p className="text-nuvala-blue"><b>co-workers presenti</b></p>
            </div>
        </div>
    );
}