import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {isAuth, logout} from "../../services/Auth.service";
import {AdminFilter, getAdminAccommodations, getAdminDashBoardData, getAdminUsers} from "../../services/Admin.service";
import {AccommodationAdminModel, UserAdminModel} from "../../models/Admin";
import {Toast} from "../../components/shared/Toast.component";
import {AdminAccommodation} from "../../components/admin/AdminAccommodation.component";
import {Loading} from "../../components/shared/Loading.component";
import {AdminUser} from "../../components/admin/AdminUser.component";


export function AdminDashboard() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [formError, setFormError] = useState<string[]>([]);
    const [pendingAccommodations, setPendingAccommodations] = useState<AccommodationAdminModel[]>([])
    const [pendingUsers, setPendingUsers] = useState<UserAdminModel[]>([])

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        } else {
            setLoading(true);
            Promise.all([
                getAdminAccommodations([{key: "is_validated", value: "false"}]),
                getAdminUsers([{key: "is_verified", value: "false"}]),
            ]).then(res => {
                setPendingAccommodations(res[0].data);
                setPendingUsers(res[1].data);
            }).catch(err => {
                setFormError(["Qualcosa e' andato storto:", err.response.data.message]);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, []);

    function setAccommodation(accommodation: AccommodationAdminModel) {
        const newState = pendingAccommodations.map(acc => {
            if (acc.slug === accommodation.slug) {
                return {...acc, is_validated: accommodation.is_validated};
            }
            return acc;
        });
    }

    function setUser(user: UserAdminModel) {
        const newState = pendingUsers.map(u => {
            if (u.public_id === user.public_id) {
                return {...u, is_verified: user.is_verified};
            }
            return u;
        });
    }

    if (loading) return <Loading />

    return (
        <div className="row">
            <div className="col-12 text-center">
                { formError.length > 0 ? <Toast text={formError} /> : "" }
            </div>
            <div className="col-md-8 col-12 mb-3">
                <h4 className="text-nuvala-blue">Strutture da approvare</h4>
                {
                    (pendingAccommodations.length > 0) ? pendingAccommodations.map((a: AccommodationAdminModel, idx) => {
                        return <AdminAccommodation key={"pacc-"+a.slug} accommodation={a} setAccommodation={setAccommodation} />
                    }) : <p>Non ci sono strutture da verificare</p>
                }
            </div>
            <div className="col-md-4 col-12 mb-3">
                <h4 className="text-nuvala-blue">Utenti da approvare</h4>
                {
                    (pendingUsers.length > 0) ? pendingUsers.map((u: UserAdminModel, idx) => {
                        return <AdminUser user={u} setUser={setUser} />
                    }) : <p>Non ci sono utenti da verificare</p>
                }
            </div>
        </div>
    )
}