import React, {ChangeEvent, useEffect, useState} from "react";
import {isAuth, logout} from "../../services/Auth.service";
import {useNavigate} from "react-router-dom";
import {Loading} from "../../components/shared/Loading.component";
import {
    AccommodationModel, AccommodationModelSchema,
    AccommodationTypologies, BedModel, FacilityModel, getEmptyAccommodationModel
} from "../../models/Accommodation";
// @ts-ignore
import MapboxAutocomplete from "react-mapbox-autocomplete";
import {RegionsLocales, zipToRegion} from "../../services/Locale.service";
import {addNewAccommodation, getReferentialsList, updateAccommodation} from "../../services/Accommodation.service";
import {Toast} from "../../components/shared/Toast.component";
import {Accordion} from "../../components/shared/Accordion.component";
import Select from 'react-select'

export function HostAddAccommodation(props: any) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdate, setIsUpdate] = useState(!!Object.keys(props).length);
    const [formValues, setFormValues] = useState<AccommodationModel>(getEmptyAccommodationModel());
    const [formError, setFormError] = useState<string[]>([]);
    const [facilitiesList, setFacilitiesList] = useState<any[]>([]);
    const [bedsList, setBedsList] = useState<BedModel[]>([]);

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        }
        getReferentialsList().then(
            res => {
                if(isUpdate) {
                    props.accommodation.beds = props.accommodation.beds.map(
                        (b: {"value": number, "bed": BedModel}) => ({ "name": b.bed.name, "value": b.value })
                    );
                    setFormValues(props.accommodation);
                }
                setFacilitiesList(res.data.facilities.groups);
                setBedsList(res.data.beds);
            }).catch(err => {
                setFormError(["Qualcosa e' andato storto, riprova."]);
                console.log(err);
            }).finally(() => {
                setIsLoading(false);
            });
    }, []);

    function _suggestionSelect(result: string, lat: number, lng: number, text: string) {
        setFormValues({
            ...formValues,
            address: result,
            latitude: lat,
            longitude: lng,
            region: zipToRegion(result),
        });
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await AccommodationModelSchema.validate(formValues, {abortEarly: false}).then(validation => {
                addNewAccommodation(formValues).then(
                    res => {
                        setFormValues(getEmptyAccommodationModel());
                        navigate("/host/accommodation/" + res.data.slug);
                    }).catch(err => {
                        setFormError(["Qualcosa e' andato storto, riprova."]);
                        console.log(err);
                    }).finally(() => {
                        setIsLoading(false);
                    });
            });
        } catch (err: any) {
            setFormError(err.errors);
            setIsLoading(false);
        }
    }

    async function handleSubmitUpdate(event: any) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await AccommodationModelSchema.validate(formValues);
            updateAccommodation(formValues).then(
                res => {
                    setFormError(["Struttura aggiornata!"]);
                }).catch(err => {
                    setFormError(["Qualcosa e' andato storto, riprova."]);
                    console.log(err);
                }).finally(() => {
                    setIsLoading(false);
                });
        } catch (err: any) {
            setFormError(err.errors);
            setIsLoading(false);
        }
    }

    function handleChange(event: any) {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        if (name === "is_ppa") value = !formValues.is_ppa;
        if (name === "additional_ppp") value = parseFloat(value)
        if(name === "facilities") {
            if (formValues.facilities.includes(value)) {
                const newFacilities = formValues.facilities.filter(function(ele){
                    return ele !== value;
                });
                setFormValues({
                    ...formValues,
                    facilities: newFacilities
                });
            } else {
                setFormValues({
                    ...formValues,
                    facilities: [...formValues.facilities, value]
                });
            }
        } else {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }
    }

    function handleMultiSelectChange (e: any[], category: string) {
        // filter out input categories
        const previous_facilities = formValues.facilities.filter((f: FacilityModel) => f.category !== category)
        const new_cat = e.map(f => ({
            "id": f.id,
            "name": f.value,
            "category": f.category,
            "group": f.group,
        }));
        setFormValues({
            ...formValues,
            facilities: [...previous_facilities, ...new_cat]
        })
    }

    function handleBeds(bed: BedModel, value: number) {
        if(formValues.beds === undefined) formValues.beds = [];
        const b: ({name: string, value: number} | undefined) = formValues.beds.find((bed_lookup: {name: string, value: number}) => bed_lookup.name === bed.name);
        if(b) b.value = value;
        else formValues.beds.push({name: bed.name, value: value});
        // check if zero value
        if(value <= 0) {
            const idx = formValues.beds.findIndex((bed: {name: string, value: number}) => bed.name === bed.name);
            formValues.beds.splice(idx, 1);
        }
    }

    if (isLoading === true) return <Loading/>

    return (
        <form onSubmit={isUpdate ? handleSubmitUpdate : handleSubmit}>
        <div className="row">
            { isUpdate ? "" : <div className="col-12"><h3>Aggiungi nuova struttura</h3></div> }
            { formError.length > 0 ? <Toast text={formError} /> : "" }
            <div className="col-8">
                <Accordion title="Informazioni di base" open={true}>
                    <>
                        <div className="row mb-3">
                            <div className="col-7">
                                <label className="form-label"><b>Nome *</b></label>
                                <input type="text" name="name" className="form-control" placeholder="Nome struttura"
                                       value={formValues?.name} onChange={handleChange}/>
                            </div>
                            <div className="col-5">
                                <div className="mb-2">
                                    <label className="form-label"><b>Tipologia *</b></label>
                                    <select className="form-select" value={formValues?.typology} name="typology" onChange={handleChange}>
                                        <option value="" disabled>Seleziona una tipologia</option>
                                        { AccommodationTypologies.map(l => (<option key={l.name} value={l.name}>{l.name}</option>)) }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-7">
                                <label className="form-label"><b>Indirizzo *</b></label>
                                <MapboxAutocomplete publicKey='pk.eyJ1IjoiczRmZSIsImEiOiJja3l3dmQ5c3EwY2U0Mm9xaXpnaG9hajlyIn0.NxFOzf9mkgI9AieFiLCXsA'
                                    inputClass='form-control search mb-0'
                                    onSuggestionSelect={_suggestionSelect}
                                    country='it'
                                    query={formValues?.address}
                                    resetSearch={false}/>
                            </div>
                            <div className="col-5">
                                <label className="form-label"><b>Regione *</b></label>
                                <select className="form-select" value={formValues?.region} name="region" onChange={handleChange}>
                                    <option value="" disabled>Seleziona una regione</option>
                                    { RegionsLocales.map(l => (<option key={l} value={l}>{l}</option>)) }
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label"><b>Descrizione *</b></label>
                                <textarea rows={6} name="description" maxLength={2000}
                                          className="form-control" placeholder="Descrizione struttura"
                                          value={formValues?.description} onChange={handleChange}/>
                                <small className="text-muted float-end">{formValues?.description.length} caratteri (max 2000)</small>
                            </div>
                        </div>
                    </>
                </Accordion>
                <Accordion title="Sistemazioni, ospiti e prezzi" open={true}>
                    <div className="row mb-3">
                    <div className="col-6">
                        <label className="form-label"><b>Sistemazioni *</b></label>
                        <BedsWidget bedsList={bedsList} formValues={formValues} handleChange={handleBeds} />
                    </div>
                    <div className="col-6">
                        <div className="mb-2">
                            <label className="form-label"><b>Min / Max Ospiti *</b></label>
                            <div className="input-group">
                                <span className="input-group-text text-nuvala-blue">min</span>
                                <input type="text" name="min_pax" value={formValues?.min_pax} placeholder="min" onChange={handleChange} className="form-control text-center" />
                                <span className="input-group-text text-nuvala-blue">max</span>
                                <input type="text" name="max_pax" value={formValues?.max_pax} placeholder="max" onChange={handleChange} className="form-control text-center" />
                                <span className="input-group-text text-nuvala-blue">pax</span>
                            </div>
                        </div>
                        <div className="mb-2">
                            <label className="form-label"><b>Min / Max Stay *</b></label>
                            <div className="input-group">
                                <span className="input-group-text text-nuvala-blue">min</span>
                                <input type="text" name="min_stay" value={formValues?.min_stay} placeholder="min" onChange={handleChange} className="form-control text-center" />
                                <span className="input-group-text text-nuvala-blue">max</span>
                                <input type="text" name="max_stay" value={formValues?.max_stay} placeholder="max" onChange={handleChange} className="form-control text-center" />
                                <span className="input-group-text text-nuvala-blue">days</span>
                            </div>
                        </div>
                        <PriceModeSelector formValues={formValues} handleChange={handleChange}/>
                    </div>
                </div>
                </Accordion>
                <Accordion title="Categorie filtro" open={true}>
                    <div className="row mb-3">
                        <div className="col-12">
                            <FacilityFilters {...{facilitiesList}} {...{formValues}} handleMultiSelectChange={handleMultiSelectChange} />
                        </div>
                    </div>
                </Accordion>
                <Accordion title="Informazioni aggiuntive">
                        <div className="row mb-3">
                            <div className="col-12">
                                <label className="form-label"><b>Come raggiungere la struttura</b></label>
                                <textarea rows={6} name="get_there" maxLength={1000}
                                          className="form-control" placeholder="Come raggiungere la struttura"
                                          value={formValues?.get_there} onChange={handleChange}/>
                                <small className="text-muted float-end">{formValues?.get_there.length} caratteri (max 1000)</small>
                            </div>
                            <div className="col-12">
                                <label className="form-label"><b>Messaggio di benvenuto</b></label>
                                <textarea rows={6} name="welcome_message" maxLength={2000}
                                          className="form-control" placeholder="Come raggiungere la struttura"
                                          value={formValues?.welcome_message} onChange={handleChange}/>
                                <small className="text-muted float-end">{formValues?.welcome_message.length} caratteri (max 2000)</small>
                            </div>
                        </div>
                </Accordion>
                <button type="submit" className="btn btn-nuvala float-end">{ isUpdate ? "Aggiorna Struttura" : "Aggiungi Struttura" }</button>
            </div>
        </div>
        </form>
    );
}

interface BedsWidgetProps {
    bedsList: BedModel[],
    formValues: AccommodationModel,
    handleChange: (bed: BedModel, value: number) => void
}
export function BedsWidget({bedsList, formValues, handleChange}: BedsWidgetProps) {
    const [loading, setLoading] = useState(true);
    const [beds, setBeds] = useState<BedModel[]>([]);

    useEffect(() => {
        setBeds(bedsList);
        setLoading(false);
    }, []);

    if(loading) {
        return (
            <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        )
    }

    return (
        <>
            {
                beds.map(b => {
                    const iv: {name: string, value: number}[] = formValues.beds.filter(lookup => lookup.name === b.name);
                    return <BedInput key={b.name} bed={b} handleChange={handleChange} initialValue={(iv.length > 0) ? iv[0].value : 0}/>
                })
            }
        </>
    )
}

interface BedInputProps {
    bed: BedModel,
    initialValue?: number,
    handleChange: (bed: BedModel, value: number) => void
}
export function BedInput({bed, initialValue, handleChange} : BedInputProps) {
    const [value, setValue] = useState(0);
    useEffect(() => {
        if(initialValue) setValue(initialValue);
    }, [])

    function incrementValue(event: any) {
        handleChange(bed, value+1);
        setValue(value+1);
    }
    function decrementValue(event: any) {
        if (value > 0) {
            handleChange(bed, value-1);
            setValue(value-1);
        }
    }

    return (
        <div className="facility_card text-center">
            <div className="input-group input-group-sm mb-1">
                <span style={{textTransform: "capitalize", width: "180px"}} className="input-group-text text-nuvala-blue">{ bed.name }</span>
                <button className="btn btn-sm btn-nuvala" type="button" onClick={decrementValue}>-</button>
                <input type="text" style={{textAlign: "center"}} className="form-control"
                       name="beds" value={value} onChange={() => {}}/>
                <button className="btn btn-sm btn-nuvala" type="button" onClick={incrementValue}>+</button>
            </div>
        </div>
    )
}

interface FacilityFiltersProps {
    facilitiesList: any[],
    formValues: AccommodationModel,
    handleMultiSelectChange: (event: any, category: string) => void
}
export function FacilityFilters({facilitiesList, formValues, handleMultiSelectChange} : FacilityFiltersProps) {
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState<any[]>([]);

    useEffect(() => {
        setFilters(facilitiesList);
        setLoading(false);
    }, []);

    if(loading) {
        return (
            <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        )
    }
    return (
        <div>
            {
                (filters.length > 0) && filters?.map((group, idx) => {
                    return (
                        <div className="row" key={idx}>
                            <div className="col-1 align-self-center">
                                <div className="groups-vertical-text">{group.name}</div>
                            </div>
                            <div className="col-11 p-4">
                                {
                                    group.categories?.map((category: any, idx: number) => {
                                        let fc: FacilityModel[] = formValues.facilities.filter((f: FacilityModel) => f.category === category.name);
                                        return (
                                            <div className="py-2" key={idx}>
                                                <h5 className="text-nuvala-blue">{category.name}</h5>
                                                <Select
                                                    defaultValue={ fc.map((f: FacilityModel) => (
                                                        {"label": f.name, "value": f.name, "category": f.category, "group": f.group, "id": f.id}
                                                    ))}
                                                    isMulti
                                                    name={category.name}
                                                    options={category.facilities.map((f: FacilityModel) => (
                                                        {"label": f.name, "value": f.name, "category": f.category, "group": f.group, "id": f.id}
                                                    ))}
                                                    className="basic-multi-select text-nuvala-blue"
                                                    classNamePrefix="select"
                                                    onChange={(e) => handleMultiSelectChange(e, category.name)}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {(idx < filters.length-1) && <hr className="nuvala-hr" />}
                        </div>
                    );
                })
            }
        </div>
    )
}

interface PriceModeSelectorProps {
    formValues: AccommodationModel,
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}
export function PriceModeSelector({formValues, handleChange} : PriceModeSelectorProps) {
    const [isPPA, setPPA] = useState(formValues.is_ppa);

    function handleToggle(event: any) {
        setPPA(event.target.checked);
        handleChange(event);
    }

    function handleChangeInt(event: any) {
        if (event.target.name === "additional_ppp" && event.target.value === "") {
            event.target.value = event.target.valueAsNumber || 0;
        }
        handleChange(event);
    }

    return (
        <div className="mb-2">
            <label className="form-label"><b>Prezzo per struttura / persona *</b></label>
            <div className="custom-control custom-switch">
                <input
                    name="is_ppa"
                    defaultChecked={isPPA}
                    value="is_ppa"
                    type="checkbox"
                    className="custom-control-input"
                    id="ppa-toggle"
                    onChange={handleToggle}
                />
                <label className="custom-control-label" htmlFor="ppa-toggle">
                    { isPPA ? "Prezzo per struttura" : "Prezzo per persona" }
                </label>
            </div>
            { isPPA &&
                (<>
                    <label className="form-label mt-2"><b>Costo addizionale per persona</b></label>
                    <div className="input-group" style={{width: "120px"}}>
                        <input type="number" min="0" step=".01" name="additional_ppp" value={formValues?.additional_ppp} onChange={handleChangeInt} className="form-control text-center" />
                        <span className="input-group-text">€</span>
                    </div>
                </>)
            }
        </div>
    )
}