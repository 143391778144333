import axios from "axios";
import authHeader from "./auth-header";
import {AccommodationModel, FacilityModel} from "../models/Accommodation";
import {dateToString} from "../services/Planner.service";
const API_URL = process.env.REACT_APP_API_URL + "/api/accommodations";

export const getReferentialsList = () => {
  // @ts-ignore
  return axios.get(API_URL + "/referentials");
};

export const getFiltersList = () => {
  // @ts-ignore
  return axios.get(API_URL + "/filters");
};

export const getAccommodationBySlug = (slug: string) => {
  // @ts-ignore
  return axios.get(API_URL + "/" + slug, { headers: authHeader() });
};

export const getAccommodationList = (host_id: string) => {
  // @ts-ignore
  return axios.get(API_URL + "", { params: { host: host_id } });
};

export const generateFormDataAccommodation = (data: AccommodationModel) => {
  const bodyFormData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    if(key === "facilities") {
      bodyFormData.append(key, JSON.stringify(value));
    } else if(key === "beds") {
      bodyFormData.append(key, JSON.stringify(value));
    } else {
      bodyFormData.append(key, value);
    }
  }
  return bodyFormData;
}

export const addNewAccommodation = async (data: AccommodationModel) => {
  const jsonData = JSON.stringify(data);
  // @ts-ignore
  return await axios.post(API_URL + "", jsonData, { headers: authHeader(true) });
}

export const cloneAccommodation = async (slug: string) => {
  // @ts-ignore
  return await axios.post(API_URL + "/" + slug + "/clone", {}, { headers: authHeader(true) });
}

export const updateAccommodation = async (data: AccommodationModel) => {
  if (data.additional_ppp === null) data.additional_ppp = 0;
  const jsonData = JSON.stringify(data);
  // @ts-ignore
  return await axios.patch(API_URL + "/" +data.slug, jsonData, { headers: authHeader(true) });
}

export const addNewImageToAccommodation = async (image_url: string, slug: string ) => {
  const bodyFormData = new FormData();
  bodyFormData.append('url', image_url);
  bodyFormData.append('slug', slug);
  // @ts-ignore
  return await axios.post(API_URL + "/image", bodyFormData, { headers: authHeader() });
}

export const deleteImageToAccommodation = async (id: string) => {
  // @ts-ignore
  return await axios.delete(API_URL + "/image/" + id,{ headers: authHeader() });
}

export const setActiveAccommodation = async (status: boolean, slug: string) => {
  const bodyFormData = new FormData();
  bodyFormData.append('is_active', String(status));
  // @ts-ignore
  return await axios.patch(API_URL + "/" + slug + "/toggle", bodyFormData, { headers: authHeader() });
}

export const getAccommodationMapList = (filters?: FacilityModel[], type?: { value: string, label: string }[], startDate?: Date | null, endDate?: Date | null, guests?: number | null) => {
  let args = generateMapArgsString(filters, type);
  if (startDate && startDate !== null) {
    const dateString = dateToString(startDate);
    args += '&start_date=' + dateString;
  }
  if (endDate && endDate !== null) {
    const dateString = dateToString(endDate);
    args += '&end_date=' + dateString;
  }
  if (guests && guests > 0) {
    args += '&guests=' + guests;
  }
  // @ts-ignore
  return axios.get(API_URL + "/map.geojson" + '?' + args );
};

export const getAccommodationPublicBySlug = (slug: string) => {
  // @ts-ignore
  return axios.get(API_URL + "/public/" + slug);
};

export const getAccommodationsPublicBySlug = (slugs: string[]) => {
  let slugs_concat = ""
  slugs.forEach((s: string) => {
    slugs_concat += s + ','
  });
  slugs_concat = slugs_concat.substring(0, slugs_concat.length-1);
  // @ts-ignore
  return axios.get(API_URL + "/public?slugs=" + slugs_concat);
};

export const getAccommodationsPublicByHost = (host_id: string) => {
  // @ts-ignore
  return axios.get(API_URL + "/public?host=" + host_id);
};

function generateMapArgsString(filters?: FacilityModel[], type?: { value: string, label: string }[]) {
  const args_filter = filters?.map((f:FacilityModel) => f.name);
  const args_type = type?.map((t: { value: string, label: string }) => t.value);
  const args_f = (args_filter !== undefined && args_filter?.length > 0) ? args_filter?.reduce((pv, cv) => {
      return pv + cv + ',';
  }, "").slice(0, -1) : undefined;

  const args_t = (args_type !== undefined && args_type?.length > 0) ? args_type?.reduce((pv, cv) => {
    return pv + cv + ',';
  }, "").slice(0, -1) : undefined;
  return ((args_f !== undefined) ? ('facilities=' + args_f) : '') + ((args_t !== undefined) ? ('&typologies=' + args_t) : '');
}