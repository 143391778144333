import {AccommodationPublicModel} from "./Accommodation";
import * as Yup from "yup";
import {GuestAdminBaseModel} from "./Admin";

export interface BuddyModel {
    "id"?: number,
    "name": string,
    "age": number,
    "job": string,
}

export const getEmptyBuddy = () : BuddyModel => ({
    "name": '',
    "age": 18,
    "job": '',
})

export interface ReservationStateModel {
    "status": string,
    "created_at": string,
    "note": string,
}

export interface ReservationModel {
    "slug": string,
    "checkin_date": string,
    "checkout_date": string,
    "created_at": string,
    "updated_at": string,
    "total_price": number,
    "pax": number,
    "avg_ppd": number,
    "no_nights": number,
    "host_note": string,
    "accommodation": AccommodationPublicModel,
    "buddies": BuddyModel[],
    "statuses": ReservationStateModel[],
    "guest": GuestAdminBaseModel,
}

export interface ReservationChatMessageModel {
    "message": string,
    "role": string,
    "sender": string,
    "created_at": string,
    "seen_at": string
}

export const BuddyModelSchema =
    Yup.object({
        name: Yup.string()
            .min(2, 'Nome buddy troppo corto')
            .max(50, 'Nome buddy troppo lungo, max 50!')
            .required('Inserire il nome dell travel buddy'),
        age: Yup.number().required('Inserire l\'eta\' del buddy')
            .positive('L\'eta\' deve essere maggiore di 0')
            .integer('Inserire un numero valido'),
        job: Yup.string()
            .min(2, 'Lavoro troppo corto')
            .max(50, 'Lavoro troppo lungo, max 50!')
            .required('Inserire il lavoro dell travel buddy'),
    });