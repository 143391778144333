import React, {useEffect, useState} from "react";
import {isAuth, logout} from "../../services/Auth.service";
import {Link, useNavigate} from "react-router-dom";
import {ReservationModel} from "../../models/Reservation";
import {
    getHostReservationsList,
    setHostReservationStatus,
    stateToBadge,
    statusCodeToString
} from "../../services/Reservation.service";
import {Accordion} from "../../components/shared/Accordion.component";
import {AdminFilter} from "../../services/Admin.service";
import {spinnner} from "../../components/shared/Spinner.component";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';
import {dateStringToString} from "../../models/Planner";
import {svg_check_circle, svg_eye_open, svg_reject} from "../../components/shared/SVG.component";

const allStatuses: string[] = ["pending_approval", "pending_payment", "paid", "rejected", "cancelled", "completed"];
const startingStatuses: string[] = ["pending_approval", "pending_payment", "paid", "rejected", "cancelled", "completed"];

export function HostListReservations(){
    registerLocale('it', it);
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [reservationsList, setReservationsList] = useState<ReservationModel[]>([]);
    const [statuses, setStatuses] = useState<string[]>(startingStatuses);
    const [ordering, setOrdering] = useState<string>("checkin_date-desc");
    const [search, setSearch] = useState<string>("");
    const [from, setFrom] = useState<Date | null>(new Date());
    const [to, setTo] = useState<Date | null>(null);

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        }
        fetchData();
    }, [from, to, ordering, statuses]);

    function fetchData() {
        setLoading(true);
        getHostReservationsList(from, to, statuses, ordering, search).then(res => {
            setReservationsList(res.data);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false);
        });
    }

    function handleStatuses(e: any) {
        const checked = e.target.checked;
        const value = e.target.value;
        if(checked) {
            setStatuses([...statuses, value]);
        } else {
            setStatuses(statuses.filter(f => f !== value));
        }
    }

    const handleChangeDates = (dates: [Date | null, Date | null]) => {
        const [_from, _to] = dates;
        setFrom(_from);
        setTo(_to);
    };

    return (
        <div className="row">
            <div className="col-12">
                <h3 className="text-nuvala-blue">Le mie prenotazioni</h3>
            </div>
            <div className="col-12">
                <Accordion title="Filtri" open={true}>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Ordina per
                            </label>
                            <select className="form-select" value={ordering} onChange={(e) => setOrdering(e.target.value)} >
                                <option value="checkin_date-desc">Data check-in discendente</option>
                                <option value="checkin_date-asc">Data check-in ascendente</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Cerca codice prenotazione
                            </label>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control text-nuvala-blue" value={search}
                                       onChange={(e) => setSearch(e.target.value)}
                                       placeholder="Cerca per codice prenotazione"
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-nuvala rounded-0" type="button"
                                            onClick={fetchData}
                                    >Cerca</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Filtra date
                            </label>
                            <div className="input-group mb-3">
                                <DatePicker
                                    locale="it"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Seleziona le date"
                                    startDate={from}
                                    endDate={to}
                                    onChange={handleChangeDates}
                                    selectsRange
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {
                            allStatuses.map((s: string) => {
                                return (
                                    <div key={"stat-" + s}  className="col-12 col-md-3">
                                        <div className="form-check mt-2">
                                            <input className="form-check-input" type="checkbox"
                                                   name="status"
                                                   value={s}
                                                   checked={statuses.some(stat => stat === s)}
                                                   onChange={handleStatuses}/>
                                            <label className="form-check-label">
                                                {statusCodeToString(s)}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Accordion>
            </div>
            <div className="col-12 text-center">
                {
                    loading && spinnner
                }
            </div>
            {   !loading &&
                <>
                    <div className="col-12">
                        <div className="table-responsive p-0">
                            <table className="table table-bordered text-nuvala-blue">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col">codice</th>
                                        <th className="px-2" scope="col">struttura</th>
                                        <th className="px-2" scope="col">stato</th>
                                        <th className="px-2" scope="col">check-in</th>
                                        <th className="px-2" scope="col">check-out</th>
                                        <th className="px-2" scope="col">guest</th>
                                        <th className="px-2" scope="col">pax</th>
                                        <th className="px-2" scope="col">notti</th>
                                        <th className="px-2" scope="col">prezzo</th>
                                        <th scope="col">azioni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    reservationsList.map((r: ReservationModel) => {
                                        return (
                                            <tr key={"res-" + r.slug} className="align-middle text-center">
                                                <th className="text-yellow" scope="row">{r.slug}</th>
                                                <td className="px-2">{r.accommodation.slug}</td>
                                                <td className="px-2">{stateToBadge(r.statuses[0])}</td>
                                                <td className="px-2">{dateStringToString(r.checkin_date)}</td>
                                                <td className="px-2">{dateStringToString(r.checkout_date)}</td>
                                                <td className="px-2">{r.guest.guest_name} {r.guest.guest_surname}</td>
                                                <td className="px-2">{r.pax}</td>
                                                <td className="px-2">{r.no_nights}</td>
                                                <td className="px-2">{r.total_price} €</td>
                                                <td>
                                                    <HostListReservationsActions reservation={r} setReservationsList={setReservationsList} reservationsList={reservationsList} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 text-nuvala-blue">
                        { reservationsList.length === 0 && <p>Non abbiamotrovato prenotazioni per i filtri selezionati</p>}
                    </div>
                </>
            }

        </div>
    );
}

interface HostListReservationsActionsProps {
    reservation: ReservationModel,
    setReservationsList: (res: ReservationModel[]) => void,
    reservationsList: ReservationModel[],
}
export function HostListReservationsActions({reservation, setReservationsList, reservationsList}: HostListReservationsActionsProps) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const setReservationApproval = (approved: boolean) => {
        setLoading(true);
        if(message.length >= 50 || approved) {
            setHostReservationStatus(reservation.slug, approved, approved ? "" : message).then(res => {
                setReservationsList([...reservationsList.filter(br => br.slug !== reservation.slug), res.data]);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setMessage("");
                setLoading(false);
                setOpen(false);
            });
        }
    }

    return (
        <>
        {
            (reservation.statuses[0].status === "pending_approval") &&
            <a className="btn btn-sm m-1 btn-outline-nuvala nuvala-green-bg" onClick={() => setReservationApproval(true)}>
                { loading && spinnner }
                { svg_check_circle(16) }
            </a>
        }
        {
            (reservation.statuses[0].status === "pending_payment" || reservation.statuses[0].status === "pending_approval") &&
            <a className="btn btn-sm m-1 btn-outline-nuvala nuvala-red-bg" onClick={() => setOpen(true)}>
                { svg_reject(16) }
            </a>
        }
            <Link to={ "/host/reservation/" + reservation.slug } className="btn btn-sm m-1 btn-outline-nuvala">
                { svg_eye_open(16) }
            </Link>
            <div className={"modal fade" + ((open) ? "show d-block" : "")} >
                <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "400px"}}>
                    <div className="modal-content nuvala-modal text-nuvala-blue" style={{textAlign: "left"}}>
                        <p>Inserisci perche' stai rifiutando la prenotazione. Questo messaggio sara' recapitato al guest</p>
                        <div className="col mb-4">
                            <textarea rows={4} name="description" maxLength={200}
                                      className="form-control" placeholder="Messaggio"
                                      value={message} onChange={(e) => setMessage(e.target.value)}/>
                            <small className="float-end text-nuvala-blue">{message.length} caratteri (min 50, max 200)</small>
                        </div>
                        <div className="btn-nuvala-bottom-right-group">
                            <div className="d-flex justify-content-between">
                                <a onClick={() => setOpen(false)} className="py-2 px-3 text-nuvala-blue" style={{textDecoration: "none"}}>
                                    chiudi
                                </a>
                                <a className={"py-2 px-3 b-nuvala-left text-nuvala-blue" + ((message.length < 50) ? " disabled" : "")}
                                   style={{textDecoration: "none"}}
                                   onClick={() => setReservationApproval(false)}>
                                    { loading && spinnner }
                                    salva
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}