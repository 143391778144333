import type {LayerProps} from 'react-map-gl';

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'symbol',
  source: 'nuvala',
  filter: ['has', 'point_count'],
  layout: {
    'icon-image': [
        'case',
        ['get', 'nuvala-fest'],
        "blob-acc-fest",
        "blob-acc-01",
    ],
    'icon-allow-overlap': true,
    'icon-size': 0.2,
  },
};
// Idea use {clster_id} to concat the icon-image for clusters
export const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'symbol',
  source: 'nuvala',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-allow-overlap': true,
    'icon-size': 0.2,
    //'text-field': ['get', 'name'],
    //'text-font': ['Roboto Regular', 'Arial Unicode MS Bold'],
    //'text-size': 12,
  },
  paint: {
    'icon-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.5,
      1
    ]
  }
};