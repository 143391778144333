import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../shared/UserContext";
import {useNavigate} from "react-router-dom";
import {
    getEmptyUserRegister,
    UserRegisterSchema,
    UserRegister,
    GuestModel,
    HostModel,
    getEmptyGuestModel, getEmptyHostModel, GuestJobs, HostModelSchema, GuestModelSchema
} from "../models/User";
import {Toast} from "../components/shared/Toast.component";
import {NationsLocales} from "../services/Locale.service";
import {register} from "../services/Auth.service";
import bg from "../assets/images/backgrounds/bg-7.jpg";
import {spinnner} from "../components/shared/Spinner.component";

export default function Register() {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState<string>("base");
    const [formValues, setFormValues] = useState<UserRegister>(getEmptyUserRegister);
    const [formValuesGuest, setFormValuesGuest] = useState<GuestModel>(getEmptyGuestModel);
    const [formValuesHost, setFormValuesHost] = useState<HostModel>(getEmptyHostModel);
    const [formError, setFormError] = useState<string[]>([]);

    let navigate = useNavigate();
    const {user, signIn} = useContext(UserContext);

    useEffect(() => {
        if (user !== null) {
            navigate("/")
        }
    }, [user]);

    function handleChange(event: any) {
        let value = event.target.value;
        const name = event.target.name;
        if (name === "is_host") value = !formValues.is_host;
        setFormValues({ ...formValues, [name]: value })
    }
    function handleChangeGuest(event: any) {
        setFormValuesGuest({ ...formValuesGuest, [event.target.name]: event.target.value })
    }
    function handleChangeHost(event: any) {
        setFormValuesHost({ ...formValuesHost, [event.target.name]: event.target.value })
    }

    async function handleGuestSubmit() {
        setLoading(true);
        try {
            await GuestModelSchema.validate(formValuesGuest, {abortEarly: false}).then((validation: any) => {
                register(formValues, formValuesGuest).then(
                    res => {
                        setFormError(["Profilo aggiornato correttamente!"]);
                        setState("completed");
                    }).catch(err => {
                        setFormError(["Qualcosa e' andato storto:", err.response.data.message]);
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });
            });
        } catch (err: any) {
            setFormError(err.errors);
            setLoading(false);
        }
    }
    async function handleHostSubmit() {
        setLoading(true);
        try {
            await HostModelSchema.validate(formValuesHost, {abortEarly: false}).then((validation: any) => {
                register(formValues, formValuesHost).then(
                    res => {
                        setFormError(["Profilo aggiornato correttamente!"]);
                        setState("completed");
                    }).catch(err => {
                        setFormError(["Qualcosa e' andato storto:", err.response.data.message]);
                    }).finally(() => {
                        setLoading(false);
                    });
            });
        } catch (err: any) {
            setFormError(err.errors);
            setLoading(false);
        }
    }
    async function handleBasicSubmit() {
        setLoading(true);
        try {
            await UserRegisterSchema.validate(formValues, {abortEarly: false}).then((validation: any) => {
                if(formValues.is_host) setState("host");
                else setState("guest");
                setLoading(false);
            });
        } catch (err: any) {
            setFormError(err.errors);
            setLoading(false);
        }
    }

    return (
        <div className="container-fluid px-0">
            <div className="nuvala-hero vh-100" style={{backgroundImage: "url("+ bg +")", backgroundSize: "cover", paddingTop: "30px"}}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-4 offset-lg-4 my-md-auto">
                            <div className="nuvala-modal nuvala-modal-sm">
                                { formError.length > 0 ? <Toast text={formError} /> : "" }
                                <form>
                                    {
                                        (state === "base") &&
                                        <RegisterBasic formValues={formValues} handleChange={handleChange} handleBasicSubmit={handleBasicSubmit}/>
                                    }
                                    {
                                        (state === "host") && <RegisterHost formValues={formValuesHost}
                                                                handleChange={handleChangeHost}
                                                                handleHostSubmit={handleHostSubmit}
                                                                setState={setState}
                                                                loading={loading}
                                                                />
                                    }
                                    {
                                        (state === "guest") && <RegisterGuest formValues={formValuesGuest}
                                                                handleChange={handleChangeGuest}
                                                                handleGuestSubmit={handleGuestSubmit}
                                                                setState={setState}
                                                                loading={loading}
                                                                />
                                    }
                                    {
                                        (state === "completed") && (
                                            <>
                                                <h4 className="text-nuvala-blue">Registrazione Completata!</h4>
                                                <p className="text-nuvala-blue">Ti abbiamo inviato una email di
                                                    conferma all'indirizzo <b>{formValues.email} </b>
                                                    dove troverai un link per attivare il tuo account <b>:)</b></p>
                                            </>
                                        )
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface RegisterBasicProps {
    formValues: UserRegister,
    handleChange: (event: any) => void,
    handleBasicSubmit: () => void,
}
function RegisterBasic(props: RegisterBasicProps) {
    return (
        <>
            <h4 className="text-nuvala-blue">Benvenuto su Nuvala</h4>
            <div className="form-group mt-4">
                <label htmlFor="email"><b>Email *</b></label>
                <input name="email" type="text" className="form-control"
                    value={props.formValues.email} onChange={props.handleChange}/>
            </div>
            <div className="form-group mt-4">
                <label htmlFor="password"><b>Password *</b></label>
                <input name="password" type="password" className="form-control" autoComplete="on"
                    value={props.formValues.password} onChange={props.handleChange}/>
            </div>
            <div className="form-group mt-4">
                <label htmlFor="password"><b>Conferma Password *</b></label>
                <input name="passwordConfirmation" type="password" className="form-control" autoComplete="on"
                    value={props.formValues.passwordConfirmation} onChange={props.handleChange}/>
            </div>
            <div className="form-check mt-4">
                <input className="form-check-input" type="checkbox" name="is_host"
                       checked={props.formValues.is_host} onChange={props.handleChange} />
                <label className="form-check-label ms-2" style={{lineHeight: "2.1rem"}}>
                    Voglio diventare un host
                </label>
            </div>
            <div className="form-check mt-2">
                <a className="btn-nuvala-bottom-right" onClick={props.handleBasicSubmit}>
                    continua
                </a>
            </div>
        </>
    )
}

interface RegisterGuestProps {
    formValues: GuestModel,
    handleChange: (event: any) => void,
    handleGuestSubmit: () => void,
    setState: React.Dispatch<React.SetStateAction<string>>,
    loading: boolean,
}
function RegisterGuest(props: RegisterGuestProps) {
    return (
        <>
            <h4 className="text-nuvala-blue">Benvenuto su Nuvala</h4>
            <small className="text-nuvala-blue">Informazioni aggiuntive</small>

            <div className="row mt-4 text-nuvala-blue">
                <div className="col-6">
                    <label><b>Nome *</b></label>
                    <input name="guest_name" value={props.formValues.guest_name}
                        type="text" className="form-control" onChange={props.handleChange} />
                </div>
                <div className="col-6">
                    <label><b>Cognome *</b></label>
                    <input name="guest_surname" value={props.formValues.guest_surname}
                        type="text" className="form-control" onChange={props.handleChange} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-7">
                     <label><b>Lavoro *</b></label>
                     <input name="job_title" value={props.formValues.job_title}
                           type="text" className="form-control" onChange={props.handleChange} />
                </div>
                <div className="col-5">
                    <label><b>Eta' *</b></label>
                    <input name="age" value={props.formValues.age}
                           type="number" className="form-control" onChange={props.handleChange} />
                </div>
            </div>
            <div className="row mt-3">
                 <div className="form-group col-5">
                     <label htmlFor="inputState"><b>Prefisso</b></label>
                     <select name="country_code" value={props.formValues.country_code}  className="form-control" onChange={props.handleChange} >
                         <option value="" disabled>Seleziona prefisso nazionale</option>
                         { NationsLocales.map(l => (<option key={l.code} value={l.code}>{l.code} {l.name}</option>))}
                     </select>
                 </div>
                 <div className="form-group col-7">
                     <label htmlFor="inputCity"><b>Telefono *</b></label>
                     <input name="phone_number" value={props.formValues.phone_number}
                            type="text" className="form-control" onChange={props.handleChange} />
                 </div>
            </div>
            <div className="row mt-3 mb-5">
                <div className="col">
                    <label className="form-label"><b>Bio</b></label>
                    <textarea rows={4} name="bio" maxLength={500}
                              className="form-control" placeholder="Biografia"
                              value={props.formValues.bio} onChange={props.handleChange}/>
                    <small className="text-muted float-end">{props.formValues.bio.length} caratteri (max 500)</small>
                </div>
            </div>
            <div className="btn-nuvala-bottom-right-group">
                <div className="d-flex justify-content-between text-nuvala-blue">
                    <a onClick={(() => props.setState("base"))} className="py-2 px-3">
                        <i className="nuvala-arrow-left " />
                    </a>
                    <a className="py-2 px-3 b-nuvala-left text-nuvala-blue" style={{textDecoration: "none"}} onClick={props.handleGuestSubmit}>
                        { props.loading && spinnner}
                        registrati
                    </a>
                </div>
            </div>
        </>
    )

}

interface RegisterHostProps {
    formValues: HostModel,
    handleChange: (event: any) => void,
    handleHostSubmit: () => void,
    setState: React.Dispatch<React.SetStateAction<string>>,
    loading: boolean,
}
function RegisterHost(props: RegisterHostProps) {
    return (
        <>
            <h4 className="text-nuvala-blue">Benvenuto su Nuvala</h4>
            <small className="text-nuvala-blue">Informazioni aggiuntive</small>
             <div className="form-group mt-4">
                 <label>Nome Struttura</label>
                 <input name="name" value={props.formValues.name}
                        type="text" className="form-control" onChange={props.handleChange} />
             </div>
             <div className="row mt-2 text-nuvala-blue">
                 <div className="col-6">
                     <label>Nome</label>
                     <input name="owner_name" value={props.formValues.owner_name}
                            type="text" className="form-control" onChange={props.handleChange} />
                 </div>
                 <div className="col-6">
                     <label>Cognome</label>
                     <input name="owner_surname" value={props.formValues.owner_surname}
                            type="text" className="form-control" onChange={props.handleChange} />
                 </div>
             </div>
             <div className="form-group mt-2">
                 <label>Indirizzo</label>
                 <input name="address" value={props.formValues.address}
                        type="text" className="form-control" onChange={props.handleChange} />
             </div>
             <div className="form-group mt-2">
                 <label>Partita IVA</label>
                 <input name="vat_number" value={props.formValues.vat_number}
                        type="text" className="form-control" onChange={props.handleChange} />
             </div>
             <div className="row mt-2 mb-5">
                 <div className="form-group col-5">
                     <label htmlFor="inputState">Prefisso</label>
                     <select name="country_code" value={props.formValues.country_code}  className="form-control" onChange={props.handleChange} >
                         <option value="" disabled>Seleziona prefisso nazionale</option>
                         { NationsLocales.map(l => (<option key={l.code} value={l.code}>{l.code} {l.name}</option>))}
                     </select>
                 </div>
                 <div className="form-group col-7">
                     <label htmlFor="inputCity">Telefono</label>
                     <input name="phone_number" value={props.formValues.phone_number}
                            type="text" className="form-control" onChange={props.handleChange} />
                 </div>
             </div>
            <div className="btn-nuvala-bottom-right-group">
                <div className="d-flex justify-content-between text-nuvala-blue">
                    <a onClick={(() => props.setState("base"))} className="py-2 px-3">
                        <i className="nuvala-arrow-left " />
                    </a>
                    <a className="py-2 px-3 b-nuvala-left text-nuvala-blue" style={{textDecoration: "none"}} onClick={props.handleHostSubmit}>
                        { props.loading && spinnner}
                        registrati
                    </a>
                </div>
            </div>
        </>
    )

}