import React, {useEffect, useState} from "react";
import logo from "../assets/images/logoLanding.svg";
import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {NuvalaMapMultiPopup} from "../components/map_multi_popup/NuvalaMapMultiPopup.component";
import {NuvalaMapPopup} from "../components/map_popup/NuvolaMapPopup.component";
import {getEmptyLocation, UserLoc} from "../services/Locale.service";
import bg from "../assets/images/backgrounds/bg-manifesto-01.jpg";
import {getAccommodationList, getAccommodationsPublicByHost} from "../services/Accommodation.service";
import {AccommodationPublicModel} from "../models/Accommodation";
import {Simulate} from "react-dom/test-utils";

export function BookPage() {
    // @ts-ignore
    const { slugs_string = "" } = useParams<string>();
    const { host = "" } = useParams<string>();

    const [userLocation, setUserLocation] = useState<UserLoc>(getEmptyLocation());
    const [selectedAccommodation, setSelectedAccommodation] = useState<string>("");
    const [selectedAccommodations, setSelectedAccommodations] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [slugs, setSlugs] = useState<string[]>([]);

    let navigate = useNavigate();

    useEffect(() => {
        getAccommodationsPublicByHost(host).then(res => {
            if(res.data.length === 0) navigate('/');
            setSlugs(res.data.map((a: AccommodationPublicModel) => a.slug));
        })
        .catch(err => console.log(err))
        .finally(() => {

        });
    }, [host]);

    useEffect(() => {
        refreshSlug()
    }, [slugs])

    const refreshSlug = () => {
        if(slugs.length === 0) {

        } else if (slugs.length === 1) {
            setSelectedAccommodation(JSON.parse(JSON.stringify(slugs[0])));
        } else {
            setSelectedAccommodations(JSON.parse(JSON.stringify(slugs)));
        }
    }

    return (
        <div className="container-fluid px-0">
            <div className="nuvala-hero min-vh-100" style={{backgroundImage: "url("+ bg +")", height: "auto", backgroundRepeat: "repeat-y"}}>
                <NuvalaMapPopup
                    accommodation_slug={selectedAccommodation}
                    userLocation={userLocation}
                    setSelectedAccommodation={setSelectedAccommodation}
                    startDateIn={startDate}
                    endDateIn={endDate}
                    modalCloseable={false}
                    refreshSlug={refreshSlug}
                />
                <NuvalaMapMultiPopup
                    accommodation_slugs={selectedAccommodations}
                    setSelectedAccommodation={setSelectedAccommodation}
                    modalCloseable={false}
                />
            </div>
        </div>
    );
}