import React, {useContext, useEffect, useState} from 'react';
import {SetPasswordRecovery, StartPasswordRecovery, VerifyAndLogin} from "../services/Auth.service";
import {UserContext} from "../shared/UserContext";
import logo from '../assets/images/logoLanding.svg';
import {useNavigate} from "react-router-dom";
import {
    getEmptyUserRecoverPassword,
    UserRecoverPassword, UserRecoverPasswordSchema,
    UserRegisterSchema,
} from "../models/User";
import {Toast} from "../components/shared/Toast.component";
import { reach } from 'yup';
import {useParams} from "react-router";
import bg from "../assets/images/backgrounds/bg-7.jpg";
import {spinnner} from "../components/shared/Spinner.component";

export function RecoverPasswordInit() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string>("");
    const [formError, setFormError] = useState<string[]>([]);
    const [completed, setCompleted] = useState(false);

    let navigate = useNavigate();
    const {user, signIn} = useContext(UserContext);

    useEffect(() => {
        if (user !== null) {
            navigate("/")
        }
    }, [user]);

    async function handleSubmit(event: any) {
        event.preventDefault();
        setLoading(true);
        try {
            await reach(UserRegisterSchema, 'email').validate(email).then((validation: any) => {
                StartPasswordRecovery(email).then(res => {
                    setCompleted(true);
                }).catch(err => {
                    setFormError(["Qualcosa e' andato storto:", err.response.data.message]);
                }).finally(() => {
                    setLoading(false);
                })
            })
        } catch (err: any) {
            setFormError(err.errors);
            setLoading(false);
        }
    }

    if (completed) return (
        <div className="container-fluid px-0">
            <div className="nuvala-hero vh-100" style={{backgroundImage: "url("+ bg +")"}}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-5 my-auto">
                            <div className="nuvala-modal">
                                <h4>Email di Recupero inviata!</h4>
                                <p>Ti abbiamo inviato una email di
                                    all'indirizzo <b>{email} </b>
                                    dove troverai un link per reimpostare la password <b>:)</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className="container-fluid px-0">
            <div className="nuvala-hero vh-100" style={{backgroundImage: "url("+ bg +")"}}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-5 my-auto">
                            <div className="nuvala-modal">
                                { formError.length > 0 ? <Toast text={formError} /> : "" }
                                <form>
                                    <div className="text-center">
                                        <img src={logo} className="img-fluid my-3" style={{maxWidth: "200px"}}/>
                                        <p className="mt-2 text-nuvala-blue">
                                            inserisci la tua email per ricevere un messaggio contenente un link che ti permettera' di recuperare la password.
                                        </p>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label htmlFor="username">Email</label>
                                        <input name="email" type="text" className="form-control"
                                               value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    <div className="form-group mt-5">
                                        <a className="btn-nuvala-bottom-right" onClick={handleSubmit}>
                                            { loading && spinnner}
                                            Invia email di recupero
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function RecoverPasswordModule() {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState<UserRecoverPassword>(getEmptyUserRecoverPassword());
    const [formError, setFormError] = useState<string[]>([]);
    const [completed, setCompleted] = useState(false);
    const { token = "" } = useParams<string>();

    let navigate = useNavigate();
    const {user, signIn} = useContext(UserContext);

    useEffect(() => {
        if (user !== null) {
            navigate("/")
        }
    }, [user]);

    function handleChange(event: any) {
        setFormValues({ ...formValues, [event.target.name]: event.target.value })
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        setLoading(true);
        try {
            await UserRecoverPasswordSchema.validate({...formValues, token: token}).then(validation => {
                SetPasswordRecovery(formValues, token).then(res => {
                    if(res !== undefined && res !== null) {
                        navigate("/login");
                    } else {
                        setFormError(["Qualcosa e' andato storto! riprova"]);
                    }
                }).catch(err => {
                    setFormError(["Qualcosa e' andato storto:", err.response.data.message]);
                }).finally(() => {
                    setLoading(false);
                });
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    return (
        <div className="container-fluid px-0">
            <div className="nuvala-hero vh-100" style={{backgroundImage: "url("+ bg +")"}}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-5 my-auto">
                            <div className="nuvala-modal">
                                { formError.length > 0 ? <Toast text={formError} /> : "" }
                                <form>
                                    <input type="hidden" name="token" value={token} />
                                    <div className="text-center">
                                        <img src={logo} className="img-fluid my-3" style={{maxWidth: "200px"}}/>
                                        <p className="mt-2 text-nuvala-blue">
                                            inserisci e conferma la nuova password, dopo verrai automaticamente autenticato su Nuvala!
                                        </p>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label htmlFor="username">Password *</label>
                                        <input name="password" type="password" className="form-control"
                                               autoComplete="on"
                                               value={formValues.password} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label htmlFor="password">Conferma Password *</label>
                                        <input name="passwordConfirmation" type="password" className="form-control"
                                               autoComplete="on"
                                               value={formValues.passwordConfirmation} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group mt-4 mb-5">
                                        <a className="btn-nuvala-bottom-right" onClick={handleSubmit}>
                                            { loading && spinnner}
                                            Aggiorna Password
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}