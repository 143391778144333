import React, {useContext, useEffect, useState} from 'react';
import bg from "../assets/images/backgrounds/bg-7.jpg"
import {Marquee} from "../components/shared/Marquee.component";

import locandinaConcept from "../assets/images/locandine/concept.jpg"
import locandina2022 from "../assets/images/locandine/2022.jpg"
import locandina2023 from "../assets/images/locandine/2023.jpg"
import {ImageLightBox} from "../components/shared/ImageLightBox.component";

const imagesConcept = require.context('../assets/images/nuvala_concept', true);
const imagesConceptList = imagesConcept.keys().map(imagesConcept);

const imagesNuvala2023 = require.context('../assets/images/nuvala_fest_2023', true);
const imagesNuvala2023List = imagesNuvala2023.keys().map(imagesNuvala2023);

const video = ( 
    <iframe width="560" height="315"
        src="https://www.youtube.com/embed/Gveltw4Ctcs"
        title="YouTube video player" frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen></iframe>
)

export function Manifesto() {
    const [manifestoIDX, setManifestoIDX] = useState(0);

    useEffect(() => {
        const intervalID = setTimeout(() =>  {
            const next = manifestoIDX + 1;
            setManifestoIDX(next);
        }, 8000);

        return () => clearInterval(intervalID);
    });

    return (
        <>
        <div className="container-fluid px-0">
            <div className="nuvala-hero min-vh-100" style={{backgroundImage: "url("+ bg +")", height: "auto", backgroundRepeat: "repeat-y"}}></div>
            <div className="position-absolute top-0 start-0 w-100 first-block-manifesto">
                <div className="container">
                    <div className="d-flex align-content-center mt-5">
                        <div className="d-flex justify-content-around">
                            <div className="col-4 col-md-3 p-4 text-center">

                                <ImageLightBox srcs={[locandinaConcept, ...imagesConceptList]} wrapperStyle="img-fluid" keepRatio={true} singleImage={true} />

                                <div className="text-start" style={{display: "inline-block"}}>
                                    <p className="text-light mt-3 mb-1">NUVALA CONCEPT</p>
                                    <p>
                                        <small className="text-light text-start">
                                            Foto: Aldo Dith <br />
                                            Styling: Opale Studio
                                        </small>
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 col-md-3 p-4 text-center">
                                <ImageLightBox srcs={[locandina2022, video]} wrapperStyle="img-fluid" keepRatio={true} singleImage={true} />
                                
                                <div className="text-start" style={{display: "inline-block"}}>
                                    <p className="text-light mt-3 mb-1">NUVALA FEST 2022</p>
                                </div>
                            </div>
                            <div className="col-4 col-md-3 p-4 text-center">
                            <ImageLightBox srcs={[locandina2023, ...imagesNuvala2023List]} wrapperStyle="img-fluid" keepRatio={true} singleImage={true} />
                                
                                <div className="text-start" style={{display: "inline-block"}}>
                                    <p className="text-light mt-3 mb-1">NUVALA FEST 2023</p>
                                    <p>
                                        <small className="text-light text-start">
                                            Foto: Loredana Lavino
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid px-0 d-none d-md-block">
            <Marquee slow={true}
                text="immagina: stai lavorando a casa o in un ufficio o
                in agenzia o in studio o in openspace o in hangar
                in città nella metropoli sei stressato non hai
                tempo non hai aria spazio calma quiete relax ma
                solo frenesia e deadline e allora sbuffi fumi
                svapi scrolli tamburelli ti incazzi ti annoi mangi
                veloce non dormi non respiri non fai niente di
                nulla per te stesso se non sognare e poi un
                giorno dopo la riunione rimani seduto al tavolo
                e guardi fuori dalla finestra e vedi una nuvola
                bianca veloce nel cielo azzurro — libera pura
                serena e quella nuvola ti incanta è il tuo
                spirito guida che ti chiama vorresti essere come
                quella nuvola - e ora immagina davvero: se tu
                scoprissi che puoi essere più leggero di quella
                nuvola e che quella nuvola è a meno di cento
                chilometri da te e puoi raggiungerla?
                e allora respiri."/>
        </div>
        </>
    )
}