import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {isAuth, logout} from "../../services/Auth.service";
import {AdminFilter, getAdminAccommodations, getAdminUsers} from "../../services/Admin.service";
import {AccommodationAdminModel, UserAdminModel} from "../../models/Admin";
import {Toast} from "../../components/shared/Toast.component";
import {Loading} from "../../components/shared/Loading.component";
import {Accordion} from "../../components/shared/Accordion.component";
import {AdminAccommodation} from "../../components/admin/AdminAccommodation.component";

const startingFilters: AdminFilter[] = [
    {key: "is_validated", value: "true"},
    {key: "is_active", value: "true"},
]

export function AdminAccommodations() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [formError, setFormError] = useState<string[]>([]);
    const [selectedAccommodations, setSelectedAccommodations] = useState<AccommodationAdminModel[]>([]);
    const [filters, setFilters] = useState<AdminFilter[]>(startingFilters);
    const [ordering, setOrdering] = useState<string>("created_at-desc");
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        } else {
            fetchData();
        }
    }, [filters, ordering]);

    function fetchData() {
        setLoading(true);
        getAdminAccommodations(filters, ordering, search).then(res => {
            setSelectedAccommodations(res.data);
        }).catch(err => {
            setFormError(["Qualcosa e' andato storto:", err.response.data.message]);
        }).finally(() => {
            setLoading(false);
        });
    }

    function setAccommodation(acc: AccommodationAdminModel) {
        const newState = selectedAccommodations.map(a => {
            if (a.slug === acc.slug) {
                return {...a, is_validated: a.is_validated};
            }
            return a;
        });
    }

    function handleFilters(e: any) {
        const key = e.target.name;
        const checked = e.target.checked;
        const value = e.target.value;
        if(checked) {
            setFilters([...filters, {key:key, value: value}]);
        } else {
            setFilters(filters.filter(f => (!((f.key === key) && (f.value === value)))));
        }
    }

    if (loading) return <Loading />

    return (
        <div className="row">
            <div className="col-12 text-center">
                { formError.length > 0 ? <Toast text={formError} /> : "" }
            </div>
            <div className="col-12">
                <Accordion title="Filtri" open={true}>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Ordina per
                            </label>
                            <select className="form-select" value={ordering} onChange={(e) => setOrdering(e.target.value)} >
                                <option value="created_at-desc">Data registrazione descrescente</option>
                                <option value="created_at-asc">Data registrazione crescente</option>
                                <option value="name-desc">Nome alfabetico descrescente</option>
                                <option value="name-asc">Nome alfabetico crescente</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-3">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Cerca nome struttura
                            </label>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control text-nuvala-blue" value={search}
                                       onChange={(e) => setSearch(e.target.value)}
                                       placeholder="Cerca struttura per nome"
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-nuvala rounded-0" type="button"
                                            onClick={fetchData}
                                    >Cerca</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 px-5">
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox"
                                       name="is_validated"
                                       value="true"
                                       checked={filters.some(f => (f.key === "is_validated" && f.value === "true") )}
                                       onChange={handleFilters}/>
                                <label className="form-check-label">
                                    Verificate
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox"
                                       name="is_validated"
                                       value="false"
                                       checked={filters.some(f => (f.key === "is_validated" && f.value === "false") )}
                                       onChange={handleFilters}/>
                                <label className="form-check-label">
                                    Non Verificate
                                </label>
                            </div>
                        </div>
                        <div className="col-12 col-md-3  px-5">
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox"
                                       name="is_active"
                                       value="true"
                                       checked={filters.some(f => (f.key === "is_active" && f.value === "true") )}
                                       onChange={handleFilters}/>
                                <label className="form-check-label">
                                    Attiva
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox"
                                       name="is_active"
                                       value="false"
                                       checked={filters.some(f => (f.key === "is_active" && f.value === "false") )}
                                       onChange={handleFilters}/>
                                <label className="form-check-label">
                                    Non Attiva
                                </label>
                            </div>
                        </div>
                    </div>
                </Accordion>
            </div>
            <div className="col-12">
                <Accordion title={"Strutture nuvala (selezionate:" + selectedAccommodations.length + ")"} open={true}>
                    <div className="row">
                    {
                        (selectedAccommodations.length > 0) ? selectedAccommodations.map((a: AccommodationAdminModel, idx) => {
                            return (
                                <div className="col-12">
                                    <AdminAccommodation key={idx} accommodation={a} setAccommodation={setAccommodation}  />
                                </div>
                            )
                        }) : <p>I filtri non hanno ritornato nessuna struttura</p>
                    }
                    </div>
                </Accordion>
            </div>
        </div>
    )
}