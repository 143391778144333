import { useParams } from 'react-router';
import {useEffect, useState} from "react";
import {isAuth, logout} from "../../services/Auth.service";
import {getAccommodationBySlug} from "../../services/Accommodation.service";
import {AccommodationModel, getEmptyAccommodationModel, MediaModel} from "../../models/Accommodation";
import {Link, useNavigate} from "react-router-dom";
import {Loading} from "../../components/shared/Loading.component";
import {ImageUpload} from "../../components/shared/ImageUpload.component";
import {HostAddAccommodation} from "./HostAddAccommodation.page";

export function HostAccommodation() {
  let navigate = useNavigate();
  const { slug = "" } = useParams<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [accommodation, setAccommodation] = useState<AccommodationModel>(getEmptyAccommodationModel());
  const [accommodationMedia, setAccommodationMedia] = useState<MediaModel[]>([]);

  useEffect(() => {
    setIsLoading(true);
    setIsFound(false);
    if (!isAuth()) {
        logout();
        navigate("/");
    }
    getAccommodationBySlug(slug).then(
        res => {
            setAccommodation(res.data);
            setAccommodationMedia(res.data.media);
            setIsFound(true);
        }, err => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
  }, [slug]);

  if(isLoading) {
      return <Loading/>;
  } else if (!isFound) {
      return <h5 className="text-nuvala-blue">Non siamo riusciti a trovare la struttura che cercavi</h5>
  }

  let img_inputs = [];
  accommodationMedia?.map(m => {
      img_inputs.push(<ImageUpload key={m.id} media={m} slug={ accommodation.slug }/>);
  });
  for(let i=accommodationMedia.length; i<5; i++) {
      img_inputs.push(<ImageUpload key={"und-" + i} slug={ accommodation.slug }/>);
  }

  return (
      <div className="row">
          <div className="col-md-12">
              <h2 className="text-nuvala-blue">{ accommodation.name }</h2>
              <div className="d-flex justify-content-start">
              { img_inputs }
              </div>
          </div>
          <div className="col-md-12 mt-3">
              <HostAddAccommodation accommodation={accommodation}/>
          </div>
      </div>
  )
}