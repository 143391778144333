import React, {useEffect, useState} from "react";
import {NuvalaMap} from "../components/map/NuvalaMap.component";
import i0 from "../assets/images/backgrounds/bg-1.jpg";
import i1 from "../assets/images/places/places_1.jpg";
import i2 from "../assets/images/places/places_2.jpg";
import i3 from "../assets/images/places/places_3.jpg";
import i4 from "../assets/images/places/places_4.jpg";
import i5 from "../assets/images/places/places_5.jpg";
import i6 from "../assets/images/places/places_6.jpg";
import i7 from "../assets/images/places/places_7.jpg";
import i8 from "../assets/images/places/places_8.jpg";
import i9 from "../assets/images/places/places_9.jpg";

import logo from "../assets/images/logoLanding.svg";
import {Marquee} from "../components/shared/Marquee.component";
import {Carousel} from "react-bootstrap";
import press1 from "../assets/images/press/pdn1.png";
import press2 from "../assets/images/press/pdn2.png";
import press3 from "../assets/images/press/pdn3.png";
import press4 from "../assets/images/press/pdn4.png";
import logoArrow from "../assets/images/logo-home-arrow.png";
import logoArrowViola from "../assets/images/logo-home-arrow-viola.png";

export function Home() {
    const [anchorMap, setAnchorMap] = useState<HTMLElement | null>(null);
    const [anchorHome, setAnchorHome] = useState<HTMLElement | null>(null);

    const [count, setCount] = useState(0)

    useEffect(() => {
        setAnchorMap(document.getElementById("map-container"));
        setAnchorHome(document.getElementById("home-container"));
    }, []);

    const handleScrollDown = (event: any) => {
        event.preventDefault();
        anchorMap?.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    const handleScrollUp = (event: any) => {
        event.preventDefault();
        anchorHome?.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    return (
        <>
            <div className="container-fluid px-0 min-vh-100" id="home-container">
                <div className="nuvala-hero">
                    <Carousel controls={false} interval={4000} indicators={false}>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i0}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i1}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i2}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i3}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i4}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i5}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i6}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i7}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i8}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                        <Carousel.Item><img className="d-block w-100 img-carousel" src={i9}
                                            alt="Nuvala evasioni urbane"/></Carousel.Item>
                    </Carousel>
                    <div className="nuvala-scroll-down" onClick={handleScrollDown}>
                        <div className="row">
                            <div className="col-12 col-lg-6 d-flex align-items-end flex-column">
                                <div><p className="text-yellow" style={{fontSize: "30px"}}>Lavora da una nuvala, vivi la tua evasione urbana!</p></div>
                                <div className="mt-auto"><img className="img-fluid" style={{width: "50%"}} src={logoArrow}/></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-end d-none d-lg-flex flex-column">
                                <div><p className="text-yellow" style={{fontSize: "30px"}}>Scopri i pacchetti Nuvala Workation</p></div>
                                <div className="mt-auto"><img className="img-fluid" style={{width: "50%"}} src={logoArrowViola}/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-0">
                <Marquee
                    text="→→ Nuvala →→ evasioni urbane →→ an hosting platform →→ for workation and experiences →→ "/>
            </div>
            <div id="map-container" className="container-fluid px-0 min-vh-100">
                <NuvalaMap handleScrollUp={handleScrollUp}/>
            </div>
            <div className="container-fluid px-0">
                <div className="nuvala-hero" style={{backgroundColor: "white", height: "auto", marginTop: "0"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-6 col-md-3">
                                <a href="https://www.italiachecambia.org/2021/09/nuvala-vacanze-lavoro/" target="_blank">
                                    <img src={press1} className="img-fluid"/>
                                </a>
                            </div>
                            <div className="col-6 col-md-3">
                                <a href="https://www.marieclaire.it/lifestyle/coolmix/a38062385/workation-lavoro-luogo/" target="_blank">
                                    <img src={press2} className="img-fluid"/>
                                </a>
                            </div>
                            <div className="col-6 col-md-3">
                                <a href="https://www.lonelyplanetitalia.it/lonely-planet-magazine/19-giugno-luglio-2022" target="_blank">
                                    <img src={press3} className="img-fluid"/>
                                </a>
                            </div>
                            <div className="col-6 col-md-3">
                                <a href="#">
                                    <img src={press4} className="img-fluid"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}