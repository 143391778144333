import 'react-app-polyfill/ie11';
import * as React from 'react';
import {useState} from "react";

interface AccordionProps {
    title: string,
    open?: boolean,
    children?: React.ReactNode
}

export function Accordion(props: AccordionProps) {
    const [show, setShow] = useState(!!props.open);

    return (
        <div className="accordion accordion-flush mb-3">
            <div className="accordion-item">
                <p className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" onClick={() => setShow(!show)}>
                        <b>{ props.title }</b>
                    </button>
                </p>
                <div className={"accordion-collapse p-3 collapse" + (show ? " show" : "")}>
                    { props.children }
                </div>
            </div>
        </div>
    );
}