export default function authHeader(json?: boolean) {
  const token = localStorage.getItem('token');

  if (token) {
    if(json) return {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    };
    else return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}