import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {BuddyModel, ReservationModel} from "../../models/Reservation";
import {isAuth, logout} from "../../services/Auth.service";
import {Loading} from "../../components/shared/Loading.component";
import {Accordion} from "../../components/shared/Accordion.component";
import {ReservationChat} from "../../components/shared/ReservationChat.component";
import {dateStringToString} from "../../models/Planner";
import {BuddyCard} from "../../components/guest/BuddyCard.component";
import {AddBuddyModal} from "../../components/guest/AddBuddyModal.component";
import {GuestModel, GuestModelSchema} from "../../models/User";
import {Toast} from "../../components/shared/Toast.component";
import {getHostReservationBySlug} from "../../services/Host.service";


export function HostReservation() {
    let navigate = useNavigate();
    const {slug = ""} = useParams<string>();
    const [loading, setLoading] = useState(true);
    const [isFound, setIsFound] = useState(false);
    const [reservation, setReservation] = useState<ReservationModel>({} as ReservationModel)
    const [buddies, setBuddies] = useState<BuddyModel[]>([])
    const [guest, setGuest] = useState<GuestModel>({} as GuestModel);
    const [formError, setFormError] = useState<string[]>([]);

    useEffect(() => {
        setLoading(true);
        setIsFound(false);
        if (!isAuth()) {
            logout();
            navigate("/");
        }
        getHostReservationBySlug(slug).then(res => {
            setReservation(res.data);
            setBuddies(res.data.buddies);
            setGuest(res.data.guest)
        }).catch(err => {
            setFormError(["Qualcosa e' andato storto, riprova."]);
        }).finally(() => {
            setIsFound(true);
            setLoading(false);
        });
    }, [slug]);

    if(loading) {
        return <Loading/>;
    } else if (!isFound) {
        return <h5>Non siamo riusciti a trovare la prenotazione che cercavi</h5>
    }

    return (
        <div className="container">
            { formError.length > 0 ? <Toast text={formError} /> : "" }
            <div className="row">
                <div className="col-12" style={{marginTop: "75px"}}>
                    <Link to="/host/reservations"  className="btn btn-outline-nuvala mb-4" style={{textDecoration: "none"}}>&lt;--- Torna alla home</Link>
                </div>
                <div className="col-12 col-lg-7 text-nuvala-blue">
                    <Accordion title={"Soggiorno presso " + reservation.accommodation.name} open={true}>
                        <p>Reservation code: <span className="badge bg-secondary">{reservation.slug}</span></p>
                        <p>Check In: {dateStringToString(reservation.checkin_date)}</p>
                        <p>Check Out: {dateStringToString(reservation.checkout_date)}</p>
                        <p>Numero persone: {reservation.pax}</p>
                        <p>Prezzo: {reservation.total_price}€</p>
                        <div className="d-flex">
                            <BuddyCard locked={true} key={"buddy-" + (1)} index={1}
                                       inputBuddy={{name: guest.guest_name, job: guest.job_title, age: guest.age}}
                                       setBuddies={setBuddies} buddies={buddies}
                            />
                            {buddies.map((b, i) =>
                                <BuddyCard locked={true} key={"buddy-" + (i+2)} index={i+2} inputBuddy={b} setBuddies={setBuddies} buddies={buddies}/>
                            )}
                        </div>
                        {(buddies.length+1 < reservation.pax) && <div className="mt-3"><AddBuddyModal locked={true} slug={reservation.slug} setBuddies={setBuddies} buddies={buddies}/></div>}
                    </Accordion>
                </div>
                <div className="col-12 col-lg-5">
                    <ReservationChat slug={reservation.slug} />
                </div>
            </div>
        </div>
    )
}