import React, {useEffect, useState} from "react";
import {AccommodationPublicModel, BedModel, FacilityModel, MediaModel} from "../../models/Accommodation";
import {SharedAccoProps} from "./NuvolaMapPopup.component";
import {ImageLightBox} from "../shared/ImageLightBox.component";
import {Carousel} from "../shared/Carousel.component";

export function NuvalaMapPopupLanding(props: SharedAccoProps) {
    const [accommodation, setAccommodation] = useState<AccommodationPublicModel>();
    const [categories, setCategories] = useState<{category: string, attributes: string[]}[]>([])
    const [distance, setDistance] = useState<number>();

    useEffect(() => {
        setAccommodation(props.accommodation);
        setDistance(props.distance);
        let category_buckets:{category: string, attributes: string[]}[] = []
        props.accommodation.facilities?.forEach((f: FacilityModel) => {
            const b_idx = category_buckets.findIndex(b => b.category === f.category);
            if(b_idx >= 0) {
                category_buckets[0].attributes.push(f.name);
            } else {
                category_buckets.push({category: f.category, attributes: [f.name]});
            }
        });
        setCategories(category_buckets);
    }, [props])

    if (distance && accommodation) {
        return (
            <>
                <div className="row mx-0">
                    <div className="col-12 col-md-6 b-nuvala-right p-3">
                        <Carousel images={accommodation.media.map((i: MediaModel) => i.url)} />
                    </div>
                    <div className="col-12 col-md-6 px-0">
                        <div className="row mx-0">
                            <div className="col-12 b-nuvala-bottom p-3">
                                <p className="text-nuvala-blue preserve-newlines">
                                    {
                                        accommodation?.description
                                    }
                                </p>
                                <p className="text-nuvala-blue mb-1"><b>distanza {distance} km</b></p>
                            </div>
                            {
                                categories.length > 0 && (
                                    <div className="col-12 b-nuvala-bottom p-3">
                                        <h5>servizi</h5>
                                        {
                                            categories?.map(c => {
                                                return (
                                                    <p className="text-nuvala-blue" key={c.category}>
                                                        <b>{c.category}: </b>
                                                        {
                                                            c.attributes.map((a, idx) => (idx === c.attributes.length - 1) ? a : a + ', ')
                                                        }
                                                    </p>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            <div className="col-12 p-3">
                                <h5>letti e alloggi</h5>
                                {
                                    accommodation.beds.map((b: any) => {
                                        return (
                                            <p><b>{b.bed.name}:</b> {b.value}</p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <a className="btn-nuvala-bottom-right" onClick={() => props.setStep('pick_date')}>
                    prenota
                </a>
            </>
        );
    } else {
        return <></>;
    }
}