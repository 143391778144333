import React, {ChangeEvent, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {isAuth, logout} from "../../services/Auth.service";
import {GuestJobs, GuestModel, GuestModelSchema} from "../../models/User";
import {Loading} from "../../components/shared/Loading.component";
import {getBasicGuestData, getGuestReservationsData, setBasicGuestData} from "../../services/Guest.service";
import {Accordion} from "../../components/shared/Accordion.component";
import {NationsLocales} from "../../services/Locale.service";
import {Toast} from "../../components/shared/Toast.component";
import {ReservationModel} from "../../models/Reservation";
import {dateStringToString} from "../../models/Planner";
import {stateToBadge} from "../../services/Reservation.service";

export function GuestDashboard(){
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [guest, setGuest] = useState<GuestModel>({} as GuestModel)
    const [reservations, setReservations] = useState<ReservationModel[]>([])

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        } else {
             Promise.all([getBasicGuestData(), getGuestReservationsData()]).then(
                res => {
                    setGuest(res[0].data.guest);
                    setReservations(res[1].data.reservations)
                }).catch(err => {
                    logout();
                    navigate("/login");
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [navigate]);

    function handleChange(event: any) {
        let value = event.target.value;
        const name = event.target.name;
        setGuest({ ...guest, [name]: value })
    }

    if (loading) return <Loading/>;

    return (
        <div className="container" style={{marginTop: "100px"}}>
            <div className="row">
                <div className="col-12 col-md-8 offset-md-2 text-center text-nuvala-blue">
                    <h3>Benvenuto { guest?.guest_name }</h3>
                </div>
                <div className="col-12 col-md-8 offset-md-2">
                    { reservations.length === 0 && <h3>Non ci sono ancora prenotazioni!</h3>}
                </div>
                <div className="col-12 col-md-8 offset-md-2">
                    <div className="row">
                        {
                            reservations.map((r: ReservationModel) => {
                                return (<ReservationCard key={r.slug} {...r} />);
                            })
                        }
                    </div>
                </div>
                <div className="col-12 col-md-8 offset-md-2 mt-4">
                    <GuestSettings guest={guest} handleChange={handleChange} />
                </div>
            </div>
        </div>
    )
}

interface GuestSettingsProps {
    guest: GuestModel,
    handleChange: (event: ChangeEvent<any>) => void
}
export function GuestSettings(props: GuestSettingsProps) {
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string[]>([]);

    useEffect(() => {

    }, [props])

    async function handleSubmit(event: any) {
        setLoading(true);
        try {
            await GuestModelSchema.validate(props.guest, {abortEarly: false}).then((validation: any) => {
                setBasicGuestData(props.guest).then(
                    res => {
                        setFormError(["Profilo aggiornato correttamente!"]);
                    }).catch(err => {
                        setFormError(["Qualcosa e' andato storto, riprova."]);
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });
            });
        } catch (err: any) {
            setFormError(err.errors);
            setLoading(false);
        }
    }

    return (
        <Accordion title="Modifica informazioni di base">
            { formError.length > 0 ? <Toast text={formError} /> : "" }
            <div className="row mb-3">
                <div className="col-lg-6 col-12 mt-2">
                    <label><b>Nome</b></label>
                    <input name="guest_name" value={props.guest.guest_name}
                        type="text" className="form-control" onChange={props.handleChange} />
                </div>
                <div className="col-lg-6 col-12 mt-2">
                    <label><b>Cognome</b></label>
                    <input name="guest_surname" value={props.guest.guest_surname}
                        type="text" className="form-control" onChange={props.handleChange} />
                </div>
                <div className="col-lg-4 col-12 mt-2">
                    <label><b>Lavoro *</b></label>
                    <input name="job_title" value={props.guest.job_title}
                           type="text" className="form-control" onChange={props.handleChange} />
                </div>
                <div className="col-lg-2 col-12 mt-2">
                    <label><b>Eta'</b></label>
                    <input name="age" value={props.guest.age}
                           type="number" className="form-control" onChange={props.handleChange} />
                </div>
                <div className="col-lg-2 col-4 mt-2">
                    <label htmlFor="inputState"><b>Prefisso</b></label>
                    <select name="country_code" value={props.guest.country_code}  className="form-control" onChange={props.handleChange} >
                        <option value="" disabled>Seleziona prefisso nazionale</option>
                        { NationsLocales.map(l => (<option key={l.code} value={l.code}>{l.code} {l.name}</option>))}
                    </select>
                </div>
                <div className="col-md-4 col-8 mt-2">
                    <label htmlFor="inputCity"><b>Telefono *</b></label>
                    <input name="phone_number" value={props.guest.phone_number}
                        type="text" className="form-control" onChange={props.handleChange} />
                </div>
                <div className="col-12 mt-2">
                    <label className="form-label"><b>Bio</b></label>
                    <textarea rows={3} name="bio" maxLength={1000}
                              className="form-control" placeholder="La tua bio"
                              value={props.guest?.bio} onChange={props.handleChange}/>
                    <small className="text-muted float-end">{props.guest?.bio.length} caratteri (max 1000)</small>
                </div>
                <div className="col-12 mt-3 text-end">
                    <button type="button" className="btn btn-nuvala" onClick={handleSubmit}>
                        Salva Modifiche
                        { loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> }
                    </button>
                </div>
            </div>
        </Accordion>
    )

}

export function ReservationCard(reservation: ReservationModel) {
    return (
        <div className="col-4 p-3">
            <div className="nuvala-modal text-nuvala-blue">
                <img className="img-fluid" alt="cover struttura ricettiva" src={reservation.accommodation.media[0].url}/>
                <p className="mb-1"><span className="badge bg-secondary">{reservation.slug}</span></p>
                <p className="mb-1"><b>check-in</b>: {dateStringToString(reservation.checkin_date)}</p>
                <p className="mb-1"><b>check-out</b>: {dateStringToString(reservation.checkout_date)}</p>
                <p className="mb-1"><b>numero persone</b>: {reservation.pax}</p>
                <p className="mb-1"><b>prezzo</b>: {reservation.total_price}€</p>
                <p className="text-center my-3">{stateToBadge(reservation.statuses[0])}</p>
                <Link className="btn-nuvala-bottom-right" to={ "/guest/reservation/" + reservation.slug }>Apri</Link>
            </div>
        </div>
    )
}