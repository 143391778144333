import React, {useContext, useEffect, useState} from 'react';
import {login} from "../services/Auth.service";
import {UserContext} from "../shared/UserContext";
import {Link, useNavigate} from "react-router-dom";
import {getEmptyUserLogin, UserLogin, UserLoginSchema} from "../models/User";
import {Toast} from "../components/shared/Toast.component";
import bg from "../assets/images/backgrounds/bg-7.jpg";
import logo from '../assets/images/logoSpinner.png';
import {spinnner} from "../components/shared/Spinner.component";

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState<UserLogin>(getEmptyUserLogin);
    const [formError, setFormError] = useState<string[]>([]);

    let navigate = useNavigate();
    const {user, signIn} = useContext(UserContext);

    useEffect(() => {
        if (user !== null) {
            if(user.role === "provider") navigate("/host");
            if(user.role === "admin") navigate("/admin");
            else navigate("/");
        }
    }, [user]);

    function handleChange(event: any) {
        setFormValues({ ...formValues, [event.target.name]: event.target.value })
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        setLoading(true);
        try {
            await UserLoginSchema.validate(formValues, {abortEarly: false}).then(validation => {
                login(formValues.email, formValues.password).then(res => {
                    if(res !== undefined && res !== null) {
                        signIn(res);
                    } else {
                        setFormError(["Qualcosa e' andato storto! riprova"]);
                    }
                }).catch(err => {
                    setFormError(["Qualcosa e' andato storto:", err.response.data.message]);
                }).finally(() => {
                    setLoading(false);
                });
            });
        } catch (err: any) {
            setFormError(err.errors);
            setLoading(false);
        }
    }

    return (
        <div className="container-fluid px-0">
            <div className="nuvala-hero vh-100" style={{backgroundImage: "url("+ bg +")", backgroundSize: "cover", paddingTop: "30px"}}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-4 offset-lg-4 my-md-auto mt-lg-0">
                            <div className="nuvala-modal" style={{marginTop: "50%"}}>
                                { formError.length > 0 ? <Toast text={formError} /> : "" }
                                <form >
                                    <div className="text-center d-none d-md-block">
                                        <img src={logo} className="img-fluid my-3" style={{maxWidth: "200px"}}/>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label htmlFor="username">Email</label>
                                        <input name="email" type="text" className="form-control"
                                               value={formValues.email} onChange={handleChange}/>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label htmlFor="password">Password</label>
                                        <input name="password" type="password" className="form-control"
                                               autoComplete="on"
                                               value={formValues.password} onChange={handleChange}/>
                                    </div>
                                    <p className="text-nuvala-blue my-0 mt-3"><small><i>hai dimenticato la password?</i> </small></p>
                                    <p className="text-nuvala-blue my-0"><small>
                                        <Link style={{textDecoration: "none"}} className="text-nuvala-blue" to="/recover-email/start" >clicca qua per recuperarla</Link>
                                    </small></p>
                                    <div className="form-group mt-5">
                                        <a onClick={handleSubmit} className="btn-nuvala-bottom-right">
                                            { loading && spinnner}
                                            Login
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}