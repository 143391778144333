import axios from "axios";
import authHeader from "./auth-header";
import {HostModel} from "../models/User";
const API_URL = process.env.REACT_APP_API_URL + "/api/host";

export const getBasicData = async () => {
  // @ts-ignore
  return await axios.get(API_URL + "", { headers: authHeader() });
};


export const setBasicHostData = async (data: HostModel) => {
  const bodyFormData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    bodyFormData.append(key, value);
  }
  // @ts-ignore
  return await axios.post(API_URL + "", bodyFormData, { headers: authHeader() });
}

export const getHostReservationBySlug = async (slug: string) => {
  // @ts-ignore
  return await axios.get(API_URL + "/reservation/" + slug, { headers: authHeader() });
};

export const onboardStripeHost = async () => {
  // @ts-ignore
  return await axios.post(API_URL + "/stripe/onboard-host", {}, { headers: authHeader() });
}

export const getOnboardStripeStatus = async () => {
  // @ts-ignore
  return await axios.get(API_URL + "/stripe/onboard-host", { headers: authHeader() });
};

export const unlinkStripeHost = async () => {
  // @ts-ignore
  return await axios.delete(API_URL + "/stripe/onboard-host", { headers: authHeader() });
};