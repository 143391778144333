import axios from "axios";
import authHeader from "./auth-header";
import jwt_decode from "jwt-decode";
import {GuestModel, HostModel, UserRecoverPassword, UserRegister} from "../models/User";
import {getBasicGuestData} from "./Guest.service";
import {getBasicData} from "./Host.service";
const API_URL = process.env.REACT_APP_API_URL + "/api/auth/";

export enum ROLE {
    Admin = "admin",
    Provider = "provider",
    Customer = "customer",
    Guest = "guest",
}

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // console.log("intercepted: " + response.status);
    // console.log(response.data);
    // here implement the refresh token check
    return response;
  }, function (error) {
    return Promise.reject(error);
});

export const isTokenExpired = () => {
    const token = localStorage.getItem("token");
    if(token !== null) {
        const decoded : any = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) {
            return true;
        }
    }
    return false;
}

export const register = async (base: UserRegister, additional: HostModel | GuestModel) => {
  const jsonData = JSON.stringify({...base, ...additional});
  // @ts-ignore
  return await axios.post(API_URL + "register", jsonData, { headers: {'Content-Type': 'application/json'}});
}

export function login(email: string, password: string) {
  logout();
  let bodyFormData = new FormData();
  bodyFormData.append('email', email);
  bodyFormData.append('password', password);
  return axios.post(API_URL + "login", bodyFormData)
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("token", response.data.access_token);
        return getCurrentUser();
      }
    });
}

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

export const fetchCurrentUser = () => {
  // @ts-ignore
  return axios.get(API_URL + 'user', { headers: authHeader() });
}

export function getCurrentUser() {
  const userStr = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  // CASE 1: we have a user saved locally, we return it
  if (userStr) {
      return JSON.parse(userStr);
  }
  // CASE 2: we don't have a user, but we have a token, we fetch the user
  else if (token) {
      return fetchCurrentUser().then(
          response => {
            localStorage.setItem("user", JSON.stringify(response.data));
            return response.data;
          }, error => {
            console.log(error);
            logout();
          }
      );
  } else {
      return null;
  }
}

export const isAuth = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return true;
    else return false;
};

export function getRole() : ROLE {
    const userStr = localStorage.getItem("user");
    if (!userStr) return ROLE.Guest;
    else {
        let user = JSON.parse(userStr)
        if(user.role === "admin") return ROLE.Admin;
        if(user.role === "provider") return ROLE.Provider;
        if(user.role === "customer") return ROLE.Customer;
        return ROLE.Guest;
    }
}

export function VerifyAndLogin(email: string, password: string, token: string) {
  logout();
  let bodyFormData = new FormData();
  bodyFormData.append('email', email);
  bodyFormData.append('password', password);
  bodyFormData.append('token', token);
  return axios.post(API_URL + "verify", bodyFormData)
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("token", response.data.access_token);
        return getCurrentUser();
      }
    });
}

export function StartPasswordRecovery(email: string) {
    let bodyFormData = new FormData();
    bodyFormData.append('email', email);
    return axios.post(API_URL + "recovery/start", bodyFormData);
}

export function SetPasswordRecovery(data: UserRecoverPassword, token: string) {
    let bodyFormData = new FormData();
    bodyFormData.append('password', data.password);
    bodyFormData.append('passwordConfirmation', data.passwordConfirmation);
    bodyFormData.append('token', token);
    return axios.post(API_URL + "recovery/set", bodyFormData);
}