import 'react-app-polyfill/ie11';
import * as React from 'react';
import logo from '../../assets/images/logoSpinner.png';

// @ts-ignore
export function Loading() {
    return (
        <div style={{
            position: "absolute",
            right:"50%",
            top:"50%",
            width:"100px",
        }}>
            <img className="App-logo" src={logo} width="100%" />
        </div>
    );
}