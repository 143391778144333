import axios from "axios";
import authHeader from "./auth-header";
import {GuestModel} from "../models/User";
const API_URL = process.env.REACT_APP_API_URL + "/api/guest";

export const getBasicGuestData = async () => {
  // @ts-ignore
  return await axios.get(API_URL + "", { headers: authHeader() });
};

export const setBasicGuestData = async (data: GuestModel) => {
  const bodyFormData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    bodyFormData.append(key, value);
  }
  // @ts-ignore
  return await axios.patch(API_URL + "", bodyFormData, { headers: authHeader() });
}

export const getGuestReservationsData = async () => {
  // @ts-ignore
  return await axios.get(API_URL + "/reservations", { headers: authHeader() });
};

export const getGuestReservationBySlug = async (slug: string) => {
  // @ts-ignore
  return await axios.get(API_URL + "/reservation/" + slug, { headers: authHeader() });
};