import {useEffect, useState} from "react";
import {BuddyModel, BuddyModelSchema, getEmptyBuddy} from "../../models/Reservation";
import * as React from "react";
import {addBuddy} from "../../services/Reservation.service";

const spinnner = (<span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />);

interface AddBuddyModalProps {
    slug: string,
    buddies: BuddyModel[],
    setBuddies: (buddies: BuddyModel[]) => void,
    locked?: boolean,
}

export function AddBuddyModal(props: AddBuddyModalProps) {
    const [buddy, setBuddy] = useState<BuddyModel>(getEmptyBuddy())
    const [modalOpen, setModalOpen] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [formError, setFormError] = useState<string[]>([]);

    function handleChange(e: any) {
        setBuddy({...buddy, [e.target.name]: e.target.value})
    }

    async function handleSubmit() {
        setBtnLoader(true);
        try {
            await BuddyModelSchema.validate(buddy, {abortEarly: false}).then(validation => {
                addBuddy(props.slug, buddy).then(res => {
                    setFormError([]);
                    props.setBuddies([...props.buddies, res.data]);
                }).catch(err => {

                }).finally(() => {
                    setBuddy(getEmptyBuddy());
                    setBtnLoader(false);
                    setModalOpen(false);
                });
            })
        } catch (err: any) {
            setFormError(err.errors);
            setBtnLoader(false);
        }
    }

    return (
        <>
        <div className={"modal mt-5 fade" + (modalOpen ? " show d-block" : "")}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Aggiungi Travel Buddy</h5>
                        <button className="btn-close" onClick={() => setModalOpen(false)} aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="col-12 mt-2">
                            {
                                formError.map((e, i) => {
                                    return <p key={"err-" + i} className="mb-1">- {e}</p>;
                                })
                            }
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 mt-2">
                                <label className="form-label"><b>Nome *</b></label>
                                <input type="text" name="name" className="form-control" placeholder="Nome"
                                       value={buddy.name} onChange={handleChange}/>
                            </div>
                            <div className="col-12 mt-2">
                                <label className="form-label"><b>Eta *</b></label>
                                <input type="number" name="age" className="form-control" placeholder="Eta'"
                                       value={buddy.age} onChange={handleChange}/>
                            </div>
                            <div className="col-12 mt-2">
                                <label className="form-label"><b>Lavoro *</b></label>
                                <input type="text" name="job" className="form-control" placeholder="Lavoro"
                                       value={buddy.job} onChange={handleChange}/>
                            </div>
                        </div>
                        {(!props.locked) &&
                            <button className="btn btn-outline-nuvala px-4" type="button" onClick={handleSubmit}>{ btnLoader && spinnner}<b>Aggiungi Buddy</b></button>
                        }
                    </div>
                </div>
            </div>
        </div>
        {(!props.locked) &&
            <a onClick={() => setModalOpen(true)} className="btn btn-outline-nuvala">Aggiungi Travel Buddy</a>
        }
        </>
    )
}