import * as Yup from "yup";
import {FormikValues} from "formik";
import {HostModel} from "./User";

export interface AccommodationModel {
    "slug": string,
    "name": string,
    "typology": string,
    "address": string,
    "region": string,
    "description": string,
    "get_there": string,
    "welcome_message": string,
    "min_pax": number,
    "max_pax": number,
    "min_stay": number,
    "max_stay": number,
    "is_ppa": boolean,
    "additional_ppp": number,
    "latitude": number,
    "longitude": number,
    "is_active": boolean,
    "is_validated": boolean,
    "facilities": FacilityModel[],
    "beds": {name: string, value: number}[],
    "media": MediaModel[],
}

export interface HostPublicModel {
    owner_name: string,
    owner_surname: string,
    vat_number: string,
    name: string,
    address: string,
    country_code: string,
    phone_number: string,
}

export interface AccommodationPublicModel {
    "slug": string,
    "name": string,
    "typology": string,
    "address": string,
    "region": string,
    "description": string,
    "min_pax": number,
    "max_pax": number,
    "min_stay": number,
    "max_stay": number,
    "latitude": number,
    "longitude": number,
    "facilities": FacilityModel[],
    "beds": {name: string, value: number}[],
    "media": MediaModel[],
    "host": HostPublicModel,
}

export interface FacilityModel {
    "id": string,
    "name": string,
    "category": string,
    "group": string,
}

export interface BedModel {
    "id": string,
    "name": string,
    "description": string,
}

export interface ReferentialsList {
    "facilities": FacilityModel[],
    "beds": BedModel[]
}
export interface MediaModel {
    "id": string,
    "url": string,
    "type": string,
}

export const getEmptyMediaModel = () : MediaModel => ({
    "id": "",
    "url": "",
    "type": "",
});

export const getEmptyAccommodationModel = () : AccommodationModel => ({
    "slug": '',
    "name": '',
    "typology": '',
    "address": '',
    "region": '',
    "description": '',
    "get_there": '',
    "welcome_message": '',
    "min_pax": 1,
    "max_pax": 2,
    "min_stay": 1,
    "max_stay": 29,
    "is_ppa": true,
    "additional_ppp": 0.0,
    "latitude": 0.0,
    "longitude": 0.0,
    "is_active": false,
    "is_validated": false,
    "facilities": [],
    "beds": [],
    "media": [],
});

export const AccommodationModelSchema =
    Yup.object({
        facilities: Yup.array(),
        beds: Yup.array().min(1, 'Selezionare almeno una sistemazione'),
        name: Yup.string()
            .min(2, 'Nome struttura troppo corto')
            .max(50, 'Nome struttura troppo lungo, max 50!')
            .required('Inserire il nome della struttura'),
        address: Yup.string().required('Inserire un indirizzo valido'),
        description: Yup.string()
            .min(100, 'La descrizione deve essere più lunga, min 100 caratteri!')
            .max(2000, 'La descrizione deve essere più breve, max 2000 caratteri!')
            .required('Inserire la descrizione della struttura'),
        get_there: Yup.string()
            .max(1000, 'La descrizione "come raggiungerci" deve essere più breve, max 1000 caratteri!'),
        welcome_message: Yup.string()
            .max(1000, 'La descrizione "messaggio di benvenuto" deve essere più breve, max 2000 caratteri!'),
        region: Yup.string().required('Selezionare la regione'),
        min_pax: Yup.number().required('Inserire in numero minimo di guests')
            .positive('Il numero di guests deve essere maggiore di zero')
            .integer('Inserire un numero di guests valido'),
        max_pax: Yup.number().required('Inserire in numero massimo di guests')
            .positive('Il numero di guests deve essere maggiore di zero')
            .integer('Inserire un numero di guests valido'),
        min_stay: Yup.number().required('Inserire in numero minimo di giorni')
            .positive('Il numero di giorni deve essere maggiore di zero')
            .integer('Inserire un numero di giorni valido'),
        max_stay: Yup.number().required('Inserire in numero massimo di giorni')
            .positive('Il numero di giorni deve essere maggiore di zero')
            .integer('Inserire un numero di giorni valido'),
        additional_ppp: Yup.number().min(0, 'Il prezzo addizionale per persona deve essere un numero positivo')
        .nullable(true)
        // checking self-equality works for NaN, transforming it to null
        .transform((_, val) => val === Number(val) ? val : null),
        typology: Yup.string().required('Selezionare la tipologia di struttura'),
    });

export const AccommodationTypologies = [
    {"name": "Glamping", },
    {"name": "Masseria", },
    {"name": "Casale", },
    {"name": "Trullo", },
    {"name": "Lamia", },
    {"name": "Casa in campagna", },
    {"name": "Casa al mare", },
    {"name": "Casa in montagna", },
    {"name": "Casa in collina", },
    {"name": "Casa al lago", },
    {"name": "Cascina", },
    {"name": "Casa nel borgo", },
];

export function getFacilityByCategory(facilitiesList: FacilityModel[]) {
    const temp_filters: {name: string, facilities: FacilityModel[]}[] = [];
    facilitiesList.map((f: FacilityModel) => {
        let bucket = temp_filters.find(bucket => bucket.name === f.category);
        if(bucket) {
            bucket.facilities.push(f);
        } else {
            bucket = {name: f.category, facilities: []};
            bucket.facilities.push(f);
            temp_filters.push(bucket);
        }
        return;
    });
    return temp_filters;
}