import 'react-app-polyfill/ie11';
import * as React from 'react';
import {useEffect, useState} from "react";
import {isAuth, logout} from "../../services/Auth.service";
import {Link, useNavigate} from "react-router-dom";
import {Loading} from "../../components/shared/Loading.component";
import {addDatePlanner, getPlanner, quickAddPricesPlanner} from "../../services/Planner.service";
import {
    CalendarAccommodation,
    CalendarAccommodationPrice,
    CalendarHeader, getPlannerQuickModalFormValues,
    Planner,
    PlannerQuickModalFormValues, QuickModalFormMultiSchema, QuickModalFormSchema, QuickModalFormSingleSchema
} from "../../models/Planner";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';

// @ts-ignore
export function HostPricePlanner() {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [planner, setPlanner] = useState<Planner>(({} as any) as Planner);
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        }
        getPlanner(null).then(res => {
            setPlanner(res.data);
            setCurrentMonth(new Date(res.data.selected_month));
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    function handleMonthChange(month: string) {
        setIsLoading(true);
        getPlanner(month).then(res => {
            setPlanner(res.data);
            setCurrentMonth(new Date(res.data.selected_month));
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    if (isLoading) return <Loading/>
    if (!planner) return <h1>Planner not available</h1>

    return (
        <>
            <div className="row">
                <div className="col-4 text-start pt-3 mb-4">
                    <button onClick={() => handleMonthChange(planner?.prev_month)} className="btn btn-sm btn-outline-primary">
                        { planner.prev_month_slug }
                    </button>
                </div>
                <div className="col-4 text-center mb-4">
                    <h2 className="mb-0">{ planner.month }</h2>
                    <small style={{fontSize: "12px"}}>{ currentMonth.toDateString() }</small>
                </div>
                <div className="col-4 text-end pt-3 mb-4">
                    <button onClick={() => handleMonthChange(planner?.next_month)} className="btn btn-sm btn-outline-primary">
                        { planner.next_month_slug }
                    </button>
                </div>

                <div className="table-responsive p-0">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" style={{backgroundColor: "white"}}/>
                                {
                                    planner.calendar.map((day : CalendarHeader) => {
                                        const date = new Date(day.date);
                                        const is_offmonth = (date.getMonth() !== currentMonth.getMonth());
                                        const is_today = (date.toDateString() === new Date().toDateString());
                                        return (
                                            <th scope="col" key={"kd-" + day.date} style={{minWidth: "50px"}}className={
                                                "text-center" +
                                                ( day.is_weekend ? " weekend_cell" : "") +
                                                ( is_offmonth ? " offmonth_cell" : "") +
                                                ( is_today ? " today_cell" : "")
                                            }>
                                                { date.getDate() }<br/><small style={{fontWeight: "200"}}>{ day.weekday }</small>
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                        {
                            planner.accommodations.map((accommodation: CalendarAccommodation) => {
                                return (
                                        <tr key={"kd-" + accommodation.slug}>
                                            <td className="text-nowrap px-2" key={"klabel-" + accommodation.slug}>
                                                <small style={{fontWeight: "600", color: "white"}}>{ accommodation.name.substring(0, 20) }</small>
                                            </td>
                                            {
                                                accommodation.prices.map((price:CalendarAccommodationPrice) => {
                                                    const current_date = new Date(price.date);
                                                    const is_today = (current_date.toDateString() === new Date().toDateString());
                                                    return (
                                                        <td className={is_today ? "today_cell" : ""} key={"kd-" + price.date + accommodation.slug}>
                                                            <PlannerCell
                                                                slug={ accommodation.slug }
                                                                day={ new Date(price.date) }
                                                                price={ (price.price !== 0) ? price.price : null }
                                                                reservation={ price.reservation }
                                                                key={accommodation.slug + price.date}
                                                            />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className="col-6 ps-0">
                    <PlannerQuickModal accommodations={planner?.accommodations.map(a => ({"slug": a.slug, "name": a.name}))} />
                </div>
            </div>
        </>
    );
}

interface PlannerCellProps {
    first?: boolean,
    day: Date,
    slug: string,
    price: number | null,
    reservation: string | null,
}

export function PlannerCell(props: PlannerCellProps) {
    const [ day, setDay ] = useState<Date>(props.day);
    const [ slug ] = useState<string>(props.slug);
    const [ price, setPrice ] = useState<number | null>(props.price);
    const [ isLoading, setLoading ] = useState(false);
    const isDisabled = (new Date() >= props.day) || (props.reservation !== null)

    function handleChange(e: any) {
        const eventPrice = parseFloat(e.target.value)
        if(eventPrice !== price && !isNaN(eventPrice)) {
            setLoading(true);
            const newPrice = eventPrice === 0 ? null : eventPrice
            setPrice(newPrice);
            addDatePlanner(slug,day,day,newPrice)
                .then(res => {
                    console.log(res);
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
            });
        }
    }

    return (
        <div className={"text-center calendar_cell border-bottom-0" + (props.reservation !== null ? " calendar_cell_reserved" : "")}>
            {
                isLoading ?
                <div className="spinner-grow text-warning" role="status">
                    <span className="sr-only"></span>
                </div>
                :
                (props.reservation !== null) ?
                <a className="text-nuvala-blue text-decoration-none" href={"/host/reservation/" + props.reservation}><small>{ price !== null ? price : "-"}</small></a>
                :
                <input
                    type="text"
                    defaultValue={ price !== null ? price : "-"}
                    onBlur={handleChange}
                    disabled={isDisabled}
                />
            }
        </div>
    )
}


interface PlannerQuickModalProps {
    accommodations: {"slug": string, "name": string}[],
}
export function PlannerQuickModal(props: PlannerQuickModalProps) {
    const [formValues, setFormValues] = useState<PlannerQuickModalFormValues>(getPlannerQuickModalFormValues());
    const [submitEnable, setSubmitEnable] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<{type: string, value: string}>({type: "", value: ""});

    registerLocale('it', it);

    useEffect(() => { validateForm(); }, [formValues]);

    const handleChangeDates = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setFormValues({...formValues, startDate: start, endDate: end});
    };

    const handleChange = (e: any) => {
        setFormValues({...formValues, [e.target.name]: e.target.value});
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true);
        quickAddPricesPlanner(formValues)?.then(res => {
            console.log(res);
            setMessage({type: "success", value: "Prezzi aggiornati!"});
        }).catch(err => {
            setMessage({type: "error", value: "Si e' verificato un problema"});
        }).finally(() => {
            setLoading(false);
        });
    }

    async function validateForm() {
        try {
            if (!formValues.enabled) {
                await QuickModalFormSchema.validate(formValues, {abortEarly: false}).then(v => {
                    setSubmitEnable(true);
                });
            }
            else if (formValues.singlePrice) {
                await QuickModalFormSingleSchema.validate(formValues, {abortEarly: false}).then(v => {
                    setSubmitEnable(true);
                });
            } else {
                await QuickModalFormMultiSchema.validate(formValues, {abortEarly: false}).then(v => {
                    setSubmitEnable(true);
                });
            }
        } catch (err: any) {
            setSubmitEnable(false);
        }
    }

    const closeAndRefresh = () => {
        setVisible(false);
        window.location.reload();
    }

    return (
        <>
        <button className="btn btn-nuvala" onClick={() => setVisible(true)}>Configurazione Rapida</button>
        <div className={"modal fade" + (visible ? " show d-block" : "")}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Configurazione Rapida</h5>
                        <button className="btn-close" onClick={() => closeAndRefresh()} aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <form className="row" onSubmit={handleSubmit}>
                            <div className="col-6 mb-3">
                                <label className="form-label"><b>Sistemazione</b></label>
                                <select className="form-select" value={formValues.slug}
                                        name="slug" onChange={handleChange}>
                                    <option value="" disabled>Seleziona una struttura</option>
                                    {
                                        props.accommodations.map(a => {
                                            return (<option key={a.slug} value={a.slug}>{a.slug}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-6 mb-3">
                                <label className="form-label"><b>Date</b></label>
                                <DatePicker
                                    locale="it"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Seleziona le date"
                                    startDate={formValues.startDate}
                                    endDate={formValues.endDate}
                                    minDate={new Date()}
                                    onChange={handleChangeDates}
                                    selectsRange
                                    className="form-control"
                                    // excludeDateIntervals={[{start: subDays(new Date(), 5), end: addDays(new Date(), 5) }]}
                                />
                            </div>
                            <div className="col-6 mb-3">
                                <div className="custom-control custom-switch">
                                    <input className="custom-control-input" type="checkbox"
                                       id="toggle-acc"
                                       onChange={(e => setFormValues({...formValues, enabled: e.target.checked}))}
                                       defaultChecked={formValues.enabled}
                                    />
                                    <label className="custom-control-label" htmlFor="toggle-acc">Disponibilità: {formValues.enabled ? " Aperte" : " Chiuse"}</label>
                                </div>
                            </div>
                            <div className="col-6 mb-3">
                            {
                                formValues.enabled && (
                                    <div className="custom-control custom-switch">
                                        <input className="custom-control-input" type="checkbox"
                                           id="toggle-multi"
                                           onChange={(e => setFormValues({...formValues, singlePrice: e.target.checked}))}
                                           defaultChecked={formValues.singlePrice}
                                        />
                                        <label className="custom-control-label" htmlFor="toggle-multi">Prezzo singolo: {formValues.singlePrice ? " si" : " no"}</label>
                                    </div>
                                )
                            }
                            </div>
                            {
                                (formValues.enabled && formValues.singlePrice) && (
                                <div className="col-6 mb-3">
                                    <label className="form-label"><b>Prezzo</b></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            name="price" onChange={handleChange} value={formValues.price}/>
                                        <span className="input-group-text">€</span>
                                    </div>
                                </div>
                                )
                            }
                            {
                                (formValues.enabled && !formValues.singlePrice) && (
                                <div className="col-6 mb-3">
                                    <label className="form-label"><b>Prezzi</b></label>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{width: "100px"}}>Lunedì</span>
                                        <input type="text" className="form-control"
                                               name="price_mon" onChange={handleChange} value={formValues.price_mon} />
                                        <span className="input-group-text">€</span>
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{width: "100px"}}>Martedì</span>
                                        <input type="text" className="form-control"
                                               name="price_tue" onChange={handleChange} value={formValues.price_tue} />
                                        <span className="input-group-text">€</span>
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{width: "100px"}}>Mercoledì</span>
                                        <input type="text" className="form-control"
                                               name="price_wed" onChange={handleChange} value={formValues.price_wed} />
                                        <span className="input-group-text">€</span>
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{width: "100px"}}>Giovedì</span>
                                        <input type="text" className="form-control"
                                               name="price_thu" onChange={handleChange} value={formValues.price_thu} />
                                        <span className="input-group-text">€</span>
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{width: "100px"}}>Venerdì</span>
                                        <input type="text" className="form-control"
                                               name="price_fri" onChange={handleChange} value={formValues.price_fri} />
                                        <span className="input-group-text">€</span>
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{width: "100px"}}>Sabato</span>
                                        <input type="text" className="form-control"
                                               name="price_sat" onChange={handleChange} value={formValues.price_sat} />
                                        <span className="input-group-text">€</span>
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{width: "100px"}}>Domenica</span>
                                        <input type="text" className="form-control"
                                               name="price_sun" onChange={handleChange} value={formValues.price_sun} />
                                        <span className="input-group-text">€</span>
                                    </div>
                                </div>
                                )
                            }
                            <div className="col-12">
                                { message.type === "error" && <span className="float-start alert alert-danger py-1 mb-0">{ message.value }</span> }
                                { message.type === "success" && <span className="float-start alert alert-warning py-1 mb-0">{ message.value }</span> }
                                <button disabled={!submitEnable || loading} type="submit" className="btn btn-sm btn-nuvala float-end">
                                    { loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" /> }
                                    Inserisci Disponibilità
                                </button>
                            </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}