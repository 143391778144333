import axios from "axios";
import authHeader from "./auth-header";
import {AccommodationModel} from "../models/Accommodation";
import {dateToString} from "./Planner.service";
const API_URL = process.env.REACT_APP_API_URL + "/api/admin";

export interface AdminFilter {
    key: string,
    value: string,
}

function generateArgsString(filters?: AdminFilter[], ordering?: string, search?: string) {
    let args = "";
    let bucket: {key: string, value: string[]}[] = []
    filters?.forEach(f => {
        let existing_bucket = bucket.find(b => b.key === f.key);
        (existing_bucket) ?
            existing_bucket.value.push(f.value) :
            bucket.push({key: f.key, value: [f.value]})
    });

    args += bucket.reduce((previousValue, currentValue) => {
        const args = currentValue.value.reduce((pv, cv) => { return pv + cv + ','; }, "");
        return previousValue + currentValue.key + '=' + args.slice(0, -1) + '&';
    }, "").slice(0, -1);

    if (ordering) args += "&order_by=" + ordering;
    if (search && search !== "") args += "&q=" + search;

    return args
}

export const getAdminDashBoardData = async () => {
    // @ts-ignore
    return await axios.get(API_URL + "/dashboard", { headers: authHeader() });
};

export const getAdminAccommodations = async (filters?: AdminFilter[], ordering?: string, search?: string) => {
    const args = generateArgsString(filters, ordering, search);
    // @ts-ignore
    return await axios.get(API_URL + "/accommodations?" + args, { headers: authHeader() });
};

export const getAdminUsers = async (filters?: AdminFilter[], ordering?: string, search?: string) => {
    const args = generateArgsString(filters, ordering, search);
    // @ts-ignore
    return await axios.get(API_URL + "/users?" + args, { headers: authHeader() });
};

export const patchAdminAccommodation = async (data: any, slug: string) => {
    const jsonData = JSON.stringify(data);
    // @ts-ignore
    return await axios.patch(API_URL + "/accommodation/" + slug, jsonData, { headers: authHeader(true) });
}

export const patchAdminUser = async (data: any, public_id: string) => {
    const jsonData = JSON.stringify(data);
    // @ts-ignore
    return await axios.patch(API_URL + "/user/" + public_id, jsonData, { headers: authHeader(true) });
}

export const getAdminReservationsList = async (from?: Date | null, to?: Date | null, statuses?: string[], ordering?: string, search?: string) => {
    let args = ""
    if (from !== undefined && from !== null) args += "from=" + dateToString(from) + "&";
    if (to !== undefined && to !== null) args += "to=" + dateToString(to) + "&";
    if (statuses !== undefined && statuses.length > 0) {
        const statuses_args = statuses.reduce((pv, cv) => {
            return pv + (cv + ',');
        }, "").slice(0, -1);
        args += "status=" + statuses_args + "&";
    }
    if (ordering) args += "&order_by=" + ordering;
    if (search) args += "&slug=" + search;
    // @ts-ignore
    return await axios.get(API_URL + "/reservations" + '?' + args, { headers: authHeader() });
}
