import { createContext } from "react";
import {User} from "../models/User";

export const UserContext = createContext<{
    user: User,
    setUser: (user: User) => void,
    signOut: () => void,
    signIn: (user: User) => void
}>({
    user: null,
    setUser: () => {},
    signOut: () => {},
    signIn: () => {}
});