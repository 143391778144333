import React, {useEffect, useState} from "react";
import {isAuth, logout} from "../../services/Auth.service";
import {Link, useNavigate} from "react-router-dom";
import {Loading} from "../../components/shared/Loading.component";
import {AccommodationModel} from "../../models/Accommodation";
import {Host} from "../../models/User";
import {getBasicData} from "../../services/Host.service";
import defaultImage from '../../assets/images/default-accommodation.png';
import {AccommodationToggle} from "../../components/host/AccommodationToggle.component";
import {cloneAccommodation} from "../../services/Accommodation.service";
import {CopyButton} from "../../components/shared/CopyButton.component";

export function HostListAccommodation(){
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [hostData, setHostData] = useState<Host>()
    const [accommodationList, setAccommodationList] = useState<AccommodationModel[]>([]);

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        }
        getBasicData().then(
            res => {
                setHostData(res.data);
                setAccommodationList(res.data.host.accommodations);
                setIsLoading(false);
            }, error => {
                console.log(error);
                logout();
                navigate("/login");
            }
        );
    }, []);

    function duplicateAccommodation(slug: string) {
        setIsLoading(true);
        cloneAccommodation(slug).then(res => {
            accommodationList.push(res.data);
            setAccommodationList(accommodationList);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false);
        });
    }

    if (isLoading) return <Loading/>

    return (
        <div className="row">
            <div className="col-12">
                <h3 className="text-nuvala-blue">Le mie strutture
                    <span className="float-end">
                        <CopyButton text={"http://nuvala.it/book/" + hostData?.host.id}/>
                        <Link to="/host/accommodation/new" className="btn btn-outline-nuvala">Aggiungi nuova struttura</Link>
                    </span>
                </h3>
            </div>
            {
                accommodationList?.length === 0 || accommodationList === null ? <p className="text-nuvala-blue">Non hai ancora aggiunto strutture</p> :
                accommodationList?.map((accommodation, i, array) => {
                    return <HostCardAccommodation accommodation={accommodation} duplicateAccommodation={duplicateAccommodation}/>;
                })
            }
        </div>
    );
}

interface HostCardAccommodationProps {
    accommodation: AccommodationModel,
    duplicateAccommodation: (slug: string) => void
}
export function HostCardAccommodation(props: HostCardAccommodationProps) {
    const cover_img = props.accommodation.media.length > 0 ? props.accommodation.media[0].url : defaultImage;
    return (
        <div className="col-4 p-2" key={props.accommodation.slug}>
            <div className="nuvala-modal text-nuvala-blue">
                    <div className="fill accom-card-header">
                        <img src={cover_img} className="card-img-top" alt={""}/>
                    </div>

                    <div className="card-body">
                        {
                            !props.accommodation.is_validated &&
                            (
                                <div className="await-approval text-center">
                                    <h5>In processo di validazione</h5>
                                    <small>La sistemazione diventera disponibile non appena lo staff di Nuvala la avrà approvata</small>
                                </div>
                            )
                        }
                        { props.accommodation.is_validated && <AccommodationToggle {...props.accommodation}/> }
                        <h5 className="card-title">{ props.accommodation.name }</h5>
                        <p className="card-text">{ props.accommodation.description.substring(0, 100) + "..." }</p>
                        <Link className="btn-nuvala-bottom-right" to={ "/host/accommodation/" + props.accommodation.slug }>Apri</Link>
                        <a onClick={(e) => props.duplicateAccommodation(props.accommodation.slug)} className="btn btn-outline-nuvala px-2 py-1">
                            <small>Duplica struttura</small>
                        </a>
                    </div>
            </div>
        </div>
    )
}