import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'react-dates/initialize';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home.page";
import Login from "./pages/Login.page";
import Register from "./pages/Register.page";
import {UserContext} from "./shared/UserContext";
import {getCurrentUser, logout, ROLE} from "./services/Auth.service";
import {HostDashboard} from "./pages/host/HostDashboard.page";
import {User} from "./models/User";
import {PrivateRoute} from "./services/PrivateRoute"
import {Navigation} from "./components/shared/Navigation.component";
import {HostAccommodation} from "./pages/host/HostAccommodation.page";
import {HostReservation} from "./pages/host/HostReservation.page"
import HostLayout from "./layouts/HostLayout";
import {HostListAccommodation} from "./pages/host/HostListAccommodations.page";
import {HostAddAccommodation} from "./pages/host/HostAddAccommodation.page";
import {HostSettings} from "./pages/host/HostSettings.page";
import {HostPricePlanner} from "./pages/host/HostPricePlanner.page";
import {Footer} from "./components/shared/Footer.component";
import VerifyEmail from "./pages/VerifyEmail.page";
import {GuestDashboard} from "./pages/guest/GuestDashboard.page";
import {GuestReservation} from "./pages/guest/GuestReservation.page";
import {RecoverPasswordInit, RecoverPasswordModule} from "./pages/RecoverPassword.page";
import {Manifesto} from "./pages/Manifesto.page";
import AdminLayout from "./layouts/AdminLayout";
import {AdminDashboard} from "./pages/admin/AdminDashboard.page";
import {AdminUsers} from "./pages/admin/AdminUsers.page";
import {AdminAccommodations} from "./pages/admin/AdminAccommodations.page";
import {HostListReservations} from "./pages/host/HostListReservations.page";
import {BookPage} from "./pages/Book.page"
import {PageNotFound} from "./pages/404.page";
import {About} from "./pages/About.page";
import {AdminReservation} from "./pages/admin/AdminReservation.page";

function App() {
    const [user, setUser] = useState<User>(null);
    // sign out the user, memoized
    const signOut = useCallback(() => {
        logout();
        setUser(null);
    }, []);
    // sign in the user, memoized
    const signIn = useCallback((u) => { setUser(u);}, []);
    useEffect(() => {
        document.title = "Nuvala"
        if(user === null) {
            const u: User = getCurrentUser();
            setUser(u);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // memoize the full context value
    const contextValue = useMemo(() => ({
        user,
        setUser,
        signOut,
        signIn,
    }), [user, setUser, signOut, signIn])

    return (
        <div className="d-flex flex-column">
            <div className="min-vh-100">
                <BrowserRouter>
                    <UserContext.Provider value={contextValue}>
                    <Navigation/>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="/festival" element={<Manifesto/>} />
                            <Route path="/about" element={<About />} />
                            <Route path="/verify/:token" element={<VerifyEmail/>}/>
                            <Route path="/recover-email/start" element={<RecoverPasswordInit />}/>
                            <Route path="/recover-email/set/:token" element={<RecoverPasswordModule />}/>
                            <Route path="/book/:host" element={<BookPage />} />
                            <Route element={<HostLayout/>}>
                                <Route path="/host"  element={
                                    <PrivateRoute roles={[ROLE.Provider]}><HostDashboard/></PrivateRoute>
                                }/>
                                <Route path="/host/accommodations"  element={
                                    <PrivateRoute roles={[ROLE.Provider]}><HostListAccommodation/></PrivateRoute>
                                }/>
                                <Route path="/host/settings"  element={
                                    <PrivateRoute roles={[ROLE.Provider]}><HostSettings/></PrivateRoute>
                                }/>
                                <Route path="/host/planner" element={
                                    <PrivateRoute roles={[ROLE.Provider]}><HostPricePlanner/></PrivateRoute>
                                }/>
                                <Route path="/host/accommodation/new"  element={
                                    <PrivateRoute roles={[ROLE.Provider]}><HostAddAccommodation/></PrivateRoute>
                                }/>
                                <Route path="/host/accommodation/:slug" element={
                                    <PrivateRoute roles={[ROLE.Provider]}><HostAccommodation/></PrivateRoute>
                                }/>
                                <Route path="/host/reservations"  element={
                                    <PrivateRoute roles={[ROLE.Provider]}><HostListReservations/></PrivateRoute>
                                }/>
                                <Route path="/host/reservation/:slug"  element={
                                    <PrivateRoute roles={[ROLE.Provider]}><HostReservation/></PrivateRoute>
                                }/>
                            </Route>
                            <Route path="/guest"  element={
                                <PrivateRoute roles={[ROLE.Customer]}><GuestDashboard/></PrivateRoute>
                            }/>
                            <Route path="/guest/reservation/:slug"  element={
                                <PrivateRoute roles={[ROLE.Customer]}><GuestReservation/></PrivateRoute>
                            }/>
                            <Route element={<AdminLayout/>}>
                                <Route path="/admin"  element={
                                    <PrivateRoute roles={[ROLE.Admin]}><AdminDashboard/></PrivateRoute>
                                }/>
                                <Route path="/admin/users" element={
                                    <PrivateRoute roles={[ROLE.Admin]}><AdminUsers/></PrivateRoute>
                                }/>
                                <Route path="/admin/accommodations" element={
                                    <PrivateRoute roles={[ROLE.Admin]}><AdminAccommodations/></PrivateRoute>
                                }/>
                                <Route path="/admin/reservations" element={
                                    <PrivateRoute roles={[ROLE.Admin]}><AdminReservation/></PrivateRoute>
                                }/>
                            </Route>
                            <Route path='*' element={<PageNotFound />} />
                        </Routes>
                    </UserContext.Provider>
                </BrowserRouter>
            </div>
            <Footer />
        </div>
  );
}

export default App;
