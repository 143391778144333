import {useEffect, useState} from "react";
import logo from '../../assets/images/logoSpinner.png';

export function Toast({text, success = false} : {text: string[], success?: boolean}) {
    const [show, setShow] = useState(true);

    useEffect(() => {
        setShow(true);
        setTimeout(function() {
          setShow(false);
        }, 10000);
    }, [text]);

    if (show === false) return <></>

    return (
        <div className={"toast" + (show ? " show" : "")} role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false">
            <div className="toast-header px-3">
                <img src={logo} style={{width: "30px"}} className="rounded me-2" alt="..." />
                    <strong className="me-auto">{success ? "Bravo!" : "Achtung!"}</strong>
                    <small>just now!</small>
                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShow(false)}/>
            </div>
            <div className="toast-body px-3">
                {
                    text.map((single_err, idx, arr) => {
                        return (<p key={"err-no-" + idx} className="text-nuvala-blue mb-0">{single_err}</p>);
                    })
                }
            </div>
        </div>
    );
}