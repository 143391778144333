import 'react-app-polyfill/ie11';
import * as React from 'react';
import defaultImage from '../../assets/images/imageUpload.jpg';
import {useEffect, useRef, useState} from "react";
import configData from "../../config.json";
import { UploadClient } from '@uploadcare/upload-client'
import {
    addNewImageToAccommodation,
    deleteImageToAccommodation,
} from "../../services/Accommodation.service";
import {getEmptyMediaModel, MediaModel} from "../../models/Accommodation";

// props.slug && props.img
export function ImageUpload(props: any) {
    const imageByteLimit = configData.maxImageSize;
    // @ts-ignore
    const client = new UploadClient({ publicKey: configData.uploadCareAPIKEY });
    const inputRef = useRef<HTMLInputElement>(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [backgroundImage, setBackgroundImage] = useState(defaultImage);
    const [media, setMedia] = useState<MediaModel>(getEmptyMediaModel());

    useEffect(() => {
        if(props.media !== undefined) {
            setBackgroundImage(props.media.url);
            setMedia(props.media);
            setIsUploaded(true);
        }
    }, []);

    const handleImageChange = (event: any) => {
        let file = event.target.files[0];
        if(file === null || file === undefined) {
            setMessage("Seleziona un immagine");
        } else if(file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
            console.log(file.type);
            setMessage("Formati accettati JPG e PNG");
        } else if (file.size > imageByteLimit) {
            setMessage("Dimensione massima 2MB");
        } else {
            setIsLoading(true);
            client.uploadFile(file).then(file => {
                if(file.cdnUrl !== null) {
                    const url : string = file.cdnUrl;
                    addNewImageToAccommodation(url, props.slug).then(
                        res => {
                            setMedia(res.data);
                        }).catch(err => {
                            console.log(err);
                        }).finally(() => {
                            setMessage("");
                            setBackgroundImage(url);
                            setIsUploaded(true);
                            setIsLoading(false);
                    });
                } else {
                    setMessage("Qualcosa e' andato storto");
                }
            })
        }
    }
    const handleDelete = () => {
        setIsLoading(true);
        deleteImageToAccommodation(media?.id).then(res => {
            setMedia(res.data);
            setBackgroundImage(defaultImage);
            setMessage("");
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsUploaded(false);
            setIsLoading(false);
        });
    }

    if(isUploaded) {
        return (
            <div style={{margin: "5px"}}>
                <div className="image_box">
                    {
                        isLoading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-warning" style={{marginTop: "37%"}}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <img className="img-fluid" src={backgroundImage}/>
                        )
                    }
                </div>
                <a onClick={handleDelete}><small className="text-nuvala-blue">rimuovi immagine</small></a>
            </div>
        )
    }

    return (
        <div style={{margin: "5px"}}>
            <input style={{display: "none"}} type="file" accept="image/jpeg,image/png" onChange={handleImageChange} ref={inputRef}/>
            <div className="image_box">
                {
                    isLoading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-warning" style={{marginTop: "37%"}}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <img className="img-fluid" src={backgroundImage} onClick={() => inputRef.current?.click()}/>
                    )
                }
            </div>
            <a><small className="text-muted">{ message }</small></a>
        </div>
    );
}