import React, {useEffect, useRef, useState} from "react";
import {Loading} from "../shared/Loading.component";
import "react-datepicker/dist/react-datepicker.css";
import {getAccommodationsPublicBySlug} from "../../services/Accommodation.service";
import {AccommodationModel, AccommodationPublicModel, MediaModel} from "../../models/Accommodation";
import def_img from '../../assets/images/default-accommodation.png'
import {useNavigate} from "react-router-dom";

export interface NuvalaMapMultiPopupProps {
    accommodation_slugs?: string[],
    setSelectedAccommodation: (slug: string) => void,
    modalCloseable?: boolean,
}

export function NuvalaMapMultiPopup(props: NuvalaMapMultiPopupProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setVisibility] = useState(false);
    const [accommodations, setAccommodations] = useState<AccommodationPublicModel[]>([]);
    const ref = useRef<HTMLDivElement>(null);

    let navigate = useNavigate();

    useEffect(() => {
        if (props.accommodation_slugs?.length) {
            setIsLoading(true);
            setVisibility(true);
            getAccommodationsPublicBySlug(props.accommodation_slugs)
                .then(res => {
                    if(res.data.length === 0) navigate('/');
                    setAccommodations(res.data)
                }).catch(err => {
                    console.log(err);
                    navigate('/');
            }).finally(() => {
                setIsLoading(false);
            });
        }
        const handleClickOutside = (event: any) => {
            if (props.modalCloseable !== undefined && !props.modalCloseable) return;
            if (ref.current && !ref.current.contains(event.target)) {
                setVisibility(false);
                setAccommodations([]);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [props.accommodation_slugs]);

    function selectAndClose(slug: string) {
        setVisibility(false);
        setAccommodations([]);
        props.setSelectedAccommodation(slug);
    }

    if (isVisible && isLoading)
        return ( <div className="nuvola_popup_loader"><Loading /></div>)

    return (
        <div className={"col-xl-9 offset-xl-1 col-12 nuvola_popup p-2" + (isVisible ? " active" : "")}>
            <div className="nuvala-yellow-modal-header position-relative d-flex justify-content-between align-items-center">
                <h5 className="text-yellow mb-0">{(accommodations && accommodations.length > 0) && accommodations[0].host.name}</h5>
                {
                    (props.modalCloseable === undefined || props.modalCloseable) && (
                        <button type="button"
                                className="btn-close btn-close-white position-absolute end-0 top-0"
                                style={{marginTop: "12px", marginRight: "12px"}} aria-label="Close"
                                onClick={() => setVisibility(false)}
                        ></button>
                    )
                }
            </div>
            <div ref={ref} className="nuvala-yellow-modal-body p-0">
                <div className="row mx-0 p-3">
                    {
                        accommodations.map((a: AccommodationPublicModel) => <NuvalaMapMultiPopupAccomodation accommodation={a} selectAndClose={selectAndClose} /> )
                    }
                </div>
            </div>
        </div>
    )
}


export interface NuvalaMapMultiPopupAccomodationProps {
    accommodation: AccommodationPublicModel,
    selectAndClose: (slug: string) => void
}

export function NuvalaMapMultiPopupAccomodation(props: NuvalaMapMultiPopupAccomodationProps) {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="col-12 col-md-4 col-lg-3 mb-4" key={props.accommodation.slug}>
            <div className="fill accom-card-header">
                <img
                src={(props.accommodation.media.length > 0) ? props.accommodation.media[0].url : def_img}
                className="card-img-top" alt="" />
            </div>
            <h5 className="mt-2">{props.accommodation.name}</h5>
            {
                expanded ? (
                    <p className="preserve-newlines">{props.accommodation.description}</p>
                ) : (
                    <p className="preserve-newlines">{props.accommodation.description.substring(0, 100)}... <span className="text-muted" onClick={() => setExpanded(true)}>leggi di piú</span></p>
                )
            }
            <a className="btn btn-outline-nuvala" onClick={() => props.selectAndClose(props.accommodation.slug)}>
                prenota
            </a>
        </div>
    )
}