import React, {useEffect, useState} from "react";
import logo from '../../assets/images/logoSpinner.png';
import {Link} from "react-router-dom";

export function CopyButton({text} : {text: string}) {
    const [clicked, setClicked] = useState(false);

    const performClick = (e: any) => {
        navigator.clipboard.writeText(text);
        setClicked(true);
        setTimeout(function() {
            setClicked(false);
        }, 3000);
    }

    if (clicked) return (
        <Link to={'#'} className="btn btn-nuvala mx-3">
            Copiato!
        </Link>
    )

    return (
        <Link to={'#'} className="btn btn-nuvala mx-3"
              onClick={performClick}>
            Copia link condivisione strutture
        </Link>
    );
}