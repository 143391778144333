import React, {useState} from "react";
import {UserAdminModel} from "../../models/Admin";
import {spinnner} from "../shared/Spinner.component";
import {patchAdminUser} from "../../services/Admin.service";
import {timestamp2ToString} from "../../models/Planner";
import {svg_check_circle, svg_circle_doted, svg_eye_closed, svg_eye_open} from "../shared/SVG.component";

interface AdminUserProps {
    user: UserAdminModel,
    setUser: (user: UserAdminModel) => void,
}

export function AdminUser({user, setUser}: AdminUserProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleActivate(e: any, active: boolean) {
        setLoading(true);
        const data = {"is_verified": active};
        patchAdminUser(data, user.public_id).then(res => {
            user.is_verified = res.data.is_verified;
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div key={"pacc-"+user.public_id} className="list-group d-grid gap-2 w-auto nuvala-list-group-item p-3 my-2 text-nuvala-blue">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <strong className="fw-semibold">
                        { user.email }
                        <span className="opacity-75"> (
                            { user.role == "provider" && "host" }
                            { user.role == "admin" && "admin" }
                            { user.role == "customer" && "guest" }
                        )</span>
                    </strong>
                    <span className="d-block small opacity-75">registrato il: {timestamp2ToString(user.registered_on)}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    { !isExpanded && (

                        <a onClick={(e) => setIsExpanded(true)} className="px-2 text-center text-decoration-none">
                            { svg_eye_open(32) }
                            <span className="d-block small opacity-75">mostra</span>
                        </a>

                    )}
                    { isExpanded && (
                        <a onClick={(e) => setIsExpanded(false)} className="px-2  text-center text-decoration-none">
                            { svg_eye_closed(32) }
                            <span className="d-block small opacity-75">chiudi</span>
                        </a>
                    )}
                    {
                        loading ? spinnner : user.is_verified ? (
                            <a className="px-2 text-center text-decoration-none" onClick={(e) => handleActivate(e, false)}>
                                { svg_circle_doted(32) }
                                <span className="d-block small opacity-75">invalida</span>
                            </a>
                        ) : (
                            <a className="px-2 text-center text-decoration-none" onClick={(e) => handleActivate(e, true)}>
                                { svg_check_circle(32) }
                                <span className="d-block small opacity-75">verifica</span>
                            </a>
                        )
                    }
                </div>
            </div>
            {
                isExpanded && (
                    <div>
                        <div className="row mt-3">
                            {
                                (user.role === "provider") && (
                                    <p className="small opacity-75">
                                        <b>email: </b> {user.email}<br/>
                                        <b>nome: </b> {user.host.owner_name}<br/>
                                        <b>cognome: </b> {user.host.owner_surname}<br/>
                                        <b>telefono: </b> {user.host.country_code} {user.host.phone_number}<br/>
                                        <b>p.iva: </b> {user.host.vat_number}<br/>
                                    </p>
                                )
                            }
                            {
                                (user.role === "customer") && (
                                    <p className="small opacity-75">
                                        <b>email: </b> {user.email}<br/>
                                        <b>nome: </b> {user.guest.guest_name}<br/>
                                        <b>cognome: </b> {user.guest.guest_surname}<br/>
                                        <b>telefono: </b> {user.guest.country_code} {user.guest.phone_number}<br/>
                                        <b>biografia: </b> {user.guest.bio}<br/>
                                        <b>lavoro: </b> {user.guest.job_title}<br/>
                                        <b>etá: </b> {user.guest.age}<br/>
                                    </p>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}