import {useEffect, useState} from "react";
import {BuddyModel} from "../../models/Reservation";
import {deleteBuddy} from "../../services/Reservation.service";
import * as React from "react";

const spinnner = (<span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />);

interface BuddyCardProps {
    inputBuddy: BuddyModel,
    index: number,
    buddies: BuddyModel[],
    setBuddies: (buddies: BuddyModel[]) => void,
    locked?: boolean,
}

export function BuddyCard(props: BuddyCardProps) {
    const [buddy, setBuddy] = useState<BuddyModel>({} as BuddyModel)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setBuddy(props.inputBuddy);
        return () => {};
    }, [])

    function handleDelete() {
        setLoading(true);
        if (buddy.id) {
            deleteBuddy(buddy.id).then(res => {
                props.setBuddies(props.buddies.filter(function(b) {
                    return b.id !== res.data.id
                }));
            }).catch(err => {
                console.log(err);
            }).finally(() => setLoading(false));
        }
    }

    return (
        <div className="p-2">
            <div className="buddy-card">
                <h5>Travel buddy #{props.index}</h5>
                <p className="mb-1">Nome: {props.inputBuddy.name}</p>
                <p className="mb-1">Eta': {props.inputBuddy.age}</p>
                <p className="mb-1">Lavoro: {props.inputBuddy.job}</p>
                {props.locked}
                {
                    (!props.locked) &&
                    <a className="text-end" onClick={handleDelete} style={{textDecoration: "none"}}><small className="me-2">rimuovi buddy</small>{loading && spinnner}</a>
                }
            </div>
        </div>
    )
}