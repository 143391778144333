import {useState} from "react";
import {AccommodationModel} from "../../models/Accommodation";
import {setActiveAccommodation} from "../../services/Accommodation.service";

export function AccommodationToggle(props: AccommodationModel) {
    const [isActive, setIsActive] = useState(props.is_active);

    const handleChange = (event: any) => {
        setActiveAccommodation(event.target.checked, props.slug)
            .then(res => {
                console.log(res.data.is_active);
                setIsActive(res.data.is_active);
            }).catch(err => {
                setIsActive(props.is_active);
            }).finally(() => {
                console.log(isActive);
            });
    }

    return (
        <div className="custom-control custom-switch mb-4">
            <input
                defaultChecked={isActive}
                type="checkbox"
                className="custom-control-input"
                id={"active-togg-" + props.slug}
                onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor={"active-togg-" + props.slug}>{ isActive ? "Visibile" : "Non visibile"}</label>
        </div>
    )
}