import {PricesExtract} from "../../models/Planner";
import React, {useState} from "react";
import {getRole} from "../../services/Auth.service";
import {Link} from "react-router-dom";
import {AccommodationPublicModel} from "../../models/Accommodation";

export interface NuvalaMapPopupPriceProps {
    p: PricesExtract | null,
    setRecap: (value: boolean) => void,
}


export function NuvalaMapPopupPrice(props: NuvalaMapPopupPriceProps) {
    const [isFlipped, setFlipped] = useState(false);
    const [p, setP] = useState(props.p)

    if (p == null) return (
        <div className="mt-4 text-nuvala-blue">
            <p>Selezionare la data di arrivo e la data di partenza dal calendario.</p>
        </div>
    );

    return (
        <div className={"mt-4 flipcard" + (isFlipped ? " flipped" : "")}>
            <div className="face front text-nuvala-blue">
                <div className="d-flex justify-content-between mb-1">
                    <small>Prezzo x notte</small>
                    <span><b>{p.avg_ppd}€</b></span>
                </div>
                <div className="d-flex justify-content-between">
                    <small>{p.avg_ppd}€ x {p.no_nights} {(p.no_nights == 1) ? "notte" : "notti"}</small>
                    <span><b>{p.total}€</b></span>
                </div>
                <hr/>
                <div className="d-flex justify-content-between">
                    <span><b>Totale</b></span>
                    <span><b>{p.total}€</b></span>
                </div>
                <div className="d-flex justify-content-between text-nuvala-blue">
                    {
                        getRole() === "customer" ?
                            (<button className="btn btn-nuvala mt-3" style={{width: "80%"}} onClick={(e) => props.setRecap(true)}>Prenota</button>)
                            : (<Link className="btn btn-nuvala mt-3" style={{width: "80%"}} to="/login">Accedi per
                                prenotare</Link>)
                    }

                    <button onClick={(event => setFlipped(true))} className="btn btn-nuvala mt-3"
                            style={{width: "18%"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-info-lg" viewBox="0 0 16 16">
                            <path
                                d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="face back text-nuvala-blue">
                {
                    p.prices.map((curr, idx) => {
                        return (
                            <div key={"price-" + idx} className="d-flex justify-content-between">
                                <small>{curr.date}</small>
                                <span><b>{curr.price}€</b></span>
                            </div>
                        )
                    })
                }
                {
                    (p.is_ppa && p.additional_ppp != null) &&
                    <>
                        <hr/>
                        <small>La struttura applica una tariffa aggiuntiva <b>({p.additional_ppp}€)</b> per ogni
                            ospite <b>({p.pax})</b>, per ogni
                            notte. Gia calcolato nei prezzi sopra indicati.</small>
                    </>
                }{
                (!p.is_ppa) &&
                <>
                    <hr/>
                    <small>Attenzione, il prezzo per notte calcolato varia in base al numero di ospiti: <b>{p.pax}</b>.</small>
                </>
            }
                <hr/>
                <div className="d-flex justify-content-between">
                    <span><b>Totale</b></span>
                    <span><b>{p.total}€</b></span>
                </div>
                <div className="d-flex justify-content-between">
                    <button onClick={(event => setFlipped(false))} className="btn btn-nuvala mt-3">
                        <i className="nuvala-arrow-left "/>
                    </button>
                </div>
            </div>
        </div>
    )
}