import 'react-app-polyfill/ie11';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {UserContext} from "../../shared/UserContext";
import {getRole, isTokenExpired, ROLE} from "../../services/Auth.service";
import logo from '../../assets/images/logoSpinner.png';
import {getBasicGuestData} from "../../services/Guest.service";
import {getBasicData} from "../../services/Host.service";
import {Toast} from "./Toast.component";

// @ts-ignore
export function Navigation() {
    const {user, signOut} = useContext(UserContext);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [userName, setUserName] = useState("");
    const [tokenError, setTokenError] = useState<string[]>([]);
    const location = useLocation();
    let navigate = useNavigate();

    const toggleOpen = () => setDropdownOpen(!isDropdownOpen);
    const menuClass = "dropdown-menu" + (isDropdownOpen ? " show" : "");

    useEffect(() => {
        const role = getRole();
        if (role === ROLE.Customer) {
            getBasicGuestData().then(res => setUserName("benvenuto " + res.data.guest.guest_name));
        } else if (role === ROLE.Provider) {
            getBasicData().then(res => setUserName(res.data.owner_name));
        } else if (role === ROLE.Admin) {
            setUserName("ciao admin!");
        } else {
            setUserName("");
        }
    }, [user]);

    useEffect(() => {
        if(isTokenExpired()) {
            setTokenError(["Token autenticazione scaduto, effettua nuovamente il login :)"]);
            signOut();
        }
    }, [location]);

    function scrollToMap(e: any) {
        e.preventDefault();
        if(location.pathname === "/") {
            const anchorMap = document.getElementById("map-container")
            anchorMap?.scrollIntoView({behavior: 'smooth', block: 'start'});
        } else {
            navigate("/");
        }
    }

    const hostMenu = (
        <>
            <li><Link className="dropdown-item text-nuvala-blue" to="/host">dashboard</Link></li>
            <li><Link className="dropdown-item text-nuvala-blue" to="/" onClick={signOut}>esci</Link></li>
        </>
    )

    const noUserMenu = (
        <>
            <li><Link className="dropdown-item text-nuvala-blue" to="/login">accedi</Link></li>
            <li><Link className="dropdown-item text-nuvala-blue" to="/register">registrati</Link></li>
        </>
    )

    const guestMenu = (
        <>
            <li><Link className="dropdown-item text-nuvala-blue" to="/guest">dashboard</Link></li>
            <li><Link className="dropdown-item text-nuvala-blue" to="/" onClick={signOut}>esci</Link></li>
        </>
    )

    const adminMenu = (
        <>
            <li><Link className="dropdown-item text-nuvala-blue" to="/admin">dashboard</Link></li>
            <li><Link className="dropdown-item text-nuvala-blue" to="/" onClick={signOut}>esci</Link></li>
        </>
    )

    return (
        <div style={{width: "100%", position: "absolute"}}>
            <div className="container">
                <div className="nuvala-navbar mt-3 py-3 py-md-0">
                    <header
                        className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-2">
                        <Link to="/" className="d-flex align-items-center col-md-2 mb-2 mb-md-0 text-dark text-decoration-none px-3">
                            <img style= {{maxWidth: "80px"}} src={logo}/>
                        </Link>

                        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                            <li><a onClick={scrollToMap} className="nav-link px-4 text-nuvala-blue">places</a></li>
                            <li><Link to="/festival" className="nav-link px-4 text-nuvala-blue">festival</Link></li>
                            <li><Link to="/about" className="nav-link px-4 text-nuvala-blue">about</Link></li>
                            <li><Link to="/login" className="nav-link px-4 text-nuvala-blue">join us</Link></li>
                        </ul>

                        <div className="col-md-2 text-lg-end text-center">
                            <ul className="nav justify-content-end mb-md-0">
                                <li className="nav-link px-0 px-md-2 align-self-center"><span className="text-nuvala-blue" style={{textTransform: 'lowercase'}}><i>{userName}</i></span></li>
                                <div className="dropdown dropstart align-self-center px-3" onClick={toggleOpen}>
                                    <a href="#">
                                        <span><i className="nuvala-user" /></span>
                                    </a>
                                    <ul className={menuClass}>
                                        { user === null && noUserMenu }
                                        { user !== null && user.role === "provider" && hostMenu }
                                        { user !== null && user.role === "customer" && guestMenu }
                                        { user !== null && user.role === "admin" && adminMenu }
                                    </ul>
                                </div>
                            </ul>
                        </div>
                        { tokenError.length > 0 ? <Toast text={tokenError} /> : "" }
                    </header>
                </div>
            </div>
        </div>
    );
}