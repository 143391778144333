import React, {useEffect} from "react";
import {setGuestLocation, UserLoc} from "../../services/Locale.service";
// @ts-ignore
import MapboxAutocomplete from "react-mapbox-autocomplete";
import DatePicker, {registerLocale} from "react-datepicker";
import it from "date-fns/locale/it";

interface MapLocationProps {
    startDate: Date | null,
    endDate: Date | null,
    guests: number,
    onChangeDates: (dates: [Date | null, Date | null]) => void,
    onChangeGuests: (g: number) => void 
}

export function NuvalaMapLocation(props: MapLocationProps) {
    useEffect(() => {
    }, [props])
    registerLocale('it', it);

    return (
        <div className="container" id="location_input">
            <div className="d-flex justify-content-around align-items-end">
                <div className="col-7 px-1">
                    <label className="form-label mb-1"><b>date arrivo/partenza</b></label>
                    <DatePicker
                        className="form-control form-control-filters"
                        placeholderText="arrivo e partenza"
                        locale="it"
                        dateFormat="dd-MM-yyyy"
                        startDate={props.startDate}
                        endDate={props.endDate}
                        onChange={props.onChangeDates}
                        focusSelectedMonth={true}
                        minDate={new Date()}
                        selectsRange
                    />
                </div>
                <div className="col-5 px-1">
                    <label className="form-label mb-1"><b>ospiti</b></label>
                    {
                        props.guests >= 1 ? (
                            <div className="input-group">
                                <span className="input-group-text form-control-filters-center text-nuvala-blue" onClick={() => props.onChangeGuests(props.guests - 1)}>&minus;</span>
                                <input type="text" name="min_pax" value={props.guests} placeholder="min"  className="form-control form-control-filters-center text-center" />
                                <span className="input-group-text form-control-filters-right text-nuvala-blue" onClick={() => props.onChangeGuests(props.guests + 1)}>&#x2b;</span>
                            </div>
                        ) : (
                            <button className="input-group-text form-control-filters-center text-nuvala-blue" onClick={() => props.onChangeGuests(2)}>filtra ospiti</button>
                        )
                    }

                </div>
            </div>
        </div>
        
    );
}


// interface MapLocationProps {
//     userLocation: UserLoc | undefined,
//     setUserLocation: (loc: UserLoc) => void,
// }

// export function NuvalaMapLocation(props: MapLocationProps) {
//     useEffect(() => {
//     }, [props])

//     const loc_string = props.userLocation ? props.userLocation.city : "Seleziona una destinazione di partenza";

//     function _suggestionSelect(result: string, lat: number, lng: number, text: string) {
//         const ul: UserLoc = setGuestLocation(result, {latitude: lat, longitude: lng})
//         props.setUserLocation(ul);
//     }

//     return (
//         <div id="location_input">
//             <MapboxAutocomplete
//                 publicKey='pk.eyJ1IjoiczRmZSIsImEiOiJja3l3dmQ5c3EwY2U0Mm9xaXpnaG9hajlyIn0.NxFOzf9mkgI9AieFiLCXsA'
//                 inputClass='form-control search mb-0'
//                 onSuggestionSelect={_suggestionSelect}
//                 country='it'
//                 query={loc_string}
//                 resetSearch={false}
//             />
//         </div>
//     );
// }