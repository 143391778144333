import React, {useEffect, useState} from "react";
import logo from "../assets/images/logoLanding.svg";
import {Link} from "react-router-dom";

export function PageNotFound() {

    return (
        <div className="row" style={{marginTop: "20%"}}>
            <div className="col-12 col-lg-4 offset-lg-4 text-center">
                <img src={logo} className="img-fluid" />
                <h1 className="text-nuvala-blue mt-5">Ops!</h1>
                <h3 className="text-nuvala-blue mt-5">Qualcosa e' andato storto</h3>
                <button className="btn btn-nuvala mt-5"><Link to="/" className="text-decoration-none">torna alla home</Link></button>
            </div>
        </div>
    );
}