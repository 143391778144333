import React, {useEffect, useState} from "react";

import {getBasicData, getOnboardStripeStatus, unlinkStripeHost} from "../../services/Host.service";
import {spinnner} from "../shared/Spinner.component";

export function StripeOnboard() {
    const [isLoading, setIsLoading] = useState(true);
    const [accountID, setAccountID] = useState(null);
    const [redirectURL, setRedirectURL] = useState("");
    const [chargesEnabled, setChargesEnabled] = useState(null);

    useEffect(() => {
        getOnboardStripeStatus().then(
            res => {
                setAccountID(res.data.account_id);
                setChargesEnabled(res.data.enabled);
                setRedirectURL(res.data.redirect_url);
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false);
            });
    }, []);

    function unlinkStripeAccount() {
        setIsLoading(true)
        unlinkStripeHost().then(
            res => {
                setAccountID(res.data.account_id);
                setChargesEnabled(res.data.enabled);
                setRedirectURL(res.data.redirect_url);
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false);
            });
    }

    if (isLoading) return (
        <>
            <h3 className="text-nuvala-blue">Stripe</h3>
            <div className="text-center  border p-4">
                {isLoading && spinnner}
            </div>
        </>
    )

    if (chargesEnabled) return (
        <>
            <h3 className="text-nuvala-blue">Stripe</h3>
            <div className="text-center  border p-4">
                <p className="text-nuvala-blue mb-0">Account Stripe connesso e ablitato a ricevere pagamenti! :)</p>
                <p className="text-nuvala-blue mb-0">
                    Per disconnettere l'account, <a onClick={unlinkStripeAccount}><b>clicca questo link</b></a>
                </p>
            </div>
        </>
    )

    return (
        <>
            <h3 className="text-nuvala-blue">Stripe</h3>
            <div className="text-center border p-4">
                <p className="text-nuvala-blue mb-2">Connetti il tuo account stripe per poter processare i pagamenti</p>
                <p>
                    {
                        accountID ? (
                            <a href={redirectURL} className="btn btn-dark">Completa la configurazione</a>
                        ) : (
                            <a href={redirectURL} className="btn btn-dark">Connetti account Stripe</a>
                        )
                    }
                </p>
            </div>
        </>
    )
}