import React, {SetStateAction, useEffect, useRef, useState} from "react";
import {Loading} from "../shared/Loading.component";
import {getAccommodationPublicBySlug} from "../../services/Accommodation.service";
import {AccommodationPublicModel} from "../../models/Accommodation";
import {geoDistance, UserLoc} from "../../services/Locale.service";
import "react-datepicker/dist/react-datepicker.css";
import {NuvalaMapPopupLanding} from "./NuvalaMapPopupLanding.component";
import {NuvalaMapPopupDate} from "./NuvalaMapPopupDate.component";
import {useNavigate} from "react-router-dom";

export interface NuvalaMapPopupProps {
    accommodation_slug?: string,
    userLocation: UserLoc,
    setSelectedAccommodation: React.Dispatch<SetStateAction<string>>,
    startDateIn: Date | null,
    endDateIn: Date | null,
    guestsIn?: number | null,
    modalCloseable?: boolean,
    refreshSlug?: () => void,
}

export interface SharedAccoProps {
    accommodation: AccommodationPublicModel,
    distance: number,
    setStep: (s: string) => void,
    startDateIn?: Date | null,
    endDateIn?: Date | null,
    guestsIn?: number | null,
}

export function NuvalaMapPopup(props: NuvalaMapPopupProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setVisibility] = useState(false);
    const [step, setStep] = useState('landing');
    const [accommodation, setAccommodation] = useState<AccommodationPublicModel>({} as AccommodationPublicModel);
    const [distance, setDistance] = useState<number>(0);
    const ref = useRef<HTMLDivElement>(null);

    let navigate = useNavigate();

    useEffect(() => {
        if (props.accommodation_slug) {
            setIsLoading(true);
            setVisibility(true);
            setStep('landing');
            getAccommodationPublicBySlug(props.accommodation_slug)
                .then(res => {
                    setAccommodation(res.data);
                    setDistance(geoDistance(props.userLocation.geo, {latitude: res.data.latitude, longitude: res.data.longitude}));
                }).catch(err => {
                    console.log(err);
                    navigate('/');
                }).finally(() => {
                    setIsLoading(false);
                });
        }
        const handleClickOutside = (event: any) => {
            if (props.modalCloseable !== undefined && !props.modalCloseable) return;
            if (ref.current && !ref.current.contains(event.target)) {
                setVisibility(false);
                props.setSelectedAccommodation("");
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [props.accommodation_slug]);

    if (isVisible && isLoading)
        return ( <div className="nuvola_popup_loader"><Loading /></div> )

    return (
        <div className={"col-xl-9 offset-xl-1 col-12 nuvola_popup p-2" + (isVisible ? " active" : "")}>
            <div className="nuvala-yellow-modal-header position-relative d-flex justify-content-between align-items-center">
                <h5 className="text-yellow mb-0">{accommodation.name}, <small>{accommodation.address}</small></h5>
                {
                    (props.modalCloseable === undefined || props.modalCloseable) ? (
                        <button type="button"
                                className="btn-close btn-close-white position-absolute end-0 top-0"
                                style={{marginTop: "12px", marginRight: "12px"}} aria-label="Close"
                                onClick={() => setVisibility(false)}
                        ></button>
                    ) : (
                        <button type="button" className="btn btn-nuvala btn-sm" onClick={props.refreshSlug}>Torna alle strutture</button>
                    )
                }
            </div>
            <div ref={ref} className="nuvala-yellow-modal-body p-0">
                { (step === 'landing') && <NuvalaMapPopupLanding {...{accommodation}} {...{distance}} {...{setStep}} /> }
                { (step === 'pick_date') && <NuvalaMapPopupDate
                    {...{accommodation}}
                    {...{distance}}
                    {...{setStep}}
                    startDateIn={props.startDateIn}
                    endDateIn={props.endDateIn}
                    guestsIn={props.guestsIn}
                /> }
            </div>
        </div>
    )
}

