import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {isAuth, logout} from "../../services/Auth.service";
import {AdminFilter, getAdminUsers} from "../../services/Admin.service";
import {UserAdminModel} from "../../models/Admin";
import {Toast} from "../../components/shared/Toast.component";
import {Loading} from "../../components/shared/Loading.component";
import {AdminUser} from "../../components/admin/AdminUser.component";
import {Accordion} from "../../components/shared/Accordion.component";

const startingFilters: AdminFilter[] = [
    {key: "is_validated", value: "true"},
    {key: "is_active", value: "true"},
]

export function AdminUsers() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [formError, setFormError] = useState<string[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<UserAdminModel[]>([]);
    const [filters, setFilters] = useState<AdminFilter[]>(startingFilters);
    const [ordering, setOrdering] = useState<string>("created_at-desc");
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        } else {
            fetchData();
        }
    }, [filters, ordering]);

    function fetchData() {
        setLoading(true);
        getAdminUsers(filters, ordering, search).then(res => {
            setSelectedUsers(res.data);
        }).catch(err => {
            setFormError(["Qualcosa e' andato storto:", err.response.data.message]);
        }).finally(() => {
            setLoading(false);
        });
    }

    function handleFilters(e: any) {
        const key = e.target.name;
        const checked = e.target.checked;
        const value = e.target.value;
        if(checked) {
            setFilters([...filters, {key:key, value: value}]);
        } else {
            setFilters(filters.filter(f => (!((f.key === key) && (f.value === value)))));
        }
    }

    function setUser(user: UserAdminModel) {
        const newState = selectedUsers.map(u => {
            if (u.public_id === user.public_id) {
                return {...u, is_verified: user.is_verified};
            }
            return u;
        });
    }

    if (loading) return <Loading />

    return (
        <div className="row">
            <div className="col-12 text-center">
                { formError.length > 0 ? <Toast text={formError} /> : "" }
            </div>
            <div className="col-12">
                <Accordion title="Filtri" open={true}>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Ordina per
                            </label>
                            <select className="form-select" value={ordering} onChange={(e) => setOrdering(e.target.value)} >
                                <option value="created_at-desc">Data registrazione descrescente</option>
                                <option value="created_at-asc">Data registrazione crescente</option>
                                <option value="email-desc">Email alfabetico descrescente</option>
                                <option value="email-asc">Email alfabetico crescente</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-3">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Cerca email utente
                            </label>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control text-nuvala-blue" value={search}
                                       onChange={(e) => setSearch(e.target.value)}
                                       placeholder="Cerca utente per email"
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-nuvala rounded-0" type="button"
                                            onClick={fetchData}
                                    >Cerca</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox"
                                       name="role"
                                       value="provider"
                                       checked={filters.some(f => (f.key === "role" && f.value === "provider") )}
                                       onChange={handleFilters}/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Hosts
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox"
                                       name="role"
                                       value="customer"
                                       checked={filters.some(f => (f.key === "role" && f.value === "customer") )}
                                       onChange={handleFilters}/>
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                    Guests
                                </label>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox"
                                       name="is_verified"
                                       value="true"
                                       checked={filters.some(f => (f.key === "is_verified" && f.value === "true") )}
                                       onChange={handleFilters}/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Validati
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox"
                                       name="is_verified"
                                       value="false"
                                       checked={filters.some(f => (f.key === "is_verified" && f.value === "false") )}
                                       onChange={handleFilters}/>
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                    Non Validati
                                </label>
                            </div>
                        </div>
                    </div>
                </Accordion>
            </div>

            <div className="col-12">
                <Accordion title={"Utenti nuvala (selezionati:" + selectedUsers.length + ")"} open={true}>
                    <div className="row">
                    {
                        (selectedUsers.length > 0) ? selectedUsers.map((u: UserAdminModel, idx) => {
                            return (
                                <div className="col-lg-6 col-12">
                                    <AdminUser key={idx} user={u} setUser={setUser} />
                                </div>
                            )
                        }) : <p>I filtri non hanno ritornato nessun utente</p>
                    }
                    </div>
                </Accordion>
            </div>
        </div>
    )
}