import axios from "axios";
import authHeader from "./auth-header";
import {BuddyModel, ReservationStateModel} from "../models/Reservation";
import {timestampToString} from "../models/Planner";
import {dateToString} from "./Planner.service"
const API_URL = process.env.REACT_APP_API_URL + "/api/reservations";


export const createReservation = (start_date: Date, end_date: Date, pax: number, slug: string) => {
    const bodyFormData = new FormData();
    bodyFormData.append('start_date', dateToString(start_date));
    bodyFormData.append('end_date', dateToString(end_date));
    bodyFormData.append('pax', pax.toString());
    bodyFormData.append('slug', slug);
    console.log(start_date, dateToString(start_date))
    // @ts-ignore
    return axios.post(API_URL + "/" + slug, bodyFormData, { headers: authHeader() });
};

export const getPaymentLink = (slug: string)  => {
    // @ts-ignore
    return axios.get(API_URL + "/stripe/payment/" + slug, { headers: authHeader() });
};

export const getChatMessages = (slug: string)  => {
    // @ts-ignore
    return axios.get(API_URL + "/chat/" + slug, { headers: authHeader() });
};

export const sendChatMessage = (slug: string, message: string)  => {
    const bodyFormData = new FormData();
    bodyFormData.append('message', message);
    // @ts-ignore
    return axios.post(API_URL + "/chat/" + slug, bodyFormData, { headers: authHeader() });
};

export const addBuddy = (slug: string, buddy: BuddyModel)  => {
    const bodyFormData = new FormData();
    for (let [key, value] of Object.entries(buddy)) {
        bodyFormData.append(key, value);
    }
    // @ts-ignore
    return axios.post(API_URL + "/buddy/" + slug, bodyFormData, { headers: authHeader() });
};

export const deleteBuddy = (buddy_id: number)  => {
    // @ts-ignore
    return axios.delete(API_URL + "/buddy/delete/" + buddy_id, { headers: authHeader() });
};

export function stateToBadge(s: ReservationStateModel) {
    return (
        <>
            <span className={("badge ") + statusCodeToBadgeColor(s.status)}>{ statusCodeToString(s.status) }</span><br />
            <span className="badge rounded-pill bg-light text-dark mt-2">{ timestampToString(s.created_at) }</span>
        </>
    )
}

export function statusCodeToBadgeColor(s: string) {
    if(s === 'pending_approval') {
        return "bg-secondary";
    } else if(s === 'pending_payment') {
        return "bg-secondary";
    } else if(s === 'paid') {
        return "bg-success";
    } else if(s === 'rejected') {
        return "bg-danger";
    } else if(s === 'cancelled') {
        return "bg-danger";
    } else if(s === 'completed') {
        return "bg-success";
    } else {
        return "";
    }
}

export function statusCodeToString(s: string) {
    if(s === 'pending_approval') {
        return "In attesa di conferma";
    } else if(s === 'pending_payment') {
        return "In attesa di pagamento";
    } else if(s === 'paid') {
        return "Pagato";
    } else if(s === 'rejected') {
        return "Rifiutata dall'host";
    } else if(s === 'cancelled') {
        return "Cancellata";
    } else if(s === 'completed') {
        return "Completata";
    } else {
        return "--";
    }
}

export const getHostReservationsList = async (from?: Date | null, to?: Date | null, statuses?: string[], ordering?: string, search?: string) => {
    let args = ""
    if (from !== undefined && from !== null) args += "from=" + dateToString(from) + "&";
    if (to !== undefined && to !== null) args += "to=" + dateToString(to) + "&";
    if (statuses !== undefined && statuses.length > 0) {
        const statuses_args = statuses.reduce((pv, cv) => {
            return pv + (cv + ',');
        }, "").slice(0, -1);
        args += "status=" + statuses_args + "&";
    }
    if (ordering) args += "&order_by=" + ordering;
    if (search) args += "&slug=" + search;
    // @ts-ignore
    return await axios.get(API_URL + "/host" + '?' + args, { headers: authHeader() });
}


export const setHostReservationStatus = async (slug: string, approve: boolean, message?: string) => {
    const bodyFormData = new FormData();
    bodyFormData.append('approve', approve.toString());
    if(message) bodyFormData.append('note', message);
    // @ts-ignore
    return axios.post(API_URL + "/host/approve/" + slug , bodyFormData, { headers: authHeader() });
}