import 'react-app-polyfill/ie11';
import * as React from 'react';
import logo from '../../assets/images/logoSpinner.png';

export function Footer() {
    return (
        <footer className="container-fluid mt-auto px-0 py-5 px-3">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3 col-md-10 offset-md-1">
                        <div className="d-flex justify-countent-around align-items-center flex-wrap" style={{justifyContent: "center"}}>
                            <div className="p-4 text-center">
                                <img src={logo} className="img-fluid" style={{maxWidth: "150px"}}/>
                            </div>
                            <div className="p-4 text-center text-md-start">
                                <p className="text-yellow">
                                    email | ciao@nuvala.it <br />
                                    phone | +39 333 774 1409 <br />
                                    C.F. | 93533190729 <br />
                                    Coookie policy | Privacy policy
                                </p>
                                <p className="text-yellow my-0">
                                    <small className='text-yellow'>All rights reserved @Nuvala{new Date().getFullYear().toString()}</small>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
}