import * as Yup from "yup";

export interface Planner {
    "month": string,
    "next_month": string,
    "prev_month": string,
    "next_month_slug": string,
    "prev_month_slug": string
    "selected_month": Date,
    "calendar": CalendarHeader[],
    "accommodations": CalendarAccommodation[],
}

export interface CalendarHeader {
    "date": Date,
    "is_weekend": boolean,
    "weekday": string,
}

export interface CalendarAccommodation {
    "slug": string,
    "name": string,
    "prices": CalendarAccommodationPrice[]
}

export interface CalendarAccommodationPrice {
    "date": Date,
    "price": number,
    "reservation": string | null
}

export interface PlannerQuickModalFormValues {
    slug: string,
    startDate: Date | null,
    endDate: Date | null,
    start_date?: string,
    end_date?: string,
    enabled: boolean,
    singlePrice: boolean,
    price: number,
    price_mon: number,
    price_tue: number,
    price_wed: number,
    price_thu: number,
    price_fri: number,
    price_sat: number,
    price_sun: number,
}

export const getPlannerQuickModalFormValues = () : PlannerQuickModalFormValues => ({
    slug: '',
    startDate: null,
    endDate: null,
    enabled: true,
    singlePrice: true,
    price: 0.00,
    price_mon: 0.00,
    price_tue: 0.00,
    price_wed: 0.00,
    price_thu: 0.00,
    price_fri: 0.00,
    price_sat: 0.00,
    price_sun: 0.00,
});

export const QuickModalFormSchema =
    Yup.object().shape({
        slug: Yup.string().required(),
        startDate: Yup.date().required(),
        endDate: Yup.date().required(),
    });

export const QuickModalFormSingleSchema =
    QuickModalFormSchema.shape({
        price: Yup.number().required().min(0),
    });

export const QuickModalFormMultiSchema =
    QuickModalFormSchema.shape({
        slug: Yup.string().required(),
        startDate: Yup.date().required(),
        endDate: Yup.date().required(),
        price_mon: Yup.number().required().min(0),
        price_tue: Yup.number().required().min(0),
        price_wed: Yup.number().required().min(0),
        price_thu: Yup.number().required().min(0),
        price_fri: Yup.number().required().min(0),
        price_sat: Yup.number().required().min(0),
        price_sun: Yup.number().required().min(0),
    });

export function dateToString(d: Date | null) {
    if (d === null) return "";
    return d.getDate().toString().padStart(2, '0')
        + "/" + (d.getMonth() + 1).toString().padStart(2, '0')
        + "/" + d.getFullYear()
}

export function dateStringToString(d: string) {
    const split = d.split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
}
export function timeStringToString(t: string) {
    const split = t.split(":");
    return split[0] + ":" + split[1];
}
export function timestampToString(ts: string) {
    const [date, time] = ts.split("T");
    return dateStringToString(date) + " " + timeStringToString(time);
}

export function timestamp2ToString(ts: string) {
    const [date, time] = ts.split(" ");
    return dateStringToString(date) + " " + timeStringToString(time);
}

export interface PricesExtract {
    "prices": { "date": Date, "price": number }[],
    "is_ppa": boolean,
    "additional_ppp": number,
    "avg_ppd": number,
    "no_nights": number,
    "pax": number,
    "total": number
}