import axios, {AxiosRequestHeaders} from "axios";
import authHeader from "./auth-header";
import {PlannerQuickModalFormValues} from "../models/Planner";
const API_URL = process.env.REACT_APP_API_URL + "/api/planner";

export const getPlanner = (date: string | null) => {
  const authHeaders = authHeader() as AxiosRequestHeaders;
  return axios.get(API_URL,{
    headers: authHeaders,
    params: { target_date: date },
  });
};

export const addDatePlanner = (slug: string, start_date: Date, end_date: Date, price: number | null) => {
  const quick: PlannerQuickModalFormValues = {} as PlannerQuickModalFormValues;
  quick.slug = slug;
  quick.start_date = dateToString(start_date);
  quick.end_date = dateToString(end_date)
  quick.enabled = true;
  quick.singlePrice = true;
  quick.price = price ? price : 0.00;
  const jsonData = JSON.stringify(quick);
  // @ts-ignore
  return axios.post(API_URL + "", jsonData, { headers: authHeader(true) });
};

export const quickAddPricesPlanner = (quick: PlannerQuickModalFormValues) => {
  if(!quick.startDate || !quick.endDate) return;
  quick.start_date = dateToString(quick.startDate);
  quick.end_date = dateToString(quick.endDate);
  const jsonData = JSON.stringify(quick);
  // @ts-ignore
  return axios.post(API_URL + "", jsonData, { headers: authHeader(true) });
}

export function dateToString(d: Date) {
  function pad(s: number) { return (s < 10) ? '0' + s : s; }
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}

export const getAccAvailDates = (slug: string) => {
  return axios.get(API_URL + "/availabilities/" + slug);
}

export const getAccPrices = (slug: string, start_date: Date, end_date: Date, pax: number) => {
  return axios.get(API_URL + "/prices/" + slug,
      { params: {
          start_date: dateToString(start_date),
          end_date: dateToString(end_date),
          pax: pax  }
      });
}
