import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {isAuth, logout} from "../../services/Auth.service";
import {getBasicData} from "../../services/Host.service";
import {Host} from "../../models/User";
import {Loading} from "../../components/shared/Loading.component";

export function HostDashboard(){
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [hostData, setHostData] = useState<Host>();

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        } else {
             getBasicData().then(
                res => {
                    setHostData(res.data);
                }).catch(err => {
                    logout();
                    navigate("/login");
                }).finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) return <Loading/>;

    if (hostData?.host.name === null || hostData?.host.stripe_account_id === null) {
        return (
            <div className="row">
                <div className="col-12 text-center text-nuvala-blue">
                    <h3>Benvenuto</h3>
                    <p>Per iniziare completa i dati di base della tua struttura</p>
                    <Link className="btn btn-outline-nuvala" to="/host/settings">Inizia</Link>
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <div className="col-12 text-center text-nuvala-blue">
                <h3>Benvenuto { hostData?.host.owner_name }</h3>
                <div className="row p-4">
                    <div className="col-3 px-2">
                        <p className="mb-0 buddy-card"><Link className="nav-link text-nuvala-blue" to={'/host/accommodations/new'}>Aggiungi una nuova struttura</Link></p>
                    </div>
                    <div className="col-3 px-2">
                        <p className="mb-0 buddy-card"><Link className="nav-link text-nuvala-blue" to={'/host/reservations'}>Visualizza le prenotazioni</Link></p>
                    </div>
                    <div className="col-3 px-2">
                        <p className="mb-0 buddy-card"><Link className="nav-link text-nuvala-blue" to={'/host/planner'}>Inserisci prezzi strutture</Link></p>
                    </div>
                    <div className="col-3 px-2">
                        <p className="mb-0 buddy-card"><Link className="nav-link text-nuvala-blue" to={'/host/settings'}>Modifica le mie informazioni</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}