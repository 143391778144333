import React, {useContext, useEffect, useState} from 'react';
import bg from "../assets/images/backgrounds/bg-7.jpg"
import bg2 from "../assets/images/backgrounds/bg-5.jpg"
import logo from "../assets/images/logo-home.png"
import {Marquee} from "../components/shared/Marquee.component";

export function About() {

    return (
        <>
        <div className="container-fluid px-0">
            <div className="nuvala-hero min-vh-100" style={{backgroundImage: "url("+ bg +")", height: "auto", backgroundRepeat: "repeat-y"}}>
                <div className="container">
                    <div className="row d-flex align-items-center" style={{minHeight: "100vh"}}>
                        <div className="col-md-8 offset-md-2 col-12 second-block-manifesto">
                            <div className="px-5 py-4" style={{fontWeight: '400'}}>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="text-white text-bigger mt-3">
                                            Nuvala è una piattaforma web di hosting/booking per la <b>workation</b> ed esperienze che promuove lo smartworking e <b>il turismo di prossimità.</b>
                                        </p><p className="text-white text-bigger mt-3">
                                            Nel network si trovano masserie, cascine, casali, trulli, lamie, case in zone periurbane, agricole, costiere, collinari o montane che garantiscono determinati standard di benessere termo-igrometrico, connettività e biofilia, utili per aziende, smartworker o freelancer che intendono lavorare da remoto in uno di questi luoghi.
                                        </p><p className="text-white text-bigger mt-3">
                                            Nuvala è anche uno spazio di networking che incentiva la conoscenza e il confronto off-line tra professionisti in diversi settori, come la tecnologia, la comunicazione, l’arte, la cucina e che promuove e facilita la collaborazione tra queste differenti discipline.
                                        </p>
                                    </div>
                                    <div className="col-6 text-center mt-5">
                                        <h5 className="text-white text-bigger">Workation:</h5>
                                        <p className="text-white text-bigger">vacanza che permette di lavorare da remoto in un luogo di villeggiatura, vivendo anche esperienze legate alla cultura, allo sport e al benessere.</p>
                                    </div>
                                    <div className="col-6 text-center mt-5">
                                        <h5 className="text-white text-bigger">Turismo di prossimità:</h5>
                                        <p className="text-white text-bigger">spostamento del viaggiatore verso mete vicine il luogo di residenza o domicilio, località non troppo affollate raggiungibili con un viaggio breve, al fine di valorizzare i territori limitrofi e riscoprire il turismo lento e destagionalizzato.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid px-0 d-none d-md-block">
            <Marquee slow={true}
                text="immagina: stai lavorando a casa o in un ufficio o
                in agenzia o in studio o in openspace o in hangar
                in città nella metropoli sei stressato non hai
                tempo non hai aria spazio calma quiete relax ma
                solo frenesia e deadline e allora sbuffi fumi
                svapi scrolli tamburelli ti incazzi ti annoi mangi
                veloce non dormi non respiri non fai niente di
                nulla per te stesso se non sognare e poi un
                giorno dopo la riunione rimani seduto al tavolo
                e guardi fuori dalla finestra e vedi una nuvola
                bianca veloce nel cielo azzurro — libera pura
                serena e quella nuvola ti incanta è il tuo
                spirito guida che ti chiama vorresti essere come
                quella nuvola - e ora immagina davvero: se tu
                scoprissi che puoi essere più leggero di quella
                nuvola e che quella nuvola è a meno di cento
                chilometri da te e puoi raggiungerla?
                e allora respiri."/>
        </div>

        <div className="container-fluid px-0">
            <div className="min-vh-100" style={{backgroundImage: "url("+ bg2 +")", marginTop: "0px", height: "auto", backgroundSize: "cover"}}>
                <div className="container">
                    <div className="row d-flex align-items-center" style={{minHeight: "100vh"}}>
                        <div className="col-md-8 offset-md-2 col-12 mt-5 text-center">
                            <h2 className="text-yellow">«LA NUVOLA È IL SIMBOLO DEL PROGETTO PERCHÉ RAPPRESENTA SIA IL CONCETTO DI PROSSIMITÀ CHE QUELLO DI CONNESSIONE»</h2>
                        </div>
                        <div className="col-md-8 offset-md-2 col-12 mb-5">
                            <div className="px-5 py-4" style={{fontWeight: '400'}}>
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="text-white mb-0">PERCHE’ NUVALA?</h5>
                                        <p className="text-white text-bigger mt-3">
                                            Nuvala nasce per rispondere al crescente bisogno, ma anche opportunità, di lavorare da remoto senza rinunciare alla possibilità di essere a contatto con la natura e vivere esperienze legate alla cultura, al benessere e allo sport in luoghi non troppo distanti dal proprio comune di residenza e lavoro.
                                        </p><p className="text-white text-bigger mt-3">
                                            Il progetto si fonda su due paradigmi che, a partire dagli ultimi anni, hanno iniziato a trasformare in meglio il nostro stile di vita: la transizione ecologica e quella digitale. La salvaguardia dell'ambiente, lo sviluppo del quarto settore e l'innovazione digitale hanno messo in luce la necessità di ri-organizzare gli spazi di lavoro rendendoli sempre più virtuali e connessi, innescando in qualche modo un processo di parziale rivoluzione del nostro stile di vita.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}