import 'react-app-polyfill/ie11';
import * as React from 'react';
import {getEmptyHostModel, HostModel, HostModelSchema} from "../../models/User";
import {NationsLocales} from "../../services/Locale.service";
import {getBasicData, setBasicHostData} from "../../services/Host.service";
import {useEffect, useState} from "react";
import {isAuth, logout} from "../../services/Auth.service";
import {useNavigate} from "react-router-dom";
import {Loading} from "../../components/shared/Loading.component";
import {isEmptyModel} from "../../services/Helpers.service";
import {Toast} from "../../components/shared/Toast.component";
import {StripeOnboard} from "../../components/host/StripeOnboard.component";

export function HostSettings(props: any) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const [formError, setFormError] = useState<string[]>([]);
    const [formValues, setFormValues] = useState<HostModel>(getEmptyHostModel());

    useEffect(() => {
        if (!isAuth()) {
            logout();
            navigate("/");
        } else {
             getBasicData().then(
                res => {
                    setFormValues(res.data.host);
                    setIsUpdate(isEmptyModel(res.data.host));
                }).catch(err => {
                    logout();
                    navigate("/login");
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }, []);

    function handleChange(event: any) {
        setFormValues({...formValues, [event.target.name]: event.target.value});
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await HostModelSchema.validate(formValues, {abortEarly: false}).then(validation => {
                setBasicHostData(formValues).then(
                    res => {
                        setFormError(["Profilo aggiornato correttamente!"]);
                    }).catch(err => {
                        setFormError(["Qualcosa e' andato storto, riprova."]);
                        console.log(err);
                    }).finally(() => {
                        setIsLoading(false);
                    });
            });
        } catch (err: any) {
            setFormError(err.errors);
            setIsLoading(false);
        }
    }
    async function handleSubmitUpdate(event: any) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await HostModelSchema.validate(formValues);
            setBasicHostData(formValues).then(
                res => {
                    setFormError(["Profilo aggiornato correttamente!"]);
                }).catch(err => {
                    setFormError(["Qualcosa e' andato storto, riprova."]);
                    console.log(err);
                }).finally(() => {
                    setIsLoading(false);
                });
        } catch (err: any) {
            setFormError(err.errors);
            setIsLoading(false);
        }
    }

    if (isLoading) return <Loading/>

    return (
        <>
            <div className="col-md-8 col-12 mb-5">
                <h3 className="text-nuvala-blue">{ (isUpdate ? "Aggiorna le" : "Inserisci le") + " informazioni di base" }</h3>
                { formError.length > 0 ? <Toast text={formError} /> : "" }
                <form onSubmit={isUpdate ? handleSubmitUpdate : handleSubmit}>
                 <div className="form-group mt-4">
                     <label>Nome Struttura</label>
                     <input name="name" value={formValues?.name}
                            type="text" className="form-control" onChange={handleChange} />
                 </div>
                 <div className="row mt-2">
                     <div className="col-6">
                         <label>Nome</label>
                         <input name="owner_name" value={formValues?.owner_name}
                                type="text" className="form-control" onChange={handleChange} />
                     </div>
                     <div className="col-6">
                         <label>Cognome</label>
                         <input name="owner_surname" value={formValues?.owner_surname}
                                type="text" className="form-control" onChange={handleChange} />
                     </div>
                 </div>
                 <div className="form-group mt-2">
                     <label>Indirizzo</label>
                     <input name="address" value={formValues?.address}
                            type="text" className="form-control" onChange={handleChange} />
                 </div>
                 <div className="form-group mt-2">
                     <label>Partita IVA</label>
                     <input name="vat_number" value={formValues?.vat_number}
                            type="text" className="form-control" onChange={handleChange} />
                 </div>
                 <div className="row mt-2">
                     <div className="form-group col-5">
                         <label htmlFor="inputState">Prefisso</label>
                         <select name="country_code" value={formValues?.country_code}  className="form-control" onChange={handleChange} >
                             <option value="" disabled>Seleziona prefisso nazionale</option>
                             { NationsLocales.map(l => (<option key={l.code} value={l.code}>{l.code} {l.name}</option>))}
                         </select>
                     </div>
                     <div className="form-group col-7">
                         <label htmlFor="inputCity">Telefono</label>
                         <input name="phone_number" value={formValues?.phone_number}
                                type="text" className="form-control" onChange={handleChange} />
                     </div>
                 </div>
                    <button type="submit" className="btn btn-nuvala mt-3">{ isUpdate ? "Aggiorna Dati" : "Inserisci Dati" }</button>
                </form>
            </div>
            <div className="col-md-8 col-12">
                <StripeOnboard/>
            </div>
        </>
    );
}