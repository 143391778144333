import React, {useEffect, useState} from "react";
import {AccommodationAdminModel} from "../../models/Admin";
import {ImageLightBox} from "../shared/ImageLightBox.component";
import {patchAdminAccommodation} from "../../services/Admin.service";
import {spinnner} from "../../components/shared/Spinner.component";
import {
    svg_active,
    svg_check_circle,
    svg_circle_doted,
    svg_eye_closed,
    svg_eye_open,
    svg_not_active
} from "../shared/SVG.component";

interface AdminAccommodationProps {
    accommodation: AccommodationAdminModel,
    setAccommodation: (accommodation: AccommodationAdminModel) => void,
}

export function AdminAccommodation({accommodation, setAccommodation}: AdminAccommodationProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleActivate(e: any, active?: boolean, validated?: boolean) {
        setLoading(true);
        let data = null;
        if(active !== undefined) data = {"is_active": active};
        if(validated !== undefined) data = {"is_validated": validated};
        patchAdminAccommodation(data, accommodation.slug).then(res => {
            accommodation.is_validated = res.data.is_validated;
            accommodation.is_active = res.data.is_active;
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div key={"pacc-"+accommodation.slug} className="list-group d-grid gap-2 w-auto nuvala-list-group-item p-3 my-2 text-nuvala-blue">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <strong className="fw-semibold">
                        { accommodation.name } / {accommodation.typology}
                        <span className="opacity-75"> ({ accommodation.host.name })</span>
                    </strong>
                    <span className="d-block small opacity-75">{ accommodation.address } / { accommodation.region }</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    { !isExpanded && (

                        <a onClick={(e) => setIsExpanded(true)} className="px-2 text-center text-decoration-none">
                            { svg_eye_open(32) }
                            <span className="d-block small opacity-75">mostra</span>
                        </a>

                    )}
                    { isExpanded && (
                        <a onClick={(e) => setIsExpanded(false)} className="px-2  text-center text-decoration-none">
                            { svg_eye_closed(32) }
                            <span className="d-block small opacity-75">chiudi</span>
                        </a>
                    )}
                    {
                        loading ? spinnner : accommodation.is_validated ? (
                            <a className="px-2 text-center text-decoration-none" onClick={(e) => handleActivate(e, undefined, false)}>
                                { svg_circle_doted(32) }
                                <span className="d-block small opacity-75">invalida</span>
                            </a>
                        ) : (
                            <a className="px-2 text-center text-decoration-none" onClick={(e) => handleActivate(e, undefined, true)}>
                                { svg_check_circle(32) }
                                <span className="d-block small opacity-75">verifica</span>
                            </a>
                        )
                    }
                    {
                        loading ? spinnner : accommodation.is_active ? (
                            <a className="px-2 text-center text-decoration-none" onClick={(e) => handleActivate(e, false, undefined)}>
                                { svg_not_active(32) }
                                <span className="d-block small opacity-75">disattiva</span>
                            </a>
                        ) : (
                            <a className="px-2 text-center text-decoration-none" onClick={(e) => handleActivate(e, true, undefined)}>
                                { svg_active(32) }
                                <span className="d-block small opacity-75">attiva</span>
                            </a>
                        )
                    }
                </div>
            </div>
            {
                isExpanded && (
                    <div>
                        <div className="row mt-3">
                            <ImageLightBox srcs={accommodation.media.map(m => m.url)} wrapperStyle="col-2 mb-4" />
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <b>descrizione:</b><br/>
                                <span className="small opacity-75">
                                    {accommodation.description}
                                </span>
                            </div>
                            <div className="col-lg-3 col-6 mt-3">
                                <b>letti:</b><br/>
                                <span className="small opacity-75">
                                    {accommodation.beds.map((b: any, idx) => {
                                        return <><span key={idx}>- {b.bed.name} x {b.value} </span><br/></>
                                    })}
                                </span>
                            </div>
                            <div className="col-lg-3 col-6 mt-3">
                                <b>filtri:</b><br/>
                                <span className="small opacity-75">
                                {accommodation.facilities.map((f: any, idx) => {
                                    return <><span className="my-0" key={idx}>- {f.name} </span><br/></>;
                                })}
                                </span>
                            </div>
                            <div className="col-lg-3 col-6 mt-3">
                                <b>politiche prezzi:</b><br/>
                                <span className="small opacity-75">
                                    {
                                        (accommodation.is_ppa) ? "- prezzo per sistemazione" : "- prezzo per persona"
                                    }
                                    <br />
                                    {
                                        (accommodation.is_ppa && accommodation.additional_ppp) ?
                                        "- aggiunta per persona per notte: " + accommodation.additional_ppp + "€" : ""
                                    }
                                </span>
                            </div>
                            <div className="col-lg-3 col-6 mt-3">
                                <b>politiche soggiorno:</b><br/>
                                <span className="small opacity-75">
                                    - min stay: <b>{accommodation.min_stay}</b> giorno/i<br/>
                                    - max stay: <b>{accommodation.max_stay}</b> giorno/i<br/>
                                    - min pax: <b>{accommodation.min_pax}</b> persona/e<br/>
                                    - max pax: <b>{accommodation.max_pax}</b> persona/e<br/>
                                </span>
                            </div>
                            <div className="col-12 mt-3">
                                <b>come raggiungerci:</b><br/>
                                <span className="small opacity-75">
                                    {accommodation.get_there}
                                </span>
                            </div>
                            <div className="col-12 mt-3">
                                <b>messaggio di benvenuto:</b><br/>
                                <span className="small opacity-75">
                                    {accommodation.welcome_message}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}