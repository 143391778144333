import {getCurrentUser, getRole, isAuth, ROLE} from "./Auth.service";
import { Navigate, useLocation } from "react-router-dom";
import {AccessDenied} from "../pages/AccessDenied.page";

export const PrivateRoute = ({
    children,
    roles,
} : {
    children: JSX.Element,
    roles: Array<ROLE>
}) => {
    let location = useLocation();
    const user = getCurrentUser();
    const userHasRequiredRole = user != null && roles.includes(getRole());

    if(!isAuth()) return <Navigate to="/login" state={{from: location}}/>

    if(!userHasRequiredRole) return <AccessDenied />

    return children;
};