import * as React from "react";
import {createRef, RefObject, useEffect, useState} from "react";
import {ReservationChatMessageModel} from "../../models/Reservation";
import {getRole, ROLE} from "../../services/Auth.service";
import guest_img from '../../assets/images/chat/guest.jpg';
import host_img from '../../assets/images/chat/host.jpg';
import admin_img from '../../assets/images/chat/admin.jpg';
import {timestampToString} from "../../models/Planner";
import {getChatMessages, sendChatMessage, statusCodeToString} from "../../services/Reservation.service";

const spinnner = (<span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />);

export function ReservationChat({slug} : {slug:string}) {
    const [loading, setLoading] = useState(true);
    const [btnLoader, setBtnLoader] = useState(false);
    const [messages, setMessages] = useState<ReservationChatMessageModel[]>([]);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        setLoading(true);
        getChatMessages(slug).then(res => {
            setMessages(res.data.messages);
        }).catch(err => {
            console.log(err);
            setError("Non siamo riusciti a caricare i messaggi, riprova piu tardi");
        }).finally(() => {
            setLoading(false);
        });
    }, [slug])

    useEffect(() => {
        setTimeout(() => scrollToLastMessage(), 500);
    }, [messages]);

    function scrollToLastMessage() {
        const anchor = document.getElementById("#msgx-" + messages.length);
        const chat_body = document.getElementById("chatBody");
        if(anchor && chat_body){
            chat_body.scrollTo({
                top: anchor.offsetTop,
                behavior: 'smooth'
            });
        }
    }


    function handleSendMessage() {
        setBtnLoader(true);
        if (message.length > 0) {
            sendChatMessage(slug, message).then(res => {
                setMessage("");
                setMessages([...messages, res.data]);
            }).catch(err => {
                console.log(err);
                setError("Non siamo riusciti ad inviare il messaggio, riprova piu tardi");
            }).finally(() => {
                setBtnLoader(false);
            });
        }
    }

    if (loading) return (
        <div className="chat-container">
            <div className="d-flex justify-content-center">
                <div className="spinner-border m-5" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )

    return (
        <div className="chat-container">
            <div className="chat-head p-3">
                <p style={{fontSize: "20px"}} className="text-nuvala-blue mb-0">Chat</p>
            </div>
            <div className="chat-body px-3 pt-3 overflow-scroll" id="chatBody">
                {
                    messages.map((m, idx) => {
                        return <ReservationChatMessage message={m} role={getRole()} key={idx} id={idx+1}/>
                    })
                }
            </div>
            <div className="chat-input p-2">
                <div className="input-group">
                    <input type="text" maxLength={100} name="message" value={message} className="form-control" onChange={(e) => setMessage(e.target.value)}/>
                    <button className="btn btn-outline-nuvala px-4" type="button" onClick={handleSendMessage}>{ btnLoader && spinnner}<b>Invia</b></button>
                </div>
            </div>
            <small className="text-muted float-end mt-1">{message.length} caratteri (max 100)</small>
        </div>
    );
}

interface ReservationChatMessageProps {
    message: ReservationChatMessageModel,
    role: ROLE,
    id: number
}
export function ReservationChatMessage({message, role, id} : ReservationChatMessageProps) {
    const current_role = (role === ROLE.Provider) ? "host" : "guest";
    const same_role = (current_role === message.role);

    if (message.role === "system") return (
        <div id={"#msgx-"+id} className="row message-container mb-4">
            <div className="col-12 col-md-6 offset-md-3 text-center">
                <span className="badge rounded-pill btn-yellow text-nuvala-blue">{ statusCodeToString(message.sender) }</span><br/>
                <small style={{fontSize: ".65em"}} className="text-nuvala-blue">{message.message}</small>
            </div>
        </div>
    )

    return (
        <div id={"#msgx-"+id} className="row message-container mb-4">
            <div className={"col-2 col-md-2 text-center " + (same_role ? "order-1" : "order-2") }>
                {(message.role === "guest") && <img src={guest_img} className="img-fluid rounded-circle" /> }
                {(message.role === "host") && <img src={host_img} className="img-fluid rounded-circle" /> }
                {(message.role === "admin") && <img src={admin_img} className="img-fluid rounded-circle" /> }
            </div>
            <div className={"col-8 col-md-10 " + (same_role ? "order-2 text-nuvala-blue" : "order-1 text-end text-nuvala-gray")}>
                <small style={{fontSize: ".65em"}}>
                    Alle { timestampToString(message.created_at) }, {same_role ? "tu hai" : message.sender + " ha"} scritto:
                </small>
                <p className="mb-0">{ message.message }</p>
                { (same_role && message.seen_at !== null) &&
                    <small style={{fontSize: ".65em"}}>&lt; Letto &gt;</small> }
            </div>
        </div>
    )
}