import {AccommodationModel} from "./Accommodation";
import * as Yup from "yup";

export interface UserLogin {
    email: string,
    password: string,
}

export const getEmptyUserLogin = () : UserLogin => ({
    email: '',
    password: '',
});

export const UserLoginSchema =
    Yup.object({
     email: Yup.string()
         .email('Inserire un indirizzo email valida')
         .max(255)
         .required('Inserire un indirizzo email'),
     password: Yup.string()
         .min(8, 'Password errata')
         .max(30, 'Password errata')
         .required('Inserire una password')
});


export type User = Host | Guest | null;

export interface UserBase {
    email: string,
    public_id: string,
    role: string,
    registered_on: string,
    token_expires_in: string
}

export interface Host extends UserBase {
    host: HostModel
}

export interface Guest extends UserBase {
    guest: GuestModel
}

export interface HostModel {
    id: string,
    owner_name: string,
    owner_surname: string,
    vat_number: string,
    name: string,
    address: string,
    country_code: string,
    phone_number: string,
    accommodations: AccommodationModel[],
    stripe_account_id: string,
    stripe_charges_enabled: boolean,
}

export const getEmptyHostModel = () : HostModel => ({
    id: '',
    owner_name: '',
    owner_surname: '',
    vat_number: '',
    name: '',
    address: '',
    country_code: '+39',
    phone_number: '',
    accommodations: [],
    stripe_account_id: '',
    stripe_charges_enabled: false,
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const VATRegExp = /^((AT)?U[0-9]{8}|(BE)?0?[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;
const CountryCodeRegExp = /^(\+?\d{1,3}|\d{1,4})$/;

export const HostModelSchema =
    Yup.object({
       owner_name: Yup.string()
         .min(2, "Nome troppo breve!")
         .max(50, "Nome troppo lungo!")
         .required("Inserire il campo nome!"),
       owner_surname: Yup.string()
         .min(2, "Cognome troppo breve!")
         .max(50, "Cognome troppo lungo!")
         .required("Inserire il campo cognome!"),
       name: Yup.string()
         .min(2, "Nome struttura troppo breve!")
         .max(50, "Nome struttura troppo lungo!")
         .required("Inserire il campo nome struttura!"),
       address: Yup.string()
         .min(2, "Indirizzo troppo breve!")
         .max(100, "Indirizzo troppo lungo!")
         .required("Inserire il campo indirizzo!"),
       country_code: Yup.string().trim().matches(CountryCodeRegExp, "Codice nazione non valido"),
       phone_number: Yup.string().trim().matches(phoneRegExp, "Il numero di telefono non è valido"),
       vat_number: Yup.string().trim().matches(VATRegExp, "La partita iva non è valida"),
    }
 );

export interface UserRegister {
    email: string,
    password: string,
    passwordConfirmation: string,
    is_host: boolean,
}

export const getEmptyUserRegister = () : UserRegister => ({
    email: '',
    password: '',
    passwordConfirmation: '',
    is_host: false,
});

export const UserRegisterSchema =
    Yup.object({
        email: Yup.string()
         .email('Inserire un indirizzo email valida')
         .max(255)
         .required('Inserire un indirizzo email'),
        password: Yup.string()
            .required('Inserire una password')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "La password deve contenere almeno 8 caratteri di cui: uno maiuscolo, uno minuscolo, un numero e un carattere speciale (! @ # $ % ^ & *)"),
        passwordConfirmation: Yup.string()
            .test('passwords-match', 'Il check password non corrisponde', function(value){
              return this.parent.password === value
            })
    });

export const UserRecoverPasswordSchema =
    Yup.object({
        token: Yup.string().required('Abbiamo riscontrato un problema con il link di verifica'),
        password: Yup.string()
            .required('Inserire una password')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "La password deve contenere almeno 8 caratteri di cui: uno maiuscolo, uno minuscolo, un numero e un carattere speciale (! @ # $ % ^ & *)"),
        passwordConfirmation: Yup.string()
            .test('passwords-match', 'Il check password non corrisponde', function(value){
                return this.parent.password === value
        })
});

export interface UserRecoverPassword {
    password: string,
    passwordConfirmation: string,
}

export const getEmptyUserRecoverPassword = () : UserRecoverPassword => ({
    password: '',
    passwordConfirmation: '',
})

export interface GuestModel {
    guest_name: string,
    guest_surname: string,
    job_title: string,
    bio: string,
    age: number,
    country_code: string,
    phone_number: string,
}

export const getEmptyGuestModel = () : GuestModel => ({
    guest_name: '',
    guest_surname: '',
    job_title: '',
    bio: '',
    age: 18,
    country_code: '+39',
    phone_number: '',
});

export const GuestModelSchema =
    Yup.object({
       guest_name: Yup.string()
         .min(2, "Nome troppo breve!")
         .max(50, "Nome troppo lungo!")
         .required("Inserire il campo nome!"),
       guest_surname: Yup.string()
         .min(2, "Cognome troppo breve!")
         .max(50, "Cognome troppo lungo!")
         .required("Inserire il campo cognome!"),
       job_title: Yup.string()
         .required("Selezionare un lavoro"),
       bio: Yup.string()
         .min(2, "Indirizzo troppo breve!")
         .max(500, "Indirizzo troppo lungo!"),
        age: Yup.number().required('Inserire l\'eta\'')
            .positive('L\'eta\' deve essere maggiore di 0')
            .integer('Inserire un numero valido'),
       country_code: Yup.string().trim().matches(CountryCodeRegExp, "Codice nazione non valido"),
       phone_number: Yup.string().trim().matches(phoneRegExp, "Il numero di telefono non è valido"),
    }
 );

export const GuestJobs = ["Architetto", "Ingegnere", "Designer", "Panettiere"];

export const UserVerifySchema =
    Yup.object({
     email: Yup.string()
         .email('Inserire un indirizzo email valida')
         .max(255)
         .required('Inserire un indirizzo email'),
     password: Yup.string()
         .min(8, 'Password troppo breve')
         .max(30, 'Password troppo lunga')
         .required('Inserire una password'),
});