import React, {useEffect, useRef, useState} from "react";
import {svg_chevron_left, svg_chevron_right, svg_close} from "./SVG.component";


interface ImageLightBoxProps {
    srcs: any[],
    wrapperStyle: string,
    keepRatio?: boolean
    singleImage?: boolean
}
export function ImageLightBox({srcs, wrapperStyle, keepRatio, singleImage}: ImageLightBoxProps) {
    const [isToggled, setIsToggled] = useState(false);
    const [targetSrc, setTargetSrc] = useState(srcs[0]);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsToggled(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    function handleOnClick(_src: string) {
        setTargetSrc(_src);
        setIsToggled(true);
    }
    function next() {
        const idx = srcs.indexOf(targetSrc);
        if (idx < 0) return;
        if (idx + 1 >= srcs.length) setTargetSrc(srcs[0]);
        else setTargetSrc(srcs[idx+1]);
    }
    function prev() {
        const idx = srcs.indexOf(targetSrc);
        if (idx < 0) return;
        if (idx - 1 < 0) setTargetSrc(srcs[srcs.length-1]);
        else setTargetSrc(srcs[idx-1]);
    }
    return (
        <>
            {
                singleImage ? (
                    <div onClick={(e) => handleOnClick(srcs[0])}>
                        <img src={srcs[0]} className={`${!keepRatio && "square-img" } img-fluid`} alt="nuvala fest" />
                    </div>
                ) : (
                    srcs.map((src: any, idx) => {
                        return (
                            (typeof src === 'string') ? (
                                <div key={idx} className={wrapperStyle}>
                                    <div onClick={(e) => handleOnClick(src)}>
                                        <img src={src} className={`${!keepRatio && "square-img" } img-fluid`} alt="nuvala fest" />
                                    </div>
                                </div>
                            ) : (
                                <div key={idx} className="col-md-6 col-12 my-3">
                                    <div className="video" onClick={(e) => handleOnClick(src)}>
                                        {
                                            src
                                        }
                                    </div>
                                </div>
                            )
    
                        )
                    })
                )
            }
        {
            isToggled && (
                <div className={"modal fade" + (isToggled ? " show d-block" : "")} tabIndex={-1} role="dialog"
                     aria-labelledby="imgThumb" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div ref={ref} className="modal-content modal-lightbox-content">
                            <div className="d-flex flex-row align-items-center justify-content-between lightbox-wrap">
                                <div className="lightbox-arrow mx-2 order-1 order-md-0">
                                    <span onClick={(e) => prev()}>
                                        { svg_chevron_left(40) }
                                    </span>
                                </div>
                                <div className="modal-lightbox-image order-0 order-md-1 mt-3 mt-md-0">
                                    {
                                        (typeof targetSrc === 'string') ? (
                                            <img src={targetSrc} className="img-fluid" style={{maxHeight: "80vh"}} alt="nuvala fest"/>
                                        ) : (
                                            targetSrc
                                        )
                                    }
                                    
                                    <span className="btn btn-sm btn-nuvala float-end close-bottom-right rounded-3" onClick={(e) => setIsToggled(false)}>
                                        { svg_close(30) }
                                    </span>
                                </div>
                                <div className="lightbox-arrow mx-2 order-2 order-md-2 mt-3 mt-md-0">
                                    <span onClick={(e) => next()}>
                                        { svg_chevron_right(40) }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        </>
    )
}